import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIErrorResProps,
  APIResponse,
  ApiSuccessResponse,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IFixedChartList,
  IGetFixedChart,
} from "@crema/types/models/dairy/Manage-Chart/FixedChart";
import {
  GET_FIXED_CHARTS,
  EDIT_FIXED_CHART_DETAILS,
  INIT_ADD_FIXED_CHART,
  GET_FIXED_CHART,
} from "@crema/types/actions/dairy/Manage-Chart/FixedChart.actions";

export const getFixedCharts = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/fixed-rate-chart", { params: filter })
      .then((response: APIResponse<IGetFixedChart>) => {
        handleApiResponse(dispatch, response, GET_FIXED_CHARTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const getFixedChartById = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/fixed-rate-chart`)
      .then((response: APIResponse<IFixedChartList>) => {
        handleApiResponse(dispatch, response, GET_FIXED_CHART);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const updateFixedChartDetails = (data: IFixedChartList) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/fixed-rate-chart`, data)
      .then((response: APIResponse<ApiSuccessResponse>) => {
        handleApiResponse(dispatch, response, EDIT_FIXED_CHART_DETAILS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const initAddFixedChart = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_FIXED_CHART });
  };
};
