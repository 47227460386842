import {
  ICustomerMilkDetails,
  ICustomerMilkList,
  ICustomerMilkShort,
} from "@crema/types/models/dairy/Report/CustomerMilk";

export const GET_CUSTOMER_MILKS = "GET_CUSTOMER_MILKS";
export const INIT_ADD_CUSTOMER_MILK = "INIT_ADD_CUSTOMER_MILK";
export const ADD_CUSTOMER_MILK = "ADD_CUSTOMER_MILK";
export const EDIT_CUSTOMER_MILK = "EDIT_CUSTOMER_MILK";
export const DELETE_CUSTOMER_MILK = "DELETE_CUSTOMER_MILK";
export const GET_CUSTOMER_MILK = "GET_CUSTOMER_MILK";
export const GET_CUSTOMER_MILK_LIST = "GET_CUSTOMER_MILK_LIST";
export const INIT_CUSTOMER_MILKS_FILTER = "INIT_CUSTOMER_MILKS_FILTER";
export const SET_CUSTOMER_MILKS_FILTER = "SET_CUSTOMER_MILKS_FILTER";
export const GET_CUSTOMER_MILKS_FILTER = "GET_CUSTOMER_MILKS_FILTER";
export const SET_EDIT_CUSTOMER_MILK_ID = "SET_EDIT_CUSTOMER_MILK_ID";
export const EXPORT_CUSTOMER_MILKS = "EXPORT_CUSTOMER_MILKS";
export const COLUMNS_CUSTOMER_MILKS = "COLUMNS_CUSTOMER_MILKS";
export const EXPORTED_CUSTOMER_MILKS = "EXPORTED_CUSTOMER_MILKS";

export type GetCustomerMilksActions = {
  type: typeof GET_CUSTOMER_MILKS;
  payload: ICustomerMilkList[];
};

export type InitAddCustomerMilkActions = {
  type: typeof INIT_ADD_CUSTOMER_MILK;
};

export type AddCustomerMilkActions = {
  type: typeof ADD_CUSTOMER_MILK;
  payload: ICustomerMilkDetails;
};

export type EditCustomerMilkActions = {
  type: typeof EDIT_CUSTOMER_MILK;
  payload: ICustomerMilkDetails;
};

export type GetCustomerMilkActions = {
  type: typeof GET_CUSTOMER_MILK;
  payload: ICustomerMilkDetails;
};

export type GetCustomerMilkListActions = {
  type: typeof GET_CUSTOMER_MILK_LIST;
  payload: ICustomerMilkShort[];
};

export type DeleteCustomerMilkActions = {
  type: typeof DELETE_CUSTOMER_MILK;
  payload: ICustomerMilkList;
};

export type InitCustomerMilksFilter = {
  type: typeof INIT_CUSTOMER_MILKS_FILTER;
};

export type SetCustomerMilksFilter = {
  type: typeof SET_CUSTOMER_MILKS_FILTER;
  payload: object;
};

export type GetCustomerMilksFilterActions = {
  type: typeof GET_CUSTOMER_MILKS_FILTER;
  payload: ICustomerMilkList[];
};
export type SetCustomerMilkIdForEditActions = {
  type: typeof SET_EDIT_CUSTOMER_MILK_ID;
  payload: number;
};

export type exportedCustomerMilksAction = {
  type: typeof EXPORTED_CUSTOMER_MILKS;
};

export type CustomerMilkActions =
  | GetCustomerMilksActions
  | InitAddCustomerMilkActions
  | AddCustomerMilkActions
  | EditCustomerMilkActions
  | DeleteCustomerMilkActions
  | GetCustomerMilkActions
  | GetCustomerMilkListActions
  | InitCustomerMilksFilter
  | SetCustomerMilksFilter
  | SetCustomerMilkIdForEditActions
  | exportedCustomerMilksAction;
