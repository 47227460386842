import {
  IProductDetails,
  IProductList,
  IProductShort,
} from "@crema/types/models/dairy/Manage-Product/Product";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const INIT_ADD_PRODUCT = "INIT_ADD_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const INIT_PRODUCTS_FILTER = "INIT_PRODUCTS_FILTER";
export const SET_PRODUCTS_FILTER = "SET_PRODUCTS_FILTER";
export const GET_PRODUCTS_FILTER = "GET_PRODUCTS_FILTER";
export const ATTACH_LEDGER = "ATTACH_LEDGER";
export const ADD_STOCK = "ADD_STOCK";

export type GetProductsActions = {
  type: typeof GET_PRODUCTS;
  payload: IProductList[];
};

export type InitAddProductActions = {
  type: typeof INIT_ADD_PRODUCT;
};

export type AddProductActions = {
  type: typeof ADD_PRODUCT;
  payload: IProductDetails;
};

export type EditProductActions = {
  type: typeof EDIT_PRODUCT;
  payload: IProductDetails;
};

export type GetProductActions = {
  type: typeof GET_PRODUCT;
  payload: IProductDetails;
};

export type GetProductListActions = {
  type: typeof GET_PRODUCT_LIST;
  payload: IProductShort[];
};

export type DeleteProductActions = {
  type: typeof DELETE_PRODUCT;
  payload: IProductList;
};

export type InitProductsFilter = {
  type: typeof INIT_PRODUCTS_FILTER;
};

export type SetProductsFilter = {
  type: typeof SET_PRODUCTS_FILTER;
  payload: object;
};

export type GetProductsFilterActions = {
  type: typeof GET_PRODUCTS_FILTER;
  payload: IProductList[];
};

export type AttachLedger = {
  type: typeof ATTACH_LEDGER;
  payload: IProductList;
};

export type AddStock = {
  type: typeof ADD_STOCK;
  payload: IProductList;
};

export type ProductActions =
  | GetProductsActions
  | InitAddProductActions
  | AddProductActions
  | EditProductActions
  | DeleteProductActions
  | GetProductActions
  | GetProductListActions
  | InitProductsFilter
  | SetProductsFilter
  | AttachLedger
  | AddStock;
