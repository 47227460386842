/* eslint-disable prettier/prettier */
import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIResponse,
  APIErrorResProps,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  ILedgers,
  ILedgerDetails,
  ILedgerShort,
  ILedgerTransactions,
} from "@crema/types/models/dairy/Manage-Ledger/Ledger";
import {
  GET_LEDGERS,
  ADD_LEDGER,
  INIT_ADD_LEDGER,
  EDIT_LEDGER,
  GET_LEDGER,
  SET_LEDGER_FILTER,
  INIT_LEDGER_FILTER,
  GET_LEDGER_LIST,
  INIT_LEDGER_TRANSACTION_FILTER,
  SET_LEDGER_TRANSACTION_FILTER,
  GET_LEDGER_TRANSACTIONS,
  SORT_LEDGER,
} from "@crema/types/actions/dairy/Manage-Ledger/Ledger.action";

export const getLedgers =
  (filter: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/ledger", { params: filter })
      .then((response: APIResponse<ILedgers[]>) => {
        handleApiResponse(dispatch, response, GET_LEDGERS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const initLedgerFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_LEDGER_FILTER });
};

export const setLedgerFilter =
  (filter: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_LEDGER_FILTER, payload: filter });
  };

export const createOrUpdateLedger =
  (method: "post" | "put", data: ILedgerDetails, id?: string | number) =>
    (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      jwtAxios[method](`/ledger${id ? `/${id}` : ""}`, data)
        .then((response: APIResponse<ILedgerDetails>) => {
          const actionType = method === "post" ? ADD_LEDGER : EDIT_LEDGER;
          handleApiResponse(dispatch, response, actionType);
        })
        .catch((error: APIErrorResProps) => {
          handleErrorResponse(dispatch, error);
        });
    };

export const deleteLedger =
  (id: string | number, filter: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/ledger/${id}`, filter)
      .then((response: APIResponse<ILedgerDetails>) => {
        handleApiResponse(dispatch, response, EDIT_LEDGER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const getLedgerById =
  (id: string | number) => (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/ledger/${id}`)
      .then((response: APIResponse<ILedgerDetails>) => {
        handleApiResponse(dispatch, response, GET_LEDGER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const getLedgerList =
  (queryParams?: object) =>
    (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      jwtAxios
        .get(`/dropdown-ledger`, { params: queryParams })
        .then((response: APIResponse<ILedgers[]>) => {
          handleApiResponse(dispatch, response, GET_LEDGER_LIST);
        })
        .catch((error: APIErrorResProps) => {
          handleErrorResponse(dispatch, error);
        });
    };


export const initAddLedger = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_ADD_LEDGER });
};

export const getLedgerTransaction =
  (id: string | number, filter: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/ledger/${id}/transaction`, { params: filter })
      .then((response: APIResponse<ILedgerTransactions[]>) => {
        handleApiResponse(dispatch, response, GET_LEDGER_TRANSACTIONS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const initLedgerTransactionFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_LEDGER_TRANSACTION_FILTER, payload: {} });
  };

export const setLedgerTransactionFilter =
  (filter: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_LEDGER_TRANSACTION_FILTER, payload: filter });
  };

export const sortLedger = (data: { id: number; sortOrder: number }[]) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart())
    jwtAxios
      .post(`/ledger-sort`, { sort: data })
      .then((response: APIResponse<ILedgerDetails>) => {
        handleApiResponse(dispatch, response, SORT_LEDGER)
        return response
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  }
}
