import {
  IProductGroupDetails,
  IProductGroupList,
  IProductGroupShort,
} from "@crema/types/models/dairy/Manage-Product/Product-Group/Product-Group";

export const GET_PRODUCT_GROUPS = "GET_PRODUCT_GROUPS";
export const INIT_ADD_PRODUCT_GROUP = "INIT_ADD_PRODUCT_GROUP";
export const ADD_PRODUCT_GROUP = "ADD_PRODUCT_GROUP";
export const EDIT_PRODUCT_GROUP = "EDIT_PRODUCT_GROUP";
export const DELETE_PRODUCT_GROUP = "DELETE_PRODUCT_GROUP";
export const GET_PRODUCT_GROUP = "GET_PRODUCT_GROUP";
export const GET_PRODUCT_GROUP_LIST = "GET_PRODUCT_GROUP_LIST";
export const INIT_PRODUCT_GROUPS_FILTER = "INIT_PRODUCT_GROUPS_FILTER";
export const SET_PRODUCT_GROUPS_FILTER = "SET_PRODUCT_GROUPS_FILTER";
export const GET_PRODUCT_GROUPS_FILTER = "GET_PRODUCT_GROUPS_FILTER";
export const SET_EDIT_PRODUCT_GROUP_ID = "SET_EDIT_PRODUCT_GROUP_ID";

export type GetProductGroupsActions = {
  type: typeof GET_PRODUCT_GROUPS;
  payload: IProductGroupList[];
};

export type InitAddProductGroupActions = {
  type: typeof INIT_ADD_PRODUCT_GROUP;
};

export type AddProductGroupActions = {
  type: typeof ADD_PRODUCT_GROUP;
  payload: IProductGroupDetails;
};

export type EditProductGroupActions = {
  type: typeof EDIT_PRODUCT_GROUP;
  payload: IProductGroupDetails;
};

export type GetProductGroupActions = {
  type: typeof GET_PRODUCT_GROUP;
  payload: IProductGroupDetails;
};

export type GetProductGroupListActions = {
  type: typeof GET_PRODUCT_GROUP_LIST;
  payload: IProductGroupShort[];
};

export type DeleteProductGroupActions = {
  type: typeof DELETE_PRODUCT_GROUP;
  payload: IProductGroupList;
};

export type InitProductGroupsFilter = {
  type: typeof INIT_PRODUCT_GROUPS_FILTER;
};

export type SetProductGroupsFilter = {
  type: typeof SET_PRODUCT_GROUPS_FILTER;
  payload: object;
};

export type GetProductGroupsFilterActions = {
  type: typeof GET_PRODUCT_GROUPS_FILTER;
  payload: IProductGroupList[];
};
export type SetProductGroupIdForEditActions = {
  type: typeof SET_EDIT_PRODUCT_GROUP_ID;
  payload: number;
};

export type ProductGroupActions =
  | GetProductGroupsActions
  | InitAddProductGroupActions
  | AddProductGroupActions
  | EditProductGroupActions
  | DeleteProductGroupActions
  | GetProductGroupActions
  | GetProductGroupListActions
  | InitProductGroupsFilter
  | SetProductGroupIdForEditActions;
