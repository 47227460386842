import { ConnectionObjType } from "@crema/types/models/apps/Mail";

const connectionList: ConnectionObjType[] = [
  {
    id: 311,
    name: "John Paul",
    status: "God is One",
    image: "/assets/images/avatar/A11.jpg",
    email: "john.paul@crema.com",
    address: "ABC house, Bikaner",
    designation: "CEO",
  },
  {
    id: 312,
    name: "Rahul Dravid",
    status: "Keep Calm and Work Hard!",
    image: "/assets/images/avatar/A10.jpg",
    email: "rahul.dravid@crema.com",
    address: "CDE house, Bikaner",
    designation: "React Developer",
  },
  {
    id: 313,
    name: "Will Smith",
    status: "Battery about to die",
    image: "/assets/images/avatar/A12.jpg",
    email: "will.smith@crema.com",
    address: "XYZ house, Bikaner",
    designation: "Marketing Manager",
  },
];
export default connectionList;
