import {
  ILedgerGroups,
  ILedgerGroupDetails,
  ILedgerGroupShort,
  IGetLedgerGroups,
} from "@crema/types/models/dairy/Manage-Ledger/LedgerGroup";

export const GET_LEDGER_GROUPS = "GET_LEDGER_GROUPS";
export const GET_LEDGER_GROUP = "GET_LEDGER_GROUP";
export const ADD_LEDGER_GROUP = "ADD_LEDGER_GROUP";
export const EDIT_LEDGER_GROUP = "EDIT_LEDGER_GROUP";
export const INIT_ADD_LEDGER_GROUP = "INIT_ADD_LEDGER_GROUP";
export const SET_LEDGER_GROUP_FILTER = "SET_LEDGER_GROUP_FILTER";
export const INIT_LEDGER_GROUP_FILTER = "INIT_LEDGER_GROUP_FILTER";
export const GET_LEDGER_GROUP_LIST = "GET_LEDGER_GROUP_LIST";
export const SET_EDIT_LEDGER_GROUP_ID = "SET_EDIT_LEDGER_GROUP_ID";

export type GetLedgerGroupsAction = {
  type: typeof GET_LEDGER_GROUPS;
  payload: IGetLedgerGroups;
};

export type GetLedgerGroupAction = {
  type: typeof GET_LEDGER_GROUP;
  payload: ILedgerGroupDetails;
};

export type GetLedgerGroupListByBranchIdAction = {
  type: typeof GET_LEDGER_GROUP_LIST;
  payload: ILedgerGroupShort[];
};

export type AddLedgerGroupAction = {
  type: typeof ADD_LEDGER_GROUP;
  payload: ILedgerGroupDetails;
};

export type EditLedgerGroupAction = {
  type: typeof EDIT_LEDGER_GROUP;
  payload: ILedgerGroupDetails;
};

export type InitAddLedgerGroupAction = {
  type: typeof INIT_ADD_LEDGER_GROUP;
};

export type InitLedgerGroupFilterAction = {
  type: typeof INIT_LEDGER_GROUP_FILTER;
};

export type SetLedgerGroupFilterAction = {
  type: typeof SET_LEDGER_GROUP_FILTER;
  payload: object;
};

export type SetLedgerGroupIdForEditActions = {
  type: typeof SET_EDIT_LEDGER_GROUP_ID;
  payload: number;
};

export type LedgerGroupActions =
  | GetLedgerGroupsAction
  | GetLedgerGroupAction
  | GetLedgerGroupListByBranchIdAction
  | AddLedgerGroupAction
  | EditLedgerGroupAction
  | InitAddLedgerGroupAction
  | InitLedgerGroupFilterAction
  | SetLedgerGroupFilterAction
  | SetLedgerGroupIdForEditActions;
