import { Link } from "react-router-dom";
import React from "react";
import { MessageFormatElement } from "react-intl";
import { RouterConfigData } from "@crema/types/models/Apps";
import { hasPermission } from "@crema/hooks/AuthHooks";

const renderMenuItemChildren = (
  item: RouterConfigData,
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
) => {
  const { icon, messageId, path } = item;

  if (path && path.includes("/"))
    return {
      key: item.id,
      icon:
        icon &&
        (React.isValidElement(icon) ? (
          <span className="ant-menu-item-icon">{icon}</span>
        ) : (
          <span className="ant-menu-item-icon" />
        )),
      label: (
        <Link to={path}>
          <span data-testid={messageId.toLowerCase + "-nav"}>
            {messages[messageId] as string}
          </span>
        </Link>
      ),
    };
  else {
    return {
      key: item.id,
      icon:
        icon &&
        (React.isValidElement(icon) ? (
          <span className="ant-menu-item-icon">{icon}</span>
        ) : (
          <span className="ant-menu-item-icon" />
        )),
      label: (
        <span data-testid={messageId.toLowerCase + "-nav"}>
          {messages[messageId] as string}
        </span>
      ),
    };
  }
};

const renderMenuItem: any = (
  item: RouterConfigData,
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
) => {
  return item.type === "collapse"
    ? {
        ...renderMenuItemChildren(item, messages),
        children: item.children?.map((item) => renderMenuItem(item, messages)),
        type: "collapse",
      }
    : {
        ...renderMenuItemChildren(item, messages),
      };
};

const renderMenu = (
  item: RouterConfigData,
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
) => {
  if (item.type === "group") {
    const groupHasPermission = item?.children.filter((children) => {
      return hasPermission(children.permission);
    });
    return (
      groupHasPermission.length != 0 && {
        ...renderMenuItemChildren(item, messages),
        children: item.children?.map((item) => renderMenuItem(item, messages)),
        type: "group",
      }
    );
  } else {
    return (
      hasPermission(item.permission) && {
        exact: item.exact,
        ...renderMenuItemChildren(item, messages),
      }
    );
  }
};

export const getRouteMenus = (
  routesConfig: RouterConfigData[],
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
) => {
  return routesConfig.map((route) => renderMenu(route, messages));
};
