import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  ICustomerList,
  ICustomerDetails,
  ICustomerShort,
  IGetCustomer,
} from "@crema/types/models/dairy/Customer";
import {
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER_LIST,
  INIT_ADD_CUSTOMER,
  INIT_CUSTOMERS_FILTER,
  SET_CUSTOMERS_FILTER,
} from "@crema/types/actions/dairy/Customer.actions";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getCustomers = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer", { params: filter })
      .then((response: APIResponse<IGetCustomer>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMERS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateCustomer = (
  method: "post" | "put",
  data: ICustomerDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/customer${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<ICustomerDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_CUSTOMER : EDIT_CUSTOMER,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteCustomer = (type: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/customer/${type}`, data)
      .then((response: APIResponse<ICustomerList>) => {
        handleApiResponse(dispatch, response, DELETE_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getCustomerById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/customer/${id}`)
      .then((response: APIResponse<ICustomerDetails>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getCustomerList = (search: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/customer/list`, { params: { search } })
      .then((response: APIResponse<Array<ICustomerShort>>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER_LIST);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const uploadCustomerImages =
  (id: string | number, type: string, data: object) =>
  (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/customer/${id}/upload-image/${type}`, data)
      .then((response: APIResponse<Array<ICustomerDetails>>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const initAddCustomer = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_CUSTOMER });
  };
};

export const initCustomersFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_CUSTOMERS_FILTER });
};

export const setCustomersFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CUSTOMERS_FILTER, payload: formData });
  };
