import {
  GET_MILKS,
  INIT_ADD_MILK,
  ADD_MILK,
  EDIT_MILK,
  DELETE_MILK,
  GET_MILK,
  INIT_MILKS_FILTER,
  SET_MILKS_FILTER,
  GET_CUSTOMERS_FOR_MILK,
  GET_CHARTS_FOR_MILK,
  SET_MILK_RATE,
} from "@crema/types/actions/dairy/Milk.actions";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IChartList,
  IGetChart,
} from "@crema/types/models/dairy/Manage-Chart/Chart";
import {
  ICustomerList,
  IGetCustomer,
} from "@crema/types/models/dairy/Customer";
import {
  IMilkDetails,
  IMilkList,
  IGetMilk,
  milksFilter,
  rate,
  ImilkFooter,
} from "@crema/types/models/dairy/Milk";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialMilkState = {
  milks: [] as IMilkList[],
  milkFooter: {} as ImilkFooter,
  customers: [] as ICustomerList[],
  charts: [] as IChartList[],
  pagination: null as PaginationType | null,
  milk: null as IMilkDetails | null,
  details: null as IMilkDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  milksFilter: {} as milksFilter,
  rate: null as rate,
  toggleToReload: 0 as number,
};

export const getMilksAction = createAction<IGetMilk>(GET_MILKS);
export const getCustomerForMilksAction = createAction<IGetCustomer>(
  GET_CUSTOMERS_FOR_MILK,
);
export const getChartForMilksAction =
  createAction<IChartList[]>(GET_CHARTS_FOR_MILK);
export const initAddMilkAction = createAction(INIT_ADD_MILK);
export const addMilkAction = createAction<IMilkDetails>(ADD_MILK);
export const editMilkAction = createAction(EDIT_MILK);
export const deleteMilkAction = createAction<IMilkList>(DELETE_MILK);
export const getMilkAction = createAction<IMilkDetails>(GET_MILK);
export const initMilksFilter = createAction<milksFilter>(INIT_MILKS_FILTER);
export const setMilksFilter = createAction<milksFilter>(SET_MILKS_FILTER);
export const setMilkRate = createAction<rate>(SET_MILK_RATE);

const milkReducer = createReducer(initialMilkState, (builder) => {
  builder
    .addCase(getMilksAction, (state, action) => {
      state.milks = action.payload.buyMilk;
      state.milkFooter = action.payload.pagination;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
      };
    })
    .addCase(getCustomerForMilksAction, (state, action) => {
      state.customers = action.payload.customer;
    })
    .addCase(getChartForMilksAction, (state, action) => {
      state.charts = action.payload;
    })
    .addCase(initAddMilkAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addMilkAction, (state, action) => {
      state.success = true;
      state.toggleToReload = ++state.toggleToReload;
      state.details = action.payload;
    })
    .addCase(editMilkAction, (state, action) => {
      state.milk = action.payload as IMilkDetails;
      state.milks = state.milks.map((milk) => {
        if (milk.id === (action.payload as IMilkDetails).id) {
          return action.payload;
        }
        return milk;
      });
      state.success = true;
      state.toggleToReload = ++state.toggleToReload;
    })
    .addCase(deleteMilkAction, (state, action) => {
      state.milks = state.milks.filter((milk) => milk.id !== action.payload.id);
    })
    .addCase(getMilkAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(initMilksFilter, (state) => {
      state.milksFilter = null;
    })
    .addCase(setMilkRate, (state, action) => {
      state.rate = action.payload;
    })
    .addCase(setMilksFilter, (state, action) => {
      state.milksFilter = action.payload;
    });
});

export default milkReducer;
