import React from "react";
import { RoutePermittedRole } from "@crema/constants/AppEnums";
import Account from "modules/account/MyProfile";
import ChooseDashboard from "modules/auth/ChooseDashboard";

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/my-profile",
    element: <Account />,
  },
  {
    path: "/choose-dashboard",
    element: <ChooseDashboard />,
  },
];
