import { localStoragePrefix } from "@crema/constants/AppConst";

const setPrefix = (key) => {
  const env = process.env?.REACT_APP_ENV;
  return localStoragePrefix + env + "_" + key;
};
// Set an item in local storage
export const setLocalStorageItem = (key: string, value: any) => {
  try {
    localStorage.setItem(setPrefix(key), JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting local storage item ${key}:`, error);
  }
};

// Get an item from local storage
export const getLocalStorageItem = (key: string) => {
  try {
    const value = localStorage.getItem(setPrefix(key));
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error getting local storage item ${key}:`, error);
    return null;
  }
};

// Remove an item from local storage
export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(setPrefix(key));
  } catch (error) {
    console.error(`Error removing local storage item ${key}:`, error);
  }
};

export const clearLocalStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(localStoragePrefix)) {
      localStorage.removeItem(key);
    }
  });
};

interface ItemProps {
  key?: string;
  value?: string;
}

// Get an item from local storage
export const getConfig = (key: string) => {
  try {
    const list = JSON.parse(localStorage.getItem(setPrefix("") + "config"));
    const bucketUrlItem = list.find((item: ItemProps) => item.key === key);
    return bucketUrlItem ? bucketUrlItem.value : null;
  } catch (error) {
    console.error(`Error getting local storage item ${key}:`, error);
    return null;
  }
};

// Get an item from local storage
export const getWithoutLoginConfig = (key) => {
  try {
    const list = JSON.parse(
      localStorage.getItem(setPrefix("") + "withoutLoginConfigList"),
    );
    const bucketUrlItem = list.find((item) => item.key === key);
    return bucketUrlItem ? bucketUrlItem.value : null;
  } catch (error) {
    console.error(`Error getting local storage item ${key}:`, error);
    return null;
  }
};
