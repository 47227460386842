import React from "react";

const List = React.lazy(() => import("./list"));

export const tagComponentRouteConfigs = [
  {
    path: "/tags",
    element: <List />,
  },
];
