import { RoutePermission } from "@crema/constants/AppConst";
import React from "react";

const List = React.lazy(() => import("./list"));
const Add = React.lazy(() => import("./add"));
const Edit = React.lazy(() => import("./edit"));
const View = React.lazy(() => import("./view"));

export const receiptPaymentsComponentRouteConfigs = [
  {
    path: "/receipt-payments/:type",
    element: <List />,
    permission: RoutePermission.dairy.receiptPayment.list,
  },
  {
    path: "/receipt-payments/add/:type",
    element: <Add />,
    permission: RoutePermission.dairy.receiptPayment.create,
  },
  {
    path: "/receipt-payments/edit/:id",
    element: <Edit />,
    permission: RoutePermission.dairy.receiptPayment.update,
  },

  {
    path: "/receipt-payments/view/:id",
    element: <View />,
    permission: RoutePermission.dairy.receiptPayment.single,
  },
];
