import { RoutePermission } from "@crema/constants/AppConst";
import React from "react";

const List = React.lazy(() => import("./list"));
const Add = React.lazy(() => import("./add"));
const Edit = React.lazy(() => import("./edit"));
const View = React.lazy(() => import("./view"));

export const customerComponentRouteConfigs = [
  {
    path: "/customers/:type",
    element: <List />,
    permission: RoutePermission.dairy.customer.list,
  },
  {
    path: "/customers/:type/add",
    element: <Add />,
    permission: RoutePermission.dairy.customer.create,
  },
  {
    path: "/customers/:type/edit/:id",
    element: <Edit />,
    permission: RoutePermission.dairy.customer.update,
  },
  {
    path: "/customers/:type/view/:id",
    element: <View />,
    permission: RoutePermission.dairy.customer.single,
  },
];
