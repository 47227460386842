import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IProductGroupList,
  IProductGroupDetails,
  IGetProductGroup,
} from "@crema/types/models/dairy/Manage-Product/Product-Group/Product-Group";
import {
  ADD_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  EDIT_PRODUCT_GROUP,
  GET_PRODUCT_GROUP,
  GET_PRODUCT_GROUPS,
  INIT_ADD_PRODUCT_GROUP,
  SET_EDIT_PRODUCT_GROUP_ID,
} from "@crema/types/actions/dairy/Manage-Product/Product-Group/Product-Group";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getProductGroups = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("manage-product/product-group", { params: filter })
      .then((response: APIResponse<IGetProductGroup>) => {
        handleApiResponse(dispatch, response, GET_PRODUCT_GROUPS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateProductGroup = (
  method: "post" | "put",
  data: IProductGroupDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/manage-product/product-group${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IProductGroupDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_PRODUCT_GROUP : EDIT_PRODUCT_GROUP,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteProductGroup = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/manage-product/product-group/${id}`, data)
      .then((response: APIResponse<IProductGroupList>) => {
        handleApiResponse(dispatch, response, DELETE_PRODUCT_GROUP);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getProductGroupById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-product/product-group/${id}`)
      .then((response: APIResponse<IProductGroupDetails>) => {
        handleApiResponse(dispatch, response, GET_PRODUCT_GROUP);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddProductGroup = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_PRODUCT_GROUP });
  };
};

export const editProductGroup =
  (id: number) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_EDIT_PRODUCT_GROUP_ID, payload: id });
  };
