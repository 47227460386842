import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  ICompanyDetailList,
  IGetCompanyDetail,
} from "@crema/types/models/customer/company";
import {
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAILS,
  INIT_ADD_COMPANY_DETAIL,
  INIT_COMPANY_DETAILS_FILTER,
  SET_COMPANY_DETAILS_FILTER,
} from "@crema/types/actions/customer/company";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getCompanyDetails = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer-api/company", { params: filter })
      .then((response: APIResponse<IGetCompanyDetail>) => {
        handleApiResponse(dispatch, response, GET_COMPANY_DETAILS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getCompanyDetailById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/customer-api/company/${id}`)
      .then((response: APIResponse<ICompanyDetailList>) => {
        handleApiResponse(dispatch, response, GET_COMPANY_DETAIL);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const initAddCompanyDetail = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_COMPANY_DETAIL });
  };
};

export const initCompanyDetailsFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_COMPANY_DETAILS_FILTER });
  };

export const setCompanyDetailsFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_COMPANY_DETAILS_FILTER, payload: formData });
  };
