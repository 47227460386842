import {
  IUserDetails,
  IUserList,
  IUserShort,
} from "@crema/types/models/superAdmin/user/user";

export const GET_USERS = "GET_USERS";
export const INIT_ADD_USER = "INIT_ADD_USER";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_USER = "GET_USER";
export const GET_USER_LIST = "GET_USER_LIST";
export const INIT_USERS_FILTER = "INIT_USERS_FILTER";
export const SET_USERS_FILTER = "SET_USERS_FILTER";
export const GET_USERS_FILTER = "GET_USERS_FILTER";

export type GetUsersActions = {
  type: typeof GET_USERS;
  payload: IUserList[];
};

export type InitAddUserActions = {
  type: typeof INIT_ADD_USER;
};

export type AddUserActions = {
  type: typeof ADD_USER;
  payload: IUserDetails;
};

export type EditUserActions = {
  type: typeof EDIT_USER;
  payload: IUserDetails;
};

export type GetUserActions = {
  type: typeof GET_USER;
  payload: IUserDetails;
};

export type GetUserListActions = {
  type: typeof GET_USER_LIST;
  payload: IUserShort[];
};

export type DeleteUserActions = {
  type: typeof DELETE_USER;
  payload: IUserList;
};

export type InitUsersFilter = {
  type: typeof INIT_USERS_FILTER;
};

export type SetUsersFilter = {
  type: typeof SET_USERS_FILTER;
  payload: object;
};

export type GetUsersFilterActions = {
  type: typeof GET_USERS_FILTER;
  payload: IUserList[];
};

export type UserActions =
  | GetUsersActions
  | InitAddUserActions
  | AddUserActions
  | EditUserActions
  | DeleteUserActions
  | GetUserActions
  | GetUserListActions
  | InitUsersFilter
  | SetUsersFilter;
