import {
  ILedgers,
  ILedgerDetails,
  ILedgerShort,
  ILedgerTransactions,
  IGetLedgers,
} from "@crema/types/models/dairy/Manage-Ledger/Ledger";

export const GET_LEDGERS = "GET_LEDGERS";
export const GET_LEDGER = "GET_LEDGER";
export const GET_LEDGER_LIST = "GET_LEDGER_LIST";
export const ADD_LEDGER = "ADD_LEDGER";
export const EDIT_LEDGER = "EDIT_LEDGER";
export const INIT_ADD_LEDGER = "INIT_ADD_LEDGER";
export const INIT_LEDGER_FILTER = "INIT_LEDGER_FILTER";
export const SET_LEDGER_FILTER = "SET_LEDGER_FILTER";

export const GET_LEDGER_TRANSACTIONS = "GET_LEDGER_TRANSACTIONS";
export const SET_LEDGER_TRANSACTION_FILTER = "SET_LEDGER_TRANSACTION_FILTER";
export const INIT_LEDGER_TRANSACTION_FILTER = "INIT_LEDGER_TRANSACTION_FILTER";
export const SORT_LEDGER = "SORT_LEDGER";

export type GetLedgersAction = {
  type: typeof GET_LEDGERS;
  payload: IGetLedgers;
};

export type GetLedgerAction = {
  type: typeof GET_LEDGER;
  payload: ILedgerDetails;
};

export type getLedgerList = {
  type: typeof GET_LEDGER_LIST;
  payload: ILedgerShort[];
};

export type AddLedgerAction = {
  type: typeof ADD_LEDGER;
  payload: ILedgerDetails;
};

export type EditLedgerAction = {
  type: typeof EDIT_LEDGER;
  payload: ILedgerDetails;
};

export type InitAddLedgerAction = {
  type: typeof INIT_ADD_LEDGER;
};

export type InitLedgerFilterAction = {
  type: typeof INIT_LEDGER_FILTER;
};

export type SetLedgerFilterAction = {
  type: typeof SET_LEDGER_FILTER;
  payload: object;
};

export type GetLedgerTransactions = {
  type: typeof GET_LEDGER_TRANSACTIONS;
  payload: ILedgerTransactions;
};

export type SetLedgerTransactions = {
  type: typeof SET_LEDGER_TRANSACTION_FILTER;
  payload: object;
};

export type InitLedgerTransactionFilter = {
  type: typeof INIT_LEDGER_TRANSACTION_FILTER;
};

export type sortLedger = {
  type: typeof SORT_LEDGER;
};

export type LedgerActions =
  | GetLedgersAction
  | GetLedgerAction
  | getLedgerList
  | AddLedgerAction
  | EditLedgerAction
  | InitAddLedgerAction
  | InitLedgerFilterAction
  | SetLedgerFilterAction
  | GetLedgerTransactions
  | SetLedgerTransactions
  | InitLedgerTransactionFilter
  | sortLedger;
