import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IProductUnitList,
  IProductUnitDetails,
  IGetProductUnit,
} from "@crema/types/models/dairy/Manage-Product/Product-Units/Product-Units";
import { GET_PRODUCT_UNITS } from "@crema/types/actions/dairy/Manage-Product/Product-Units/Product-Units";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getProductUnits = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("manage-product/product-unit", { params: filter })
      .then((response: APIResponse<IGetProductUnit>) => {
        handleApiResponse(dispatch, response, GET_PRODUCT_UNITS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
