import React from "react";

const Add = React.lazy(() => import("./add"));
const List = React.lazy(() => import("./list"));
const Pay = React.lazy(() => import("./pay/Pay"));
const View = React.lazy(() => import("./view"));

export const customerReceiptComponentRouteConfigs = [
  {
    path: "/manage-customers/receipt/add",
    element: <Add />,
  },
  {
    path: "/manage-customers/receipt",
    element: <List />,
  },
  {
    path: "/manage-customers/receipt/pay/:id",
    element: <Pay />,
  },
  {
    path: "/manage-customers/receipt/view/:id",
    element: <View />,
  },
];
