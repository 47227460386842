import {
  GET_PRODUCT_UNITS,
  GET_PRODUCT_UNIT,
} from "@crema/types/actions/dairy/Manage-Product/Product-Units/Product-Units";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IProductUnitDetails,
  IProductUnitList,
  IProductUnitShort,
  IGetProductUnit,
} from "@crema/types/models/dairy/Manage-Product/Product-Units/Product-Units";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialProductUnitState = {
  productUnits: [] as IProductUnitList[],
  productUnit: null as IProductUnitDetails | null,
  details: null as IProductUnitDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
};

export const getProductUnitsAction =
  createAction<IGetProductUnit>(GET_PRODUCT_UNITS);
export const getProductUnitAction =
  createAction<IProductUnitDetails>(GET_PRODUCT_UNIT);

const productUnitReducer = createReducer(initialProductUnitState, (builder) => {
  builder
    .addCase(getProductUnitsAction, (state, action) => {
      state.productUnits = action.payload.productUnit;
    })
    .addCase(getProductUnitAction, (state, action) => {
      state.details = action.payload;
    });
});

export default productUnitReducer;
