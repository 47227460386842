import {
  IProductBrandDetails,
  IProductBrandList,
  IProductBrandShort,
} from "@crema/types/models/dairy/Manage-Product/Product-Brand/Product-Brand";

export const GET_PRODUCT_BRANDS = "GET_PRODUCT_BRANDS";
export const INIT_ADD_PRODUCT_BRAND = "INIT_ADD_PRODUCT_BRAND";
export const ADD_PRODUCT_BRAND = "ADD_PRODUCT_BRAND";
export const EDIT_PRODUCT_BRAND = "EDIT_PRODUCT_BRAND";
export const DELETE_PRODUCT_BRAND = "DELETE_PRODUCT_BRAND";
export const GET_PRODUCT_BRAND = "GET_PRODUCT_BRAND";
export const GET_PRODUCT_BRAND_LIST = "GET_PRODUCT_BRAND_LIST";
export const INIT_PRODUCT_BRANDS_FILTER = "INIT_PRODUCT_BRANDS_FILTER";
export const SET_PRODUCT_BRANDS_FILTER = "SET_PRODUCT_BRANDS_FILTER";
export const GET_PRODUCT_BRANDS_FILTER = "GET_PRODUCT_BRANDS_FILTER";
export const SET_EDIT_PRODUCT_BRAND_ID = "SET_EDIT_PRODUCT_BRAND_ID";

export type GetProductBrandsActions = {
  type: typeof GET_PRODUCT_BRANDS;
  payload: IProductBrandList[];
};

export type InitAddProductBrandActions = {
  type: typeof INIT_ADD_PRODUCT_BRAND;
};

export type AddProductBrandActions = {
  type: typeof ADD_PRODUCT_BRAND;
  payload: IProductBrandDetails;
};

export type EditProductBrandActions = {
  type: typeof EDIT_PRODUCT_BRAND;
  payload: IProductBrandDetails;
};

export type GetProductBrandActions = {
  type: typeof GET_PRODUCT_BRAND;
  payload: IProductBrandDetails;
};

export type GetProductBrandListActions = {
  type: typeof GET_PRODUCT_BRAND_LIST;
  payload: IProductBrandShort[];
};

export type DeleteProductBrandActions = {
  type: typeof DELETE_PRODUCT_BRAND;
  payload: IProductBrandList;
};

export type InitProductBrandsFilter = {
  type: typeof INIT_PRODUCT_BRANDS_FILTER;
};

export type SetProductBrandsFilter = {
  type: typeof SET_PRODUCT_BRANDS_FILTER;
  payload: object;
};

export type GetProductBrandsFilterActions = {
  type: typeof GET_PRODUCT_BRANDS_FILTER;
  payload: IProductBrandList[];
};
export type SetProductBrandIdForEditActions = {
  type: typeof SET_EDIT_PRODUCT_BRAND_ID;
  payload: number;
};

export type ProductBrandActions =
  | GetProductBrandsActions
  | InitAddProductBrandActions
  | AddProductBrandActions
  | EditProductBrandActions
  | DeleteProductBrandActions
  | GetProductBrandActions
  | GetProductBrandListActions
  | InitProductBrandsFilter
  | SetProductBrandsFilter
  | SetProductBrandIdForEditActions;
