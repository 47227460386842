import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IDairyCustomerList,
  IDairyCustomerDetails,
  IGetDairyCustomer,
  IDairyCustomerInteraction,
  IAttachedUserTags,
} from "@crema/types/models/superAdmin/dairyCustomer/dairyCustomer";
import {
  ADD_DAIRY_CUSTOMER,
  ADD_DESCRIPTION_DAIRY_CUSTOMER,
  ADD_NOTE_DAIRY_CUSTOMER,
  ATTACH_TAG_DAIRY_CUSTOMER,
  DELETE_DAIRY_CUSTOMER,
  EDIT_DAIRY_CUSTOMER,
  GET_DAIRY_CUSTOMER,
  GET_DAIRY_CUSTOMERS,
  GET_DAIRY_CUSTOMER_INTERACTION,
  GET_NOTES_DAIRY_CUSTOMER,
  INIT_ADD_DAIRY_CUSTOMER,
  INIT_DAIRY_CUSTOMERS_FILTER,
  SET_DAIRY_CUSTOMERS_FILTER,
} from "@crema/types/actions/superAdmin/DairyCustomer/DairyCustomer.actions";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";
import { IGetInteraction } from "@crema/types/models/superAdmin/interaction";

export const getDairyCustomers = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/admin-api/dairy-customer", { params: filter })
      .then((response: APIResponse<IGetDairyCustomer>) => {
        handleApiResponse(dispatch, response, GET_DAIRY_CUSTOMERS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateDairyCustomer = (
  method: "post" | "put",
  data: IDairyCustomerDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/admin-api/dairy-customer${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IDairyCustomerDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_DAIRY_CUSTOMER : EDIT_DAIRY_CUSTOMER,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const getDairyCustomerInteraction = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/dairy-customer/interaction/${id}`)
      .then((response: APIResponse<IDairyCustomerInteraction>) => {
        handleApiResponse(dispatch, response, GET_DAIRY_CUSTOMER_INTERACTION);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deactivateOrDeleteDairyCustomer = (
  name: string,
  formData: { status: string; notes?: string },
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/admin-api/dairy-customer/delete/${name}`, formData)
      .then((response: APIResponse<null>) => {
        handleApiResponse(dispatch, response, DELETE_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteDairyCustomer = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`/admin-api/dairy-customer/${id}`, data)
      .then((response: APIResponse<IDairyCustomerList>) => {
        handleApiResponse(dispatch, response, DELETE_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getDairyCustomerById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/dairy-customer/${id}`)
      .then((response: APIResponse<IDairyCustomerDetails>) => {
        handleApiResponse(dispatch, response, GET_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const initAddDairyCustomer = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_DAIRY_CUSTOMER });
  };
};

export const initDairyCustomersFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_DAIRY_CUSTOMERS_FILTER });
  };

export const setDairyCustomersFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_DAIRY_CUSTOMERS_FILTER, payload: formData });
  };

export const addDairyCustomerTag = (
  userId: string | number,
  tagIds: number[],
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/admin-api/user-tags`, {
        customerId: userId,
        tagId: tagIds,
      })
      .then((response: APIResponse<IAttachedUserTags[]>) => {
        handleApiResponse(dispatch, response, ATTACH_TAG_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const addNoteDairyCustomer = (userId: string | number, note: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/admin-api/interaction`, {
        customerId: userId,
        notes: note,
      })
      .then((response: APIResponse<IAttachedUserTags[]>) => {
        handleApiResponse(dispatch, response, ADD_NOTE_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getNotesDairyCustomer = (userId: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/interaction`, {
        params: {
          customerId: userId,
        },
      })
      .then((response: APIResponse<IGetInteraction>) => {
        handleApiResponse(dispatch, response, GET_NOTES_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const addDescriptionDairyCustomer = (
  companyId: string | number,
  formData: {
    userId: number | string;
    description: string;
  },
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`/admin-api/company/${companyId}/description`, formData)
      .then((response: APIResponse<IDairyCustomerDetails>) => {
        handleApiResponse(dispatch, response, ADD_DESCRIPTION_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
