import React, { useEffect } from "react";
import { message } from "antd";
import AppLoader from "../AppLoader";
import { useAppDispatch, useAppSelector } from "toolkit/hooks";
import { hideMessage } from "toolkit/actions";

const AppInfoView = () => {
  const dispatch = useAppDispatch();
  const {
    loading,
    error,
    message: displayMessage,
  } = useAppSelector(({ common }) => common);
  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(hideMessage());
    }
  }, [error]);

  useEffect(() => {
    if (displayMessage) {
      message.success(displayMessage);
      dispatch(hideMessage());
    }
  }, [displayMessage]);

  return <>{loading ? <AppLoader /> : null}</>;
};

export default AppInfoView;
