import React from "react";
import { Descriptions, Select } from "antd";
import AppRowContainer from "@crema/components/AppRowContainer";
import IntlMessages from "@crema/helpers/IntlMessages";
import {
  StyledUserProfileForm,
  StyledUserProfileFormTitle,
} from "../index.styled";
import { useAuthUser } from "@crema/hooks/AuthHooks";
import { formatDate } from "@crema/helpers/GlobalHelper";

const Information = () => {
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const { Option } = Select;

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const { user } = useAuthUser();

  return (
    <StyledUserProfileForm
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <StyledUserProfileFormTitle>
        <IntlMessages id="userProfile.information" />
      </StyledUserProfileFormTitle>
      <AppRowContainer gutter={16}>
        {user && (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="First Name">
              {user.firstName}
            </Descriptions.Item>
            <Descriptions.Item label="Last Name">
              {user.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile">{user.mobile}</Descriptions.Item>
            <Descriptions.Item label="Default Language">
              {user.defaultLanguage}
            </Descriptions.Item>
            <Descriptions.Item label="Last Login Date">
              {formatDate(user.lastLoginDate)}
            </Descriptions.Item>
            <Descriptions.Item label="Plan Expiry Date">
              {formatDate(user.planExpiryDate)}
            </Descriptions.Item>
            {user.company && (
              <>
                <Descriptions.Item label="Company Name">
                  {user.company?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Company Code">
                  {user.company?.code}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        )}
      </AppRowContainer>
    </StyledUserProfileForm>
  );
};

export default Information;
