import {
  ITagDetails,
  ITagList,
  ITagShort,
} from "@crema/types/models/superAdmin/tag";

export const GET_TAGS = "GET_TAGS";
export const INIT_ADD_TAG = "INIT_ADD_TAG";
export const ADD_TAG = "ADD_TAG";
export const EDIT_TAG = "EDIT_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const GET_TAG = "GET_TAG";
export const GET_TAG_LIST = "GET_TAG_LIST";
export const INIT_TAGS_FILTER = "INIT_TAGS_FILTER";
export const SET_TAGS_FILTER = "SET_TAGS_FILTER";
export const GET_TAGS_FILTER = "GET_TAGS_FILTER";
export const SET_EDIT_TAG_ID = "SET_EDIT_TAG_ID";

export type GetTagsActions = {
  type: typeof GET_TAGS;
  payload: ITagList[];
};

export type InitAddTagActions = {
  type: typeof INIT_ADD_TAG;
};

export type AddTagActions = {
  type: typeof ADD_TAG;
  payload: ITagDetails;
};

export type EditTagActions = {
  type: typeof EDIT_TAG;
  payload: ITagDetails;
};

export type GetTagActions = {
  type: typeof GET_TAG;
  payload: ITagDetails;
};

export type GetTagListActions = {
  type: typeof GET_TAG_LIST;
  payload: ITagShort[];
};

export type DeleteTagActions = {
  type: typeof DELETE_TAG;
  payload: ITagList;
};

export type InitTagsFilter = {
  type: typeof INIT_TAGS_FILTER;
};

export type SetTagsFilter = {
  type: typeof SET_TAGS_FILTER;
  payload: object;
};

export type GetTagsFilterActions = {
  type: typeof GET_TAGS_FILTER;
  payload: ITagList[];
};
export type SetTagIdForEditActions = {
  type: typeof SET_EDIT_TAG_ID;
  payload: number;
};

export type TagActions =
  | GetTagsActions
  | InitAddTagActions
  | AddTagActions
  | EditTagActions
  | DeleteTagActions
  | GetTagActions
  | GetTagListActions
  | InitTagsFilter
  | SetTagsFilter
  | SetTagIdForEditActions;
