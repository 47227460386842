import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import IntlMessages from "./IntlMessages";
import { hasPermission } from "./AuthHelper";
import { RoutePermission } from "@crema/constants/AppConst";
import useIntl from "react-intl/lib/src/components/useIntl";
import { IMilkList, fat } from "@crema/types/models/dairy/Milk";
import dayjs, { Dayjs } from "dayjs";

export const isValidEmail = (value: string): boolean => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const isEmpty = (obj: Record<string, any>): boolean => {
  for (const key in obj) {
    if (obj?.key) {
      return false;
    }
  }
  return true;
};

export const cleanObject = (obj: Record<string, any>): Record<string, any> => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const getFileName = (fullPath: string): string | null => {
  const startIndex =
    fullPath.indexOf("\\") >= 0
      ? fullPath.lastIndexOf("\\")
      : fullPath.lastIndexOf("/");
  let filename = fullPath.substring(startIndex);
  if (filename.startsWith("\\") || filename.startsWith("/")) {
    filename = filename.substring(1);
  }
  return filename;
};

export const saveUserInLocalStorage = (
  user: Record<string, any> | null,
): void => {
  localStorage.setItem("user", user ? JSON.stringify(user) : null);
};

export const formatDate = (
  value: string | number,
  formatString: string = "DD-MM-YYYY",
): string => {
  const { messages } = useIntl();
  try {
    if (Number(value) > 0) {
      return moment(new Date(Number(value) * 1000)).format(formatString);
    } else {
      return dayjs(value).format(formatString);
    }
  } catch (error) {
    return String(messages["common.na"]);
  }
};

export const formatCurrency = (
  amount: number | string | null | undefined,
): string => {
  if (typeof amount === "number" && !isNaN(amount)) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(amount);
  } else if (typeof amount === "string" && !isNaN(parseFloat(amount))) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(parseFloat(amount));
  } else {
    return "0";
  }
};

export const renderFieldOrNA = (
  field: number | string | null | undefined,
): string | number => {
  return field || field === 0 ? field : "N/A";
};

interface Pagination {
  from: number;
}
const pagination: Pagination = { from: 1 };

export const renderSerialNumber = (record: any, index: number): string => {
  return `${pagination.from + index}`;
};

interface Column {
  key: string;
  title?: React.ReactNode;
  dataIndex?: string;
  render?: (text: any, record: any, index: number) => React.ReactNode;
}

export const showConfirmationDialog = (
  confirmationMessage: string,
  onConfirm: () => void,
): void => {
  Modal.confirm({
    title: confirmationMessage,
    icon: <ExclamationCircleFilled />,
    content: "",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      onConfirm();
    },
  });
};

export const getLedgerTypeList = () => [
  "Asset",
  "Income",
  "Expenditure",
  "Liabilities",
];

export const getLedgerRuleTypeList = () => [
  "Account",
  "Share",
  "Receipt",
  "EMI",
  "Loan",
]; //'EMI', 'Receipt',

export const getLedgerRuleModeList = () => ["Cash", "Online", "Transfer"];

export function findMedianFat(milks: IMilkList[]): number {
  const fats: number[] = milks.map((milk) => parseFloat(milk.fat));

  fats.sort((a, b) => a - b);

  const mid = Math.floor(fats.length / 2);
  if (fats.length % 2 === 0) {
    return (fats[mid - 1] + fats[mid]) / 2;
  } else {
    return fats[mid];
  }
}

export function findModeFat(milks: IMilkList[]): string {
  const frequencyMap: { [key: string]: number } = {};

  // Count frequency of each fat value
  milks.forEach((milk) => {
    frequencyMap[milk.fat] = (frequencyMap[milk.fat] || 0) + 1;
  });

  let mode: string | null = null;
  let maxFrequency = 0;

  // Find the fat value with the highest frequency
  Object.entries(frequencyMap).forEach(([fat, frequency]) => {
    if (frequency > maxFrequency) {
      mode = fat;
      maxFrequency = frequency;
    }
  });

  return mode ?? "0";
}

export const getDateRange = () => {
  const currentDate = dayjs();
  const currentDay = currentDate.date();

  let startDate, endDate;

  if (currentDay >= 1 && currentDay <= 10) {
    // Set start date to 21st of the previous month
    startDate = currentDate.subtract(1, "month").date(21);
    // Set end date to 31st of the previous month (if exists) or the last day of the previous month
    endDate = currentDate.subtract(1, "month").endOf("month");
  } else if (currentDay >= 11 && currentDay <= 20) {
    // Set start date to 1st of the current month
    startDate = currentDate.date(1);
    // Set end date to 10th of the current month
    endDate = currentDate.date(10);
  } else if (currentDay >= 21 && currentDay <= 31) {
    // Set start date to 11th of the current month
    startDate = currentDate.date(11);
    // Set end date to the last day of the current month
    endDate = currentDate.endOf("month");
  }
  return { startDate, endDate };
};

export const sortResults: any = (
  results: any[],
  column: string,
  orderDirection: "asc" | "desc",
) => {
  return results.sort((a, b) => {
    let valueA = a[column];
    let valueB = b[column];

    // Handle alphanumeric sorting for 'code' column
    if (column === "code") {
      const splitAlphaNumeric = (value: string) => {
        const alphaPart = value.match(/[a-zA-Z]+/g) || [""];
        const numericPart = value.match(/\d+/g) || ["0"];
        return [alphaPart[0], parseInt(numericPart[0], 10)];
      };

      const [alphaA, numA] = splitAlphaNumeric(valueA);
      const [alphaB, numB] = splitAlphaNumeric(valueB);

      if (alphaA !== alphaB) {
        return orderDirection === "asc"
          ? String(alphaA).localeCompare(String(alphaB))
          : String(alphaB).localeCompare(String(alphaA));
      }
      return orderDirection === "asc"
        ? Number(numA) - Number(numB)
        : Number(numB) - Number(numA);
    }

    // Check if the values are date strings and parse them into timestamps
    const isDate = !isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB));

    if (isDate) {
      valueA = new Date(valueA).getTime();
      valueB = new Date(valueB).getTime();
    } else {
      valueA = typeof valueA === "string" ? parseFloat(valueA) : valueA;
      valueB = typeof valueB === "string" ? parseFloat(valueB) : valueB;
    }

    if (isNaN(valueA) || isNaN(valueB)) {
      // If the values are not numbers, do a string comparison
      return orderDirection === "asc"
        ? String(a[column]).localeCompare(String(b[column]))
        : String(b[column]).localeCompare(String(a[column]));
    }

    // Numeric or date comparison
    return orderDirection === "asc" ? valueA - valueB : valueB - valueA;
  });
};
