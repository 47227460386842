import {
  GET_CUSTOMERS,
  INIT_ADD_CUSTOMER,
  ADD_CUSTOMER,
  EDIT_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMER_LIST,
  INIT_CUSTOMERS_FILTER,
  SET_CUSTOMERS_FILTER,
} from "@crema/types/actions/dairy/Customer.actions";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  AccountDetails,
  ICustomerDetails,
  ICustomerList,
  ICustomerShort,
  IGetCustomer,
} from "@crema/types/models/dairy/Customer";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialCustomerState = {
  customers: [] as ICustomerList[],
  pagination: null as PaginationType | null,
  customer: null as ICustomerDetails | null,
  details: null as ICustomerDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  customerList: [] as ICustomerShort[],
  customersFilter: {} as any,
};

export const getCustomersAction = createAction<IGetCustomer>(GET_CUSTOMERS);
export const initAddCustomerAction = createAction(INIT_ADD_CUSTOMER);
export const addCustomerAction = createAction<ICustomerDetails>(ADD_CUSTOMER);
export const editCustomerAction = createAction(EDIT_CUSTOMER);
export const deleteCustomerAction =
  createAction<ICustomerList>(DELETE_CUSTOMER);
export const getCustomerAction = createAction<ICustomerDetails>(GET_CUSTOMER);
export const getCustomerListByBranchIdAction =
  createAction<ICustomerShort[]>(GET_CUSTOMER_LIST);
export const initCustomersFilter = createAction(INIT_CUSTOMERS_FILTER);
export const setCustomersFilter = createAction<object>(SET_CUSTOMERS_FILTER);

const customerReducer = createReducer(initialCustomerState, (builder) => {
  builder
    .addCase(getCustomersAction, (state, action) => {
      state.customers = action.payload.customer;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
        ...action.payload.pagination,
      };
    })
    .addCase(initAddCustomerAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addCustomerAction, (state, action) => {
      state.customer = action.payload;
      state.success = true;
    })
    .addCase(editCustomerAction, (state, action) => {
      state.customer = action.payload as ICustomerDetails;
      state.customers = state.customers.map((customer) => {
        if (customer.id === (action.payload as ICustomerDetails).id) {
          return action.payload;
        }
        return customer;
      });
      state.success = true;
    })
    .addCase(deleteCustomerAction, (state, action) => {
      state.customers = state.customers.filter(
        (customer) => customer.id !== action.payload.id,
      );
    })
    .addCase(getCustomerAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getCustomerListByBranchIdAction, (state, action) => {
      state.customerList = action.payload;
    })

    .addCase(initCustomersFilter, (state) => {
      state.customersFilter = {};
    })
    .addCase(setCustomersFilter, (state, action) => {
      state.customersFilter = action.payload;
    });
});

export default customerReducer;
