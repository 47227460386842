import enMessages from "../locales/en_US.json";
import enUS from "antd/lib/locale/en_US";
import enValidationMessages from "../locales/en_validation.json";

const EnLang = {
  messages: {
    ...enMessages,
    ...enValidationMessages,
  },
  antLocale: enUS,
  locale: "en-US",
};
export default EnLang;
