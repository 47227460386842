import React from "react";

const List = React.lazy(() => import("./list"));

export const cashbookComponentRouteConfigs = [
  {
    path: "/manage-report/cashbook",
    element: <List />,
  },
];
