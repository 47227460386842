import React from "react";

const Add = React.lazy(() => import("./add"));
const List = React.lazy(() => import("./list"));
const Edit = React.lazy(() => import("./edit"));
const View = React.lazy(() => import("./view"));

export const customerPaymentsComponentRouteConfigs = [
  {
    path: "/manage-customers/payments/add",
    element: <Add />,
  },
  {
    path: "/manage-customers/payments",
    element: <List />,
  },
  {
    path: "/manage-customers/payments/edit/:id",
    element: <Edit />,
  },
  {
    path: "/manage-customers/payments/view/:id",
    element: <View />,
  },
];
