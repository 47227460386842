import {
  GET_FIXED_CHART,
  GET_FIXED_CHARTS,
} from "@crema/types/actions/dairy/Manage-Chart/FixedChart.actions";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IFixedChartList,
  IGetFixedChart,
} from "@crema/types/models/dairy/Manage-Chart/FixedChart";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialFixedChartState = {
  fixedCharts: [] as IFixedChartList[],
  details: null as IFixedChartList,
  validationError: null as ValidationError,
  success: false as boolean,
};

export const getFixedChartsAction =
  createAction<IGetFixedChart>(GET_FIXED_CHARTS);

export const getFixedChartAction =
  createAction<IFixedChartList>(GET_FIXED_CHART);

const fixedChartReducer = createReducer(initialFixedChartState, (builder) => {
  builder
    .addCase(getFixedChartsAction, (state, action) => {
      state.fixedCharts = action.payload.data;
    })
    .addCase(getFixedChartAction, (state, action) => {
      state.details = action.payload;
    });
});

export default fixedChartReducer;
