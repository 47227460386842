import { IReceiptPaymentList } from "@crema/types/models/dairy/Receipt-Payment/receiptPayment";

export const GET_RECEIPT_PAYMENT = "GET_RECEIPT_PAYMENT";
export const GET_RECEIPT_PAYMENTS = "GET_RECEIPT_PAYMENTS";
export const INIT_ADD_RECEIPT_PAYMENT = "INIT_ADD_RECEIPT_PAYMENT";
export const INIT_RECEIPT_PAYMENT_FILTER = "INIT_RECEIPT_PAYMENT_FILTER";
export const SET_RECEIPT_PAYMENT_FILTER = "SET_RECEIPT_PAYMENT_FILTER";
export const ADD_RECEIPT_PAYMENT = "ADD_RECEIPT_PAYMENT";
export const EDIT_RECEIPT_PAYMENT = "EDIT_RECEIPT_PAYMENT";
export const DELETE_RECEIPT_PAYMENT = "DELETE_RECEIPT_PAYMENT";
export const GET_RECEIPT_PAYMENT_LIST = "GET_RECEIPT_PAYMENT_LIST";

export type GetReceiptPaymentActions = {
  type: typeof GET_RECEIPT_PAYMENT;
  payload: IReceiptPaymentList[];
};

export type GetReceiptPaymentsActions = {
  type: typeof GET_RECEIPT_PAYMENTS;
  payload: IReceiptPaymentList[];
};

export type InitAddReceiptPaymentsActions = {
  type: typeof INIT_ADD_RECEIPT_PAYMENT;
  payload: IReceiptPaymentList[];
};

export type InitReceiptPaymentFilterActions = {
  type: typeof INIT_RECEIPT_PAYMENT_FILTER;
};

export type SetReceiptPaymentFilterActions = {
  type: typeof SET_RECEIPT_PAYMENT_FILTER;
  payload: IReceiptPaymentList;
};

export type AddReceiptPaymentActions = {
  type: typeof ADD_RECEIPT_PAYMENT;
  payload: IReceiptPaymentList;
};

export type EditReceiptPaymentActions = {
  type: typeof EDIT_RECEIPT_PAYMENT;
  payload: IReceiptPaymentList;
};

export type DeleteReceiptPaymentActions = {
  type: typeof DELETE_RECEIPT_PAYMENT;
  payload: IReceiptPaymentList;
};

export type GetReceiptPaymentListActions = {
  type: typeof GET_RECEIPT_PAYMENT_LIST;
  payload: IReceiptPaymentList;
};

export type ReceiptPaymentActions =
  | GetReceiptPaymentActions
  | GetReceiptPaymentsActions
  | InitAddReceiptPaymentsActions
  | InitReceiptPaymentFilterActions
  | SetReceiptPaymentFilterActions
  | AddReceiptPaymentActions
  | EditReceiptPaymentActions
  | DeleteReceiptPaymentActions
  | GetReceiptPaymentActions
  | GetReceiptPaymentListActions;
