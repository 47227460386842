import {
  GET_FD_CALCULATION,
  GET_RD_CALCULATION,
  INIT_FD_CALCULATION,
  INIT_RD_CALCULATION,
  GET_CUSTOMERS_LIST,
  SET_CUSTOMERS_FILTER,
} from "@crema/types/actions/Utility.action";
import {
  IRDCalculator,
  IFDCalculator,
  IGetCustomer,
  ICustomerList,
} from "@crema/types/models/Utility";
import { createAction, createReducer } from "@reduxjs/toolkit";

interface UtilityState {
  fdCalculation: IFDCalculator;
  rdCalculation: IRDCalculator;
  customers: ICustomerList[];
  customersFilter: object;
}

const initialState: UtilityState = {
  rdCalculation: {} as IRDCalculator,
  fdCalculation: {} as IFDCalculator,
  customers: [] as ICustomerList[],
  customersFilter: {} as object,
};

export const getFdCalculation = createAction<IFDCalculator>(GET_FD_CALCULATION);
export const getRdCalculation = createAction<IRDCalculator>(GET_RD_CALCULATION);
export const initFdCalculation = createAction(INIT_FD_CALCULATION);
export const initRdCalculation = createAction(INIT_RD_CALCULATION);
export const getCustomerListAction =
  createAction<IGetCustomer>(GET_CUSTOMERS_LIST);
export const setCustomersFilter = createAction<object>(SET_CUSTOMERS_FILTER);

const utilityReducer = createReducer(initialState, (builder) => {
  builder.addCase(getFdCalculation, (state, action) => {
    state.fdCalculation = action.payload;
  });
  builder.addCase(getRdCalculation, (state, action) => {
    state.rdCalculation = action.payload;
  });
  builder.addCase(initRdCalculation, (state) => {
    state.rdCalculation = {} as IRDCalculator;
  });
  builder.addCase(initFdCalculation, (state) => {
    state.fdCalculation = {} as IFDCalculator;
  });
  builder.addCase(getCustomerListAction, (state, action) => {
    state.customers = action.payload.customer;
  });
  builder.addCase(setCustomersFilter, (state, action) => {
    state.customersFilter = action.payload;
  });
});

export default utilityReducer;
