import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
  fetchSuccess,
  fetchError,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IMilkList,
  IMilkDetails,
  IGetMilk,
  rate,
} from "@crema/types/models/dairy/Milk";
import {
  ADD_MILK,
  DELETE_MILK,
  EDIT_MILK,
  GET_CHARTS_FOR_MILK,
  GET_CUSTOMERS_FOR_MILK,
  GET_MILK,
  GET_MILKS,
  INIT_ADD_MILK,
  INIT_MILKS_FILTER,
  SET_EDIT_MILK_ID,
  SET_MILKS_FILTER,
  SET_MILK_RATE,
} from "@crema/types/actions/dairy/Milk.actions";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";
import { IGetCustomer } from "@crema/types/models/dairy/Customer";
import {
  IChartDetails,
  IChartList,
  IGetChart,
} from "@crema/types/models/dairy/Manage-Chart/Chart";
import { appIntl } from "@crema/helpers/Common";

export const getMilks = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/buy-milk", { params: filter })
      .then((response: APIResponse<IGetMilk>) => {
        handleApiResponse(dispatch, response, GET_MILKS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getCustomerForMilks = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer", { params: filter })
      .then((response: APIResponse<IGetCustomer>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMERS_FOR_MILK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getChartsForMilks = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    const { messages } = appIntl();
    dispatch(fetchStart());
    jwtAxios
      .get("/chart", { params: filter })
      .then((response: APIResponse<IGetChart>) => {
        if (response.status === 200 || response.status === 201) {
          const chart = [];
          const promises = response.data.data.chart.map((cart, index) => {
            return jwtAxios
              .get(`/chart/${cart.id}`)
              .then((response: APIResponse<IChartDetails>) => {
                chart.push(response.data?.data);
              })
              .catch((error: APIErrorResProps) => {
                handleErrorResponse(dispatch, error);
              });
          });
          Promise.all(promises)
            .then(() => {
              dispatch({ type: GET_CHARTS_FOR_MILK, payload: chart });
            })
            .catch((error) => {
              dispatch(
                fetchError(String(messages["message.somethingWentWrong"])),
              );
            });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateMilk = (
  method: "post" | "put",
  data: object,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/buy-milk${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IMilkDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_MILK : EDIT_MILK,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteMilk = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/buy-milk/${id}`, data)
      .then((response: APIResponse<IMilkList>) => {
        handleApiResponse(dispatch, response, DELETE_MILK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getMilkById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/buy-milk/${id}`)
      .then((response: APIResponse<IMilkDetails>) => {
        handleApiResponse(dispatch, response, GET_MILK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddMilk = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_MILK });
  };
};

export const initMilksFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_MILKS_FILTER });
};

export const setMilksFilter =
  (formData: IMilkDetails) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_MILKS_FILTER, payload: formData });
  };

export const setMilkRate = (rate: rate) => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: SET_MILK_RATE, payload: rate });
};

export const editMilk = (id: number) => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: SET_EDIT_MILK_ID, payload: id });
};
