import { IExportFile } from "@crema/types/models/APIDataProps";
import { IMilkReportColumns } from "@crema/types/models/dairy/Report/CustomerMilk";
import {
  IMilkReportDetails,
  IMilkReportList,
  IMilkReportShort,
} from "@crema/types/models/dairy/Report/MilkReport/MilkReport";

export const GET_MILK_REPORTS = "GET_MILK_REPORTS";
export const INIT_ADD_MILK_REPORT = "INIT_ADD_MILK_REPORT";
export const ADD_MILK_REPORT = "ADD_MILK_REPORT";
export const EDIT_MILK_REPORT = "EDIT_MILK_REPORT";
export const DELETE_MILK_REPORT = "DELETE_MILK_REPORT";
export const GET_MILK_REPORT = "GET_MILK_REPORT";
export const GET_MILK_REPORT_LIST = "GET_MILK_REPORT_LIST";
export const INIT_MILK_REPORTS_FILTER = "INIT_MILK_REPORTS_FILTER";
export const SET_MILK_REPORTS_FILTER = "SET_MILK_REPORTS_FILTER";
export const GET_MILK_REPORTS_FILTER = "GET_MILK_REPORTS_FILTER";
export const SET_EDIT_MILK_REPORT_ID = "SET_EDIT_MILK_REPORT_ID";
export const EXPORT_MILK_REPORT = "EXPORT_MILK_REPORT";
export const GET_MILK_REPORT_COLUMNS = "GET_MILK_REPORT_COLUMNS";
export const EXPORTED_MILK_REPORT = "EXPORTED_MILK_REPORT";

export type GetMilkReportsActions = {
  type: typeof GET_MILK_REPORTS;
  payload: IMilkReportList[];
};

export type InitAddMilkReportActions = {
  type: typeof INIT_ADD_MILK_REPORT;
};

export type AddMilkReportActions = {
  type: typeof ADD_MILK_REPORT;
  payload: IMilkReportDetails;
};

export type EditMilkReportActions = {
  type: typeof EDIT_MILK_REPORT;
  payload: IMilkReportDetails;
};

export type GetMilkReportActions = {
  type: typeof GET_MILK_REPORT;
  payload: IMilkReportDetails;
};

export type GetMilkReportListActions = {
  type: typeof GET_MILK_REPORT_LIST;
  payload: IMilkReportShort[];
};

export type DeleteMilkReportActions = {
  type: typeof DELETE_MILK_REPORT;
  payload: IMilkReportList;
};

export type InitMilkReportsFilter = {
  type: typeof INIT_MILK_REPORTS_FILTER;
};

export type SetMilkReportsFilter = {
  type: typeof SET_MILK_REPORTS_FILTER;
  payload: object;
};

export type GetMilkReportsFilterActions = {
  type: typeof GET_MILK_REPORTS_FILTER;
  payload: IMilkReportList[];
};
export type SetMilkReportIdForEditActions = {
  type: typeof SET_EDIT_MILK_REPORT_ID;
  payload: number;
};

export type GetMilkReportColumnsAction = {
  type: typeof GET_MILK_REPORT_COLUMNS;
  payload: IMilkReportColumns;
};

export type ExportMilkReportAction = {
  type: typeof EXPORT_MILK_REPORT;
  payload: IExportFile;
};
export type ExportedMilkReportAction = {
  type: typeof EXPORTED_MILK_REPORT;
};

export type MilkReportActions =
  | GetMilkReportsActions
  | InitAddMilkReportActions
  | AddMilkReportActions
  | EditMilkReportActions
  | DeleteMilkReportActions
  | GetMilkReportActions
  | GetMilkReportListActions
  | InitMilkReportsFilter
  | SetMilkReportsFilter
  | SetMilkReportIdForEditActions
  | GetMilkReportColumnsAction
  | ExportedMilkReportAction;
