import {
  GET_PROFILE,
  GET_USER_LIST,
  SWITCH_PROFILE,
} from "@crema/constants/ActionTypes";
import { appIntl } from "@crema/helpers/Common";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { Dispatch } from "redux";
import { AppActions } from "@crema/types/actions";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  handleApiResponse,
} from "./Common";
import { APIResponse } from "@crema/types/models/APIDataProps";
import { IProfile } from "@crema/types/models/Apps";

export const onGetUserList = () => {
  return (dispatch: Dispatch<AppActions>) => {
    const { messages } = appIntl();
    dispatch(fetchStart());
    jwtAxios
      .get("/api/user/list")
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_USER_LIST, payload: data.data });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const switchProfile = (companyId: number, roleId: number) => {
  return (dispatch) => {
    jwtAxios
      .get(`getToken/${roleId}/${companyId}`)
      .then((response) => {
        handleApiResponse(dispatch, response, SWITCH_PROFILE);
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getProfile1 = () => {
  return (dispatch) => {
    jwtAxios
      .get(`profile`)
      .then((response: APIResponse<IProfile>) => {
        handleApiResponse(dispatch, response, GET_PROFILE);
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};
