import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IReceiptList,
  IReceiptDetails,
  IReceiptShort,
  IGetReceipt,
  IReceiptDraft,
} from "@crema/types/models/dairy/Manage-Customer/Receipt/Receipt";
import {
  ADD_RECEIPT,
  DELETE_RECEIPT,
  EDIT_RECEIPT,
  EXPORTED_RECEIPTS,
  EXPORT_RECEIPTS,
  GET_RECEIPT,
  GET_RECEIPTS,
  GET_RECEIPT_DRAFT,
  GET_RECEIPT_LIST,
  INIT_ADD_RECEIPT,
  INIT_RECEIPTS_FILTER,
  PAYMENT_RECEIPT,
  SETTLE_RECEIPT,
  SET_RECEIPTS_FILTER,
} from "@crema/types/actions/dairy/Manage-Customer/Receipt/Receipt";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getReceipts = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/manage-customer/receipt", { params: filter })
      .then((response: APIResponse<IGetReceipt>) => {
        handleApiResponse(dispatch, response, GET_RECEIPTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getReceiptDraft = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/manage-customer/receipt-draft", { params: filter })
      .then((response: APIResponse<IReceiptDraft>) => {
        handleApiResponse(dispatch, response, GET_RECEIPT_DRAFT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const exportReceipt = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/manage-customer/receipt/export", { params: filter })
      .then((response: APIResponse<IGetReceipt>) => {
        handleApiResponse(dispatch, response, EXPORT_RECEIPTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateReceipt = (
  method: "post" | "put",
  data: IReceiptDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/manage-customer/receipt${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IReceiptDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_RECEIPT : EDIT_RECEIPT,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteReceipt = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/manage-customer/receipt/${id}`, data)
      .then((response: APIResponse<IReceiptList>) => {
        handleApiResponse(dispatch, response, DELETE_RECEIPT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const settleReceipt = (id: number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`manage-customer/receipt/${id}/settle`)
      .then((response: APIResponse<IReceiptList>) => {
        handleApiResponse(dispatch, response, SETTLE_RECEIPT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const receiptPayment = (id: string, form: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`manage-customer/receipt/${id}/payment`, form)
      .then((response: APIResponse<IReceiptList>) => {
        handleApiResponse(dispatch, response, PAYMENT_RECEIPT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getReceiptById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-customer/receipt/${id}`)
      .then((response: APIResponse<IReceiptDetails>) => {
        handleApiResponse(dispatch, response, GET_RECEIPT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getReceiptList = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-customer/receipt`, { params: filter })
      .then((response: APIResponse<Array<IGetReceipt>>) => {
        handleApiResponse(dispatch, response, GET_RECEIPT_LIST);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddReceipt = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_RECEIPT });
  };
};

export const initReceiptsFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_RECEIPTS_FILTER });
};

export const setReceiptsFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_RECEIPTS_FILTER, payload: formData });
  };
export const exportedReceipt = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: EXPORTED_RECEIPTS });
  };
};
