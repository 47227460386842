import {
  GET_COMPANY_DETAILS,
  INIT_ADD_COMPANY_DETAIL,
  GET_COMPANY_DETAIL,
  INIT_COMPANY_DETAILS_FILTER,
  SET_COMPANY_DETAILS_FILTER,
} from "@crema/types/actions/customer/company";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  ICompanyDetailList,
  IGetCompanyDetail,
} from "@crema/types/models/customer/company";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialCompanyDetailState = {
  companyDetails: [] as ICompanyDetailList[],
  pagination: null as PaginationType | null,
  companyDetail: null as ICompanyDetailList | null,
  details: null as ICompanyDetailList | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  companyDetailsFilter: {} as object | ICompanyDetailList,
  toggleToReload: false as boolean,
};

export const getProductsAction =
  createAction<IGetCompanyDetail>(GET_COMPANY_DETAILS);
export const initAddProductAction = createAction(INIT_ADD_COMPANY_DETAIL);
export const getProductAction =
  createAction<ICompanyDetailList>(GET_COMPANY_DETAIL);
export const initProductsFilter = createAction(INIT_COMPANY_DETAILS_FILTER);
export const setProductsFilter = createAction<object>(
  SET_COMPANY_DETAILS_FILTER,
);

const companyDetailReducer = createReducer(
  initialCompanyDetailState,
  (builder) => {
    builder
      .addCase(getProductsAction, (state, action) => {
        state.companyDetails = action.payload.companyDetail;
        state.pagination = {
          total: action.payload.pagination.totalCount,
          from: action.payload.pagination.pageNumber,
          perPage: Number(action.payload.pagination.pageSize),
        };
      })
      .addCase(initAddProductAction, (state) => {
        state.details = null;
        state.validationError = null;
        state.success = false;
      })
      .addCase(getProductAction, (state, action) => {
        state.details = action.payload;
      })
      .addCase(initProductsFilter, (state) => {
        state.companyDetailsFilter = {};
      })
      .addCase(setProductsFilter, (state, action) => {
        state.companyDetailsFilter = action.payload;
      });
  },
);

export default companyDetailReducer;
