import {
  GET_CLUSTERS,
  INIT_ADD_CLUSTER,
  ADD_CLUSTER,
  EDIT_CLUSTER,
  DELETE_CLUSTER,
  GET_CLUSTER,
  GET_CLUSTER_LIST,
  INIT_CLUSTERS_FILTER,
  SET_CLUSTERS_FILTER,
} from "@crema/types/actions/dairy/Manage-Cluster/Cluster.actions";
import {
  PaginationType,
  ValidationError,
} from "@crema/types/models/APIDataProps";
import {
  IClusterDetails,
  IClusterList,
  IClusterShort,
  IGetCluster,
} from "@crema/types/models/dairy/Manage-Cluster/Cluster";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialClusterState = {
  clusters: [] as IClusterList[],
  pagination: null as PaginationType | null,
  cluster: null as IClusterDetails | null,
  details: null as IClusterDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  clusterList: [] as IClusterShort[],
  clustersFilter: {} as any,
};

export const getClustersAction = createAction<IGetCluster>(GET_CLUSTERS);
export const initAddClusterAction = createAction(INIT_ADD_CLUSTER);
export const addClusterAction = createAction<IClusterDetails>(ADD_CLUSTER);
export const editClusterAction = createAction(EDIT_CLUSTER);
export const deleteClusterAction = createAction<IClusterList>(DELETE_CLUSTER);
export const getClusterAction = createAction<IClusterDetails>(GET_CLUSTER);
export const getClusterListAction =
  createAction<IClusterShort[]>(GET_CLUSTER_LIST);
export const initClustersFilter = createAction(INIT_CLUSTERS_FILTER);
export const setClustersFilter = createAction<object>(SET_CLUSTERS_FILTER);

const clusterReducer = createReducer(initialClusterState, (builder) => {
  builder
    .addCase(getClustersAction, (state, action) => {
      state.clusters = action.payload.cluster;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
        ...action.payload.pagination,
      };
    })
    .addCase(initAddClusterAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addClusterAction, (state, action) => {
      state.cluster = action.payload;
      state.success = true;
    })
    .addCase(editClusterAction, (state, action) => {
      state.cluster = action.payload as IClusterDetails;
      state.clusters = state.clusters.map((cluster) => {
        if (cluster.id === (action.payload as IClusterDetails).id) {
          return action.payload;
        }
        return cluster;
      });
      state.success = true;
    })
    .addCase(deleteClusterAction, (state, action) => {
      state.clusters = state.clusters.filter(
        (cluster) => cluster.id !== action.payload.id,
      );
      state.pagination.total = state.pagination.total - 1;
    })
    .addCase(getClusterAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getClusterListAction, (state, action) => {
      state.clusterList = action.payload;
    })
    .addCase(initClustersFilter, (state) => {
      state.clustersFilter = {};
    })
    .addCase(setClustersFilter, (state, action) => {
      state.clustersFilter = action.payload;
    });
});

export default clusterReducer;
