import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  ICustomerProductList,
  ICustomerProductDetails,
  ICustomerProductShort,
  IGetCustomerProduct,
} from "@crema/types/models/dairy/Manage-Customer/CustomerProducts/CustomerProducts";
import {
  ADD_CUSTOMER_PRODUCT,
  DELETE_CUSTOMER_PRODUCT,
  EDIT_CUSTOMER_PRODUCT,
  GET_CUSTOMER_PRODUCT,
  GET_CUSTOMER_PRODUCTS,
  GET_CUSTOMER_PRODUCT_LIST,
  INIT_ADD_CUSTOMER_PRODUCT,
  INIT_CUSTOMER_PRODUCTS_FILTER,
  SET_CUSTOMER_PRODUCTS_FILTER,
} from "@crema/types/actions/dairy/Manage-Customer/CustomerProducts/CustomerProducts";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getCustomerProducts = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("manage-customer/product", { params: filter })
      .then((response: APIResponse<IGetCustomerProduct>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER_PRODUCTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateCustomerProduct = (
  method: "post" | "put",
  data: ICustomerProductDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/manage-customer/product${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<ICustomerProductDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_CUSTOMER_PRODUCT : EDIT_CUSTOMER_PRODUCT,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteCustomerProduct = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/manage-customer/product/${id}`, data)
      .then((response: APIResponse<ICustomerProductList>) => {
        handleApiResponse(dispatch, response, DELETE_CUSTOMER_PRODUCT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getCustomerProductById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`manage-customer/product/${id}`)
      .then((response: APIResponse<ICustomerProductDetails>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER_PRODUCT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getCustomerProductList = (
  branchId: string | number,
  search: string,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-customer/product`, { params: { branchId, search } })
      .then((response: APIResponse<Array<ICustomerProductShort>>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER_PRODUCT_LIST);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddCustomerProduct = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_CUSTOMER_PRODUCT });
  };
};

export const initCustomerProductsFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_CUSTOMER_PRODUCTS_FILTER });
  };

export const setCustomerProductsFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CUSTOMER_PRODUCTS_FILTER, payload: formData });
  };
