import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import { ITagList, ITagDetails } from "@crema/types/models/superAdmin/tag";
import {
  ADD_TAG,
  DELETE_TAG,
  EDIT_TAG,
  GET_TAG,
  GET_TAGS,
  INIT_ADD_TAG,
  SET_EDIT_TAG_ID,
} from "@crema/types/actions/superAdmin/Tag.action";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getTags = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/admin-api/tags", { params: filter })
      .then((response: APIResponse<ITagList[]>) => {
        handleApiResponse(dispatch, response, GET_TAGS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateTag = (
  method: "post" | "put",
  data: ITagDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/admin-api/tags${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<ITagDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_TAG : EDIT_TAG,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteTag = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/admin-api/tags/${id}`, data)
      .then((response: APIResponse<ITagList>) => {
        handleApiResponse(dispatch, response, DELETE_TAG);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getTagById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/tags/${id}`)
      .then((response: APIResponse<ITagDetails>) => {
        handleApiResponse(dispatch, response, GET_TAG);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddTag = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_TAG });
  };
};

export const editTag = (id: number) => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: SET_EDIT_TAG_ID, payload: id });
};
