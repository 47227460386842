import {
  ADD_BILL,
  DELETE_BILL,
  EDIT_BILL,
  GET_BILL,
  GET_BILLS,
  GET_BILL_LIST,
  INIT_ADD_BILL,
  INIT_BILLS_FILTER,
  SET_BILLS_FILTER,
  SET_EDIT_BILL_ID,
} from "@crema/types/actions/dairy/Manage-Customer/Bill";
import {
  PaginationType,
  ValidationError,
} from "@crema/types/models/APIDataProps";
import {
  IBill,
  IBillDetails,
  IBillPagination,
  IGetBills,
} from "@crema/types/models/dairy/Manage-Customer/Bill";
import { createAction, createReducer } from "@reduxjs/toolkit";

// Actions
export const getBillsAction = createAction<IGetBills>(GET_BILLS);
export const initAddBillAction = createAction(INIT_ADD_BILL);
export const addBillAction = createAction<IBillDetails>(ADD_BILL);
export const editBillAction = createAction<IBillDetails>(EDIT_BILL);
export const deleteBillAction = createAction<IBill>(DELETE_BILL);
export const getBillAction = createAction<IBillDetails>(GET_BILL);
export const getBillListAction = createAction<IBill[]>(GET_BILL_LIST);
export const initBillsFilter = createAction(INIT_BILLS_FILTER);
export const setBillsFilter = createAction<object>(SET_BILLS_FILTER);
export const setEditBillId = createAction<number>(SET_EDIT_BILL_ID);

// Initial State
const initialBillState = {
  bills: [] as IBill[],
  pagination: null as PaginationType | null,
  billFooter: null as IBillPagination | null,
  bill: null as IBillDetails | null,
  details: null as IBillDetails,
  validationError: null as ValidationError | null,
  success: false as boolean,
  billList: [] as IBill[],
  billsFilter: {
    pageNumber: 0,
    pageSize: 10,
  } as object,
  toggleToReload: false as boolean,
  billId: 0 as number,
};

// Reducer
const billReducer = createReducer(initialBillState, (builder) => {
  builder
    .addCase(getBillsAction, (state, action) => {
      state.bills = action.payload.bill;
      state.pagination = {
        ...action.payload.pagination,
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: action.payload.pagination.pageSize,
      };
      state.billFooter = action.payload.pagination;
    })
    .addCase(initAddBillAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addBillAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(editBillAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(deleteBillAction, (state, action) => {
      state.bills = state.bills.filter((bill) => bill.id !== action.payload.id);
    })
    .addCase(getBillAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getBillListAction, (state, action) => {
      state.billList = action.payload;
    })
    .addCase(initBillsFilter, (state) => {
      state.billsFilter = {
        pageNumber: 0,
        pageSize: 10,
      };
    })
    .addCase(setBillsFilter, (state, action) => {
      state.billsFilter = action.payload;
    })
    .addCase(setEditBillId, (state, action) => {
      state.billId = action.payload;
    });
});

export default billReducer;
