import {
  GET_DAIRY_CUSTOMERS,
  INIT_ADD_DAIRY_CUSTOMER,
  ADD_DAIRY_CUSTOMER,
  EDIT_DAIRY_CUSTOMER,
  DELETE_DAIRY_CUSTOMER,
  GET_DAIRY_CUSTOMER,
  INIT_DAIRY_CUSTOMERS_FILTER,
  SET_DAIRY_CUSTOMERS_FILTER,
  ATTACH_TAG_DAIRY_CUSTOMER,
  ADD_NOTE_DAIRY_CUSTOMER,
  ADD_DESCRIPTION_DAIRY_CUSTOMER,
  GET_NOTES_DAIRY_CUSTOMER,
} from "@crema/types/actions/superAdmin/DairyCustomer/DairyCustomer.actions";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IAttachedUserTags,
  ICompanyDetails,
  IDairyCustomerDetails,
  IDairyCustomerFooter,
  IDairyCustomerList,
  IDairyCustomerTag,
  IGetDairyCustomer,
} from "@crema/types/models/superAdmin/dairyCustomer/dairyCustomer";
import {
  IGetInteraction,
  Interaction,
} from "@crema/types/models/superAdmin/interaction";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { IInteractionData } from "./Note";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialDairyCustomerState = {
  dairyCustomers: [] as IDairyCustomerList[],
  pagination: null as PaginationType | null,
  dairyCustomerFooter: null as IDairyCustomerFooter | null,
  dairyCustomer: null as IDairyCustomerDetails | null,
  details: null as IDairyCustomerDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  dairyCustomersFilter: {
    pageNumber: 1,
    pageSize: 10,
  } as any | IDairyCustomerDetails,
  toggleToReload: false as boolean,
  interactions: [] as any[],
  loading: false as boolean,
  error: null as string | null,
  tags: [] as IDairyCustomerTag[],
  tagError: null as string | null,
  notes: [] as Interaction[],
};

export const getDairyCustomersAction =
  createAction<IGetDairyCustomer>(GET_DAIRY_CUSTOMERS);
export const initAddDairyCustomerAction = createAction(INIT_ADD_DAIRY_CUSTOMER);
export const addDairyCustomerAction =
  createAction<IDairyCustomerDetails>(ADD_DAIRY_CUSTOMER);
export const editDairyCustomerAction = createAction(EDIT_DAIRY_CUSTOMER);
export const deleteDairyCustomerAction = createAction<IDairyCustomerList>(
  DELETE_DAIRY_CUSTOMER,
);
export const getDairyCustomerAction =
  createAction<IDairyCustomerDetails>(GET_DAIRY_CUSTOMER);
export const initDairyCustomersFilter = createAction(
  INIT_DAIRY_CUSTOMERS_FILTER,
);
export const setDairyCustomersFilter = createAction<object>(
  SET_DAIRY_CUSTOMERS_FILTER,
);
export const attachTagDairyCustomerActions = createAction<IAttachedUserTags>(
  ATTACH_TAG_DAIRY_CUSTOMER,
);
export const addNoteDairyCustomerActions = createAction<IInteractionData>(
  ADD_NOTE_DAIRY_CUSTOMER,
);
export const addDescriptionDairyCustomerActions = createAction<ICompanyDetails>(
  ADD_DESCRIPTION_DAIRY_CUSTOMER,
);
export const getNotesDairyCustomerActions = createAction<IGetInteraction>(
  GET_NOTES_DAIRY_CUSTOMER,
);

const dairyCustomerReducer = createReducer(
  initialDairyCustomerState,
  (builder) => {
    builder

      .addCase(getDairyCustomersAction, (state, action) => {
        state.dairyCustomers = action.payload?.dairy || [];
        state.dairyCustomerFooter = action.payload?.pagination;
        state.pagination = {
          total: action.payload?.pagination?.totalCount,
          from: action.payload?.pagination?.pageNumber,
          perPage: Number(action.payload?.pagination.pageSize),
        };
      })

      .addCase(initAddDairyCustomerAction, (state) => {
        state.details = null;
        state.validationError = null;
        state.success = false;
      })

      .addCase(addDairyCustomerAction, (state, action) => {
        state.success = true;
        state.toggleToReload = !state.toggleToReload;
      })

      .addCase(editDairyCustomerAction, (state) => {
        state.success = true;
        state.toggleToReload = !state.toggleToReload;
      })

      .addCase(deleteDairyCustomerAction, (state, action) => {
        state.dairyCustomers = state.dairyCustomers.filter(
          (product) => product.userId !== action.payload.userId,
        );
      })

      .addCase(getDairyCustomerAction, (state, action) => {
        state.details = action.payload;
      })

      .addCase(initDairyCustomersFilter, (state) => {
        state.dairyCustomersFilter = {
          pageNumber: 1,
          pageSize: 10,
        };
      })
      .addCase(setDairyCustomersFilter, (state, action) => {
        state.dairyCustomersFilter = action.payload;
      })
      .addCase(getNotesDairyCustomerActions, (state, action) => {
        state.notes = action.payload.interaction;
      })
      .addCase(addDescriptionDairyCustomerActions, (state, action) => {
        const companyInfo = action.payload;
        state.dairyCustomers = state.dairyCustomers.map((customer) =>
          customer.id === companyInfo.id
            ? { ...customer, description: companyInfo.description }
            : customer,
        );
      })
      .addCase(addNoteDairyCustomerActions, (state, action) => {
        const interaction = action.payload;
        state.dairyCustomers = state.dairyCustomers.map((customer) =>
          customer.userId === interaction.userId
            ? { ...customer, notes: interaction.notes }
            : customer,
        );
      })
      .addCase(attachTagDairyCustomerActions, (state, action) => {
        const userTags = action.payload;
        state.dairyCustomers = state.dairyCustomers.map((customer) => {
          if (customer.userId === userTags.userId)
            return {
              ...customer,
              tagNames: userTags.tags?.map((tag) => tag.name).join(","),
              tagIds: userTags.tags?.map((tag) => tag.id).join(","),
            };
          return customer;
        });
      });
  },
);

export default dairyCustomerReducer;
