import React from "react";
import { Row } from "antd";
import { useIntl } from "react-intl";
import { useAuthUser } from "@crema/hooks/AuthHooks";
import AppPageMeta from "@crema/components/AppPageMeta";
import { ChooseDashboardCard } from "./card";
export interface ICompany {
  id: number;
  name: string;
}
const ChooseDashboard: React.FC = () => {
  const { messages } = useIntl();
  const { user } = useAuthUser();
  return (
    <>
      <AppPageMeta title={String(messages["auth.chooseDashboard"])} />
      <Row gutter={16}>
        {user?.roles.map((role, index) => {
          const isDefault =
            role.id === user.defaultRole?.roleId &&
            user.defaultRole?.type === "role";
          return role?.companies.map((company: ICompany, index) => {
            return (
              <ChooseDashboardCard
                key={"role-" + index}
                role={role}
                type="role"
                company={company}
                isDefault={isDefault}
              />
            );
          });
        })}
      </Row>
    </>
  );
};

export default ChooseDashboard;
