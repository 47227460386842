import {
  ICompanyDetails,
  ICompanyList,
  ICompanyShort,
} from "@crema/types/models/superAdmin/company/company";

// Define Action Types for Company
export const GET_COMPANIES = "GET_COMPANIES";
export const INIT_ADD_COMPANY = "INIT_ADD_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const INIT_COMPANIES_FILTER = "INIT_COMPANIES_FILTER";
export const SET_COMPANIES_FILTER = "SET_COMPANIES_FILTER";
export const GET_COMPANIES_FILTER = "GET_COMPANIES_FILTER";

// Define Action Type Interfaces
export type GetCompaniesActions = {
  type: typeof GET_COMPANIES;
  payload: ICompanyList[];
};

export type InitAddCompanyActions = {
  type: typeof INIT_ADD_COMPANY;
};

export type AddCompanyActions = {
  type: typeof ADD_COMPANY;
  payload: ICompanyDetails;
};

export type EditCompanyActions = {
  type: typeof EDIT_COMPANY;
  payload: ICompanyDetails;
};

export type DeleteCompanyActions = {
  type: typeof DELETE_COMPANY;
  payload: ICompanyList;
};

export type GetCompanyActions = {
  type: typeof GET_COMPANY;
  payload: ICompanyDetails;
};

export type GetCompanyListActions = {
  type: typeof GET_COMPANY_LIST;
  payload: ICompanyShort[];
};

export type InitCompaniesFilter = {
  type: typeof INIT_COMPANIES_FILTER;
};

export type SetCompaniesFilter = {
  type: typeof SET_COMPANIES_FILTER;
  payload: object;
};

export type GetCompaniesFilterActions = {
  type: typeof GET_COMPANIES_FILTER;
  payload: ICompanyList[];
};

// Union Type for Company Actions
export type CompanyActions =
  | GetCompaniesActions
  | InitAddCompanyActions
  | AddCompanyActions
  | EditCompanyActions
  | DeleteCompanyActions
  | GetCompanyActions
  | GetCompanyListActions
  | InitCompaniesFilter
  | SetCompaniesFilter
  | GetCompaniesFilterActions;
