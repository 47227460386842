import React from "react";

const List = React.lazy(() => import("./list"));

export const clusterMilkReportComponentRouteConfigs = [
  {
    path: "/manage-report/cluster-milk-report",
    element: <List />,
  },
];
