import {
  GET_USERS,
  INIT_ADD_USER,
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
  GET_USER,
  INIT_USERS_FILTER,
  SET_USERS_FILTER,
} from "@crema/types/actions/superAdmin/User/User.action";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IUserDetails,
  IUserList,
  IGetUser,
  roles,
  IUsersFilter,
} from "@crema/types/models/superAdmin/user/user";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialUserState = {
  users: [] as IUserList[],
  pagination: null as PaginationType | null,
  user: null as IUserDetails | null,
  details: null as IUserDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  usersFilter: {
    pageNumber: 1,
    pageSize: 10,
  } as IUsersFilter,
  toggleToReload: false as boolean,
  role: [] as roles[],
};

export const getUsersAction = createAction<IGetUser>(GET_USERS);
export const initAddUserAction = createAction(INIT_ADD_USER);
export const addUserAction = createAction<IUserDetails>(ADD_USER);
export const editUserAction = createAction(EDIT_USER);
export const deleteUserAction = createAction<IUserList>(DELETE_USER);
export const getUserAction = createAction<IUserDetails>(GET_USER);
export const initUsersFilter = createAction(INIT_USERS_FILTER);
export const setUsersFilter = createAction<object>(SET_USERS_FILTER);

const userReducer = createReducer(initialUserState, (builder) => {
  builder
    .addCase(getUsersAction, (state, action) => {
      state.users = action.payload.users;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
      };
    })
    .addCase(initAddUserAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addUserAction, (state, action) => {
      state.user = action.payload;
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(editUserAction, (state, action) => {
      state.user = action.payload;
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(deleteUserAction, (state, action) => {
      state.users = state.users.filter(
        (product) => product.userId !== action.payload.userId,
      );
    })
    .addCase(getUserAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(initUsersFilter, (state) => {
      state.usersFilter = {
        pageNumber: 1,
        pageSize: 10,
      };
    })
    .addCase(setUsersFilter, (state, action) => {
      state.usersFilter = action.payload;
    });
});

export default userReducer;
