import { appIntl } from "@crema/helpers/Common";
import { AppActions } from "@crema/types/actions";
import {
  CLEAR_VALIDATION_ERROR,
  CommonActionTypes,
  VALIDATION_ERROR_DISPLAYED,
} from "@crema/types/actions/Common.action";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  VALIDATION_ERROR,
} from "@crema/types/actions/Common.action";
import { APIResponse, ValidationError } from "@crema/types/models/APIDataProps";
import { Dispatch } from "redux";

export const fetchStart = (): CommonActionTypes => ({ type: FETCH_START });

export const fetchSuccess = (): CommonActionTypes => ({ type: FETCH_SUCCESS });
// export const updatingContent = () => {
//   return (dispatch: Dispatch<AppActions>) => dispatch({ type: UPDATING_CONTENT });
// };

export const fetchError = (error: string): CommonActionTypes => ({
  type: FETCH_ERROR,
  payload: error,
});

export const showMessage = (message: string): CommonActionTypes => ({
  type: SHOW_MESSAGE,
  payload: message,
});

export const onToggleAppDrawer = (): CommonActionTypes => ({
  type: TOGGLE_APP_DRAWER,
});

export const hideMessage = (): CommonActionTypes => ({ type: HIDE_MESSAGE });

export const validationError = (
  validationError: ValidationError,
): CommonActionTypes => ({ type: VALIDATION_ERROR, payload: validationError });

export const clearValidationErrors = (): CommonActionTypes => ({
  type: CLEAR_VALIDATION_ERROR,
});

export const setErrorsDisplayed = (flag: boolean): CommonActionTypes => ({
  type: VALIDATION_ERROR_DISPLAYED,
  payload: flag,
});

export const handleApiResponse = (
  dispatch: Dispatch<AppActions>,
  response: APIResponse<any>,
  successType,
  errorMessage: string = "",
) => {
  const { messages } = appIntl();
  try {
    if (response.status === 200 || response.status === 201) {
      dispatch(fetchSuccess());
      if (successType) {
        dispatch({ type: successType, payload: response.data?.data });
      }
      if (response.data?.message) {
        dispatch(showMessage(response.data.message));
      }
    } else {
      dispatch(fetchError(String(messages["message.somethingWentWrong"])));
    }
  } catch (error) {
    console.log("error in reducer", error);
  }
};

export const handleErrorResponse = (
  dispatch: Dispatch<AppActions>,
  error: any,
  defaultMessage: string = "",
) => {
  const { messages } = appIntl();
  if (error?.response && error?.response?.data.status === 400) {
    dispatch(validationError(error.response.data.error));
  } else {
    dispatch(fetchError(String(messages["message.somethingWentWrong"])));
  }
};
