import { ICompanyDetailList } from "@crema/types/models/customer/company";

export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const INIT_ADD_COMPANY_DETAIL = "INIT_ADD_COMPANY_DETAIL";
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";
export const INIT_COMPANY_DETAILS_FILTER = "INIT_COMPANY_DETAILS_FILTER";
export const SET_COMPANY_DETAILS_FILTER = "SET_COMPANY_DETAILS_FILTER";
export const GET_COMPANY_DETAILS_FILTER = "GET_COMPANY_DETAILS_FILTER";

export type GetCompanyDetailsActions = {
  type: typeof GET_COMPANY_DETAILS;
  payload: ICompanyDetailList[];
};

export type InitAddCompanyDetailActions = {
  type: typeof INIT_ADD_COMPANY_DETAIL;
};

export type GetCompanyDetailActions = {
  type: typeof GET_COMPANY_DETAIL;
  payload: ICompanyDetailList;
};

export type InitCompanyDetailsFilter = {
  type: typeof INIT_COMPANY_DETAILS_FILTER;
};

export type SetCompanyDetailsFilter = {
  type: typeof SET_COMPANY_DETAILS_FILTER;
  payload: object;
};

export type GetCompanyDetailsFilterActions = {
  type: typeof GET_COMPANY_DETAILS_FILTER;
  payload: ICompanyDetailList[];
};

export type CompanyDetailActions =
  | GetCompanyDetailsActions
  | InitAddCompanyDetailActions
  | GetCompanyDetailActions
  | InitCompanyDetailsFilter
  | SetCompanyDetailsFilter;
