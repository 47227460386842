import { createAction, createReducer } from "@reduxjs/toolkit";
import {
  GET_CUSTOMER_PAYMENT_REPORT,
  EXPORT_CUSTOMER_PAYMENT_REPORT,
  COLUMNS_CUSTOMER_PAYMENT_REPORT,
  INIT_CUSTOMER_PAYMENT_REPORT_FILTER,
  SET_CUSTOMER_PAYMENT_REPORT_FILTER,
  EXPORTED_CUSTOMER_PAYMENT_REPORT,
} from "@crema/types/actions/dairy/Report/CustomerPayment";
import {
  IGetCustomerPaymentReport,
  ICustomerPaymentReportList,
  ICustomerPaymentReportFooter,
} from "@crema/types/models/dairy/Report/CustomerPayment";
import {
  IColumns,
  IExportFile,
  ValidationError,
} from "@crema/types/models/APIDataProps";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialCustomerPaymentReportState = {
  customerPayments: [] as ICustomerPaymentReportList[],
  customerPaymentFooter: {} as ICustomerPaymentReportFooter,
  pagination: null as PaginationType | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  customerPaymentsFilter: {} as any,
  toggleToReload: false as boolean,
  exportFile: null as IExportFile,
  columns: null as IColumns,
};

export const getCustomerPaymentReportAction =
  createAction<IGetCustomerPaymentReport>(GET_CUSTOMER_PAYMENT_REPORT);
export const exportCustomerPaymentReportAction = createAction<IExportFile>(
  EXPORT_CUSTOMER_PAYMENT_REPORT,
);
export const columnsCustomerPaymentReportAction = createAction<IColumns>(
  COLUMNS_CUSTOMER_PAYMENT_REPORT,
);
export const initCustomerPaymentReportFilterAction = createAction(
  INIT_CUSTOMER_PAYMENT_REPORT_FILTER,
);
export const setCustomerPaymentReportFilterAction = createAction<object>(
  SET_CUSTOMER_PAYMENT_REPORT_FILTER,
);
export const exportedCustomerPaymentReportAction = createAction(
  EXPORTED_CUSTOMER_PAYMENT_REPORT,
);

const customerPaymentReportReducer = createReducer(
  initialCustomerPaymentReportState,
  (builder) => {
    builder
      .addCase(getCustomerPaymentReportAction, (state, action) => {
        state.customerPayments = action.payload.reports;
        state.customerPaymentFooter = action.payload.pagination;
        state.pagination = {
          total: action.payload.pagination.totalCount,
          from: action.payload.pagination.pageNumber,
          perPage: Number(action.payload.pagination.pageSize),
        };
      })
      .addCase(initCustomerPaymentReportFilterAction, (state) => {
        state.customerPaymentsFilter = {};
        state.exportFile = null;
      })
      .addCase(setCustomerPaymentReportFilterAction, (state, action) => {
        state.customerPaymentsFilter = action.payload;
      })
      .addCase(exportCustomerPaymentReportAction, (state, action) => {
        state.exportFile = action.payload;
      })
      .addCase(columnsCustomerPaymentReportAction, (state, action) => {
        state.columns = action.payload;
      })
      .addCase(exportedCustomerPaymentReportAction, (state, action) => {
        state.exportFile = null;
      });
  },
);

export default customerPaymentReportReducer;
