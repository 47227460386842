import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIErrorResProps,
  APIResponse,
  IExportFile,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IMilkReportList,
  IMilkReportDetails,
  IGetMilkReport,
} from "@crema/types/models/dairy/Report/MilkReport/MilkReport";
import {
  ADD_MILK_REPORT,
  DELETE_MILK_REPORT,
  EDIT_MILK_REPORT,
  GET_MILK_REPORT,
  GET_MILK_REPORTS,
  INIT_ADD_MILK_REPORT,
  INIT_MILK_REPORTS_FILTER,
  SET_MILK_REPORTS_FILTER,
  SET_EDIT_MILK_REPORT_ID,
  EXPORT_MILK_REPORT,
  GET_MILK_REPORT_COLUMNS,
  EXPORTED_MILK_REPORT,
} from "@crema/types/actions/dairy/Report/MilkReport/MilkReport";
import { IMilkReportColumns } from "@crema/types/models/dairy/Report/CustomerMilk";

export const getMilkReports = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/milk-report", { params: filter })
      .then((response: APIResponse<IGetMilkReport>) => {
        handleApiResponse(dispatch, response, GET_MILK_REPORTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateMilkReport = (
  method: "post" | "put",
  data: IMilkReportDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/milk-report${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IMilkReportDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_MILK_REPORT : EDIT_MILK_REPORT,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteMilkReport = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`/milk-report${id}`, data)
      .then((response: APIResponse<IMilkReportList>) => {
        handleApiResponse(dispatch, response, DELETE_MILK_REPORT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getMilkReportById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/milk-report${id}`)
      .then((response: APIResponse<IMilkReportDetails>) => {
        handleApiResponse(dispatch, response, GET_MILK_REPORT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddMilkReport = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_MILK_REPORT });
  };
};

export const initMilkReportsFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_MILK_REPORTS_FILTER });
};

export const editMilkReport =
  (id: number) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_EDIT_MILK_REPORT_ID, payload: id });
  };

export const setMilkReportsFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_MILK_REPORTS_FILTER, payload: formData });
  };

export const exportMilkReport = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/milk-report/export", { params: filter })
      .then((response: APIResponse<IExportFile>) => {
        handleApiResponse(dispatch, response, EXPORT_MILK_REPORT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getMilkReportColumns = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/milk-report/columns")
      .then((response: APIResponse<IMilkReportColumns>) => {
        handleApiResponse(dispatch, response, GET_MILK_REPORT_COLUMNS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const exportedMilkReport = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: EXPORTED_MILK_REPORT });
  };
};
