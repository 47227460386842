import axios from "@crema/services/axios";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "@crema/utility/LocalStorage";
import type { AxiosResponse } from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const jwtAxios = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
jwtAxios.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err.response && err.response.data.msg === "Token is not valid") {
      console.log("Need to logout user");
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token?: string) => {
  if (token) {
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setLocalStorageItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common["Authorization"];
    removeLocalStorageItem("token");
  }
};

export const setUserProfile = (userProfile?: string) => {
  if (userProfile) {
    setLocalStorageItem("userProfile", JSON.stringify(userProfile));
  } else {
    removeLocalStorageItem("userProfile");
  }
};

export const getUserProfile = () => {
  return getLocalStorageItem("userProfile");
};

export const setConfig = (config) => {
  if (config) {
    setLocalStorageItem("config", config);
  } else {
    removeLocalStorageItem("config");
  }
};

const token = localStorage.getItem("token");
if (token) {
  jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
  localStorage.setItem("token", token);
} else {
  delete jwtAxios.defaults.headers.common["Authorization"];
  localStorage.removeItem("token");
}
export default jwtAxios;
