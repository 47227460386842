import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
  fetchSuccess,
  fetchError,
} from "toolkit/actions";
import { APIResponse, IExportFile } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";

import {
  ADD_CLUSTER_MILK,
  DELETE_CLUSTER_MILK,
  EDIT_CLUSTER_MILK,
  GET_CHARTS_FOR_CLUSTER_MILK,
  GET_CLUSTERS_FOR_CLUSTER_MILK,
  GET_CLUSTER_MILK,
  GET_CLUSTER_MILKS,
  INIT_ADD_CLUSTER_MILK,
  INIT_CLUSTER_MILKS_FILTER,
  SET_EDIT_CLUSTER_MILK_ID,
  SET_CLUSTER_MILKS_FILTER,
  SET_CLUSTER_MILK_RATE,
  EXPORT_CLUSTER_MILKS,
  COLUMNS_CLUSTER_MILKS,
  EXPORTED_CLUSTER_MILKS,
} from "@crema/types/actions/dairy/Manage-Cluster/ClusterMilk.actions";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";
import { IGetCluster } from "@crema/types/models/dairy/Manage-Cluster/Cluster";
import {
  IChartDetails,
  IChartList,
  IGetChart,
} from "@crema/types/models/dairy/Manage-Chart/Chart";
import { appIntl } from "@crema/helpers/Common";
import {
  IClusterMilkDetails,
  IClusterMilkList,
  IGetClusterMilk,
  rate,
} from "@crema/types/models/dairy/Manage-Cluster/ClusterMilk";

export const getClusterMilks = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cluster-milk", { params: filter })
      .then((response: APIResponse<IGetClusterMilk>) => {
        handleApiResponse(dispatch, response, GET_CLUSTER_MILKS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getClustersForClusterMilks = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cluster", { params: filter })
      .then((response: APIResponse<IGetCluster>) => {
        handleApiResponse(dispatch, response, GET_CLUSTERS_FOR_CLUSTER_MILK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getChartsForClusterMilks = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    const { messages } = appIntl();
    dispatch(fetchStart());
    jwtAxios
      .get("/chart", { params: filter })
      .then((response: APIResponse<IGetChart>) => {
        if (response.status === 200 || response.status === 201) {
          const chart = [];
          const promises = response.data.data.chart.map((cart, index) => {
            return jwtAxios
              .get(`/chart/${cart.id}`)
              .then((response: APIResponse<IChartDetails>) => {
                chart.push(response.data?.data);
              })
              .catch((error: APIErrorResProps) => {
                handleErrorResponse(dispatch, error);
              });
          });
          Promise.all(promises)
            .then(() => {
              dispatch({ type: GET_CHARTS_FOR_CLUSTER_MILK, payload: chart });
            })
            .catch((error) => {
              dispatch(
                fetchError(String(messages["message.somethingWentWrong"])),
              );
            });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateClusterMilk = (
  method: "post" | "put",
  data: object,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/cluster-milk${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IClusterMilkDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_CLUSTER_MILK : EDIT_CLUSTER_MILK,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteClusterMilk = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/cluster-milk/${id}`, data)
      .then((response: APIResponse<IClusterMilkList>) => {
        handleApiResponse(dispatch, response, DELETE_CLUSTER_MILK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getClusterMilkById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/cluster-milk/${id}`)
      .then((response: APIResponse<IClusterMilkDetails>) => {
        handleApiResponse(dispatch, response, GET_CLUSTER_MILK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddClusterMilk = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_CLUSTER_MILK });
  };
};

export const initClusterMilksFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_CLUSTER_MILKS_FILTER });
  };

export const setClusterMilksFilter =
  (formData: IClusterMilkDetails) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CLUSTER_MILKS_FILTER, payload: formData });
  };

export const setClusterMilkRate =
  (rate: rate) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CLUSTER_MILK_RATE, payload: rate });
  };

export const editClusterMilk =
  (id: number) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_EDIT_CLUSTER_MILK_ID, payload: id });
  };

export const exportClusterMilk = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cluster-milk-report/export", { params: filter })
      .then((response: APIResponse<IExportFile>) => {
        handleApiResponse(dispatch, response, EXPORT_CLUSTER_MILKS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const columnsClusterMilk = (filter?: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cluster-milk-report/columns", { params: filter })
      .then((response: APIResponse<IGetClusterMilk>) => {
        handleApiResponse(dispatch, response, COLUMNS_CLUSTER_MILKS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const exportedClusterMilks = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: EXPORTED_CLUSTER_MILKS });
  };
};
