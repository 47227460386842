import {
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  TOGGLE_APP_DRAWER,
} from "@crema/types/actions/Common.action";
import { createReducer } from "@reduxjs/toolkit";
import { CommonType } from "@crema/types/models/Common";
import {
  ClearValidationErrorAction,
  FetchErrorAction,
  ShowMsgAction,
  ValidationErrorAction,
  ValidationErrorDisplayedAction,
} from "./ActionTypes/Common";

const INIT_STATE: CommonType = {
  error: "",
  loading: false,
  isAppDrawerOpen: false,
  validationErrors: [],
  message: "",
  errorsDisplayed: false as boolean,
};

const commonReducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase(FETCH_START, (state, action) => {
      state.error = "";
      state.message = "";
      state.loading = true;
    })
    // .addCase(UPDATING_CONTENT, (state, action) => {
    //   state.error = '';
    //   state.message = '';
    //   state.updatingContent = true;
    // })
    .addCase(FETCH_SUCCESS, (state, action) => {
      state.error = "";
      state.message = "";
      state.loading = false;
    })
    .addCase(ShowMsgAction, (state, action) => {
      state.error = "";
      state.message = action.payload;
      state.loading = false;
    })
    .addCase(FetchErrorAction, (state, action) => {
      state.error = action.payload;
      state.message = "";
      state.loading = false;
    })
    .addCase(HIDE_MESSAGE, (state) => {
      state.error = "";
      state.message = "";
      state.loading = false;
    })
    .addCase(ValidationErrorAction, (state, action) => {
      state.error = action.payload.message;
      state.message = "";
      state.loading = false;
      state.validationErrors = action.payload.details;
    })
    .addCase(ClearValidationErrorAction, (state) => {
      state.validationErrors = [];
    })
    .addCase(ValidationErrorDisplayedAction, (state, action) => {
      state.errorsDisplayed = action.payload;
    })
    .addCase(TOGGLE_APP_DRAWER, (state, action) => {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    });
});

export default commonReducer;
