import React from "react";

const Fixed = React.lazy(() => import("./index"));

export const fixedChartComponentRouteConfigs = [
  {
    path: "/fixed-chart",
    element: <Fixed />,
  },
];
