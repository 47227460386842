import {
  ICustomerDetails,
  ICustomerList,
  ICustomerShort,
} from "@crema/types/models/dairy/Customer";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const INIT_ADD_CUSTOMER = "INIT_ADD_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const INIT_CUSTOMERS_FILTER = "INIT_CUSTOMERS_FILTER";
export const SET_CUSTOMERS_FILTER = "SET_CUSTOMERS_FILTER";
export const GET_CUSTOMERS_FILTER = "GET_CUSTOMERS_FILTER";

export type GetCustomersActions = {
  type: typeof GET_CUSTOMERS;
  payload: ICustomerList[];
};

export type InitAddCustomerActions = {
  type: typeof INIT_ADD_CUSTOMER;
};

export type AddCustomerActions = {
  type: typeof ADD_CUSTOMER;
  payload: ICustomerDetails;
};

export type EditCustomerActions = {
  type: typeof EDIT_CUSTOMER;
  payload: ICustomerDetails;
};

export type GetCustomerActions = {
  type: typeof GET_CUSTOMER;
  payload: ICustomerDetails;
};

export type GetCustomerListActions = {
  type: typeof GET_CUSTOMER_LIST;
  payload: ICustomerShort[];
};

export type DeleteCustomerActions = {
  type: typeof DELETE_CUSTOMER;
  payload: ICustomerList;
};

export type InitCustomersFilter = {
  type: typeof INIT_CUSTOMERS_FILTER;
};

export type SetCustomersFilter = {
  type: typeof SET_CUSTOMERS_FILTER;
  payload: object;
};

export type GetCustomersFilterActions = {
  type: typeof GET_CUSTOMERS_FILTER;
  payload: ICustomerList[];
};

export type CustomerActions =
  | GetCustomersActions
  | InitAddCustomerActions
  | AddCustomerActions
  | EditCustomerActions
  | DeleteCustomerActions
  | GetCustomerActions
  | GetCustomerListActions
  | InitCustomersFilter
  | SetCustomersFilter;
