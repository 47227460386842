import {
  GET_CLUSTER_MILK_REPORTS,
  INIT_CLUSTER_MILK_REPORTS_FILTER,
  SET_CLUSTER_MILK_REPORTS_FILTER,
  EXPORT_CLUSTER_MILK_REPORT,
  GET_CLUSTER_MILK_REPORT_COLUMNS,
  EXPORTED_CLUSTER_MILK_REPORT,
} from "@crema/types/actions/dairy/Report/ClusterMilkReport";
import { IExportFile, ValidationError } from "@crema/types/models/APIDataProps";
import {
  IClusterMilkReportColumns,
  IClusterMilkReportFilter,
  IClusterMilkReportList,
  IGetClusterMilkReport,
  IClusterMilkReportPaginationType,
} from "@crema/types/models/dairy/Report/ClusterMilkReport";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialClusterMilkReportState = {
  clusterMilkReports: [] as IClusterMilkReportList[],
  pagination: null as IClusterMilkReportPaginationType,
  validationError: null as ValidationError | null,
  success: false as boolean,
  clusterMilkReportsFilter: {
    pageNumber: 1,
    pageSize: 10,
  } as IClusterMilkReportFilter,
  toggleToReload: false as boolean,
  exportFile: null as IExportFile,
  columns: null as IClusterMilkReportColumns,
};

export const getClusterMilkReportsAction = createAction<IGetClusterMilkReport>(
  GET_CLUSTER_MILK_REPORTS,
);
export const initClusterMilkReportsFilter = createAction(
  INIT_CLUSTER_MILK_REPORTS_FILTER,
);
export const setClusterMilkReportsFilter = createAction<object>(
  SET_CLUSTER_MILK_REPORTS_FILTER,
);
export const getClusterMilkReportColumns =
  createAction<IClusterMilkReportColumns>(GET_CLUSTER_MILK_REPORT_COLUMNS);
export const exportClusterMilkReport = createAction<IExportFile>(
  EXPORT_CLUSTER_MILK_REPORT,
);
export const exportedClusterMilkReportAction = createAction(
  EXPORTED_CLUSTER_MILK_REPORT,
);

const clusterMilkReportReducer = createReducer(
  initialClusterMilkReportState,
  (builder) => {
    builder
      .addCase(getClusterMilkReportsAction, (state, action) => {
        state.clusterMilkReports = action.payload.reports;
        state.pagination = {
          total: Number(action.payload.pagination.totalCount),
          from: Number(action.payload.pagination.pageNumber),
          perPage: Number(action.payload.pagination.pageSize),
          ...action.payload.pagination,
        };
      })
      .addCase(initClusterMilkReportsFilter, (state) => {
        state.clusterMilkReportsFilter = {
          pageNumber: 1,
          pageSize: 10,
        };
      })
      .addCase(setClusterMilkReportsFilter, (state, action) => {
        state.clusterMilkReportsFilter = action.payload;
      })
      .addCase(getClusterMilkReportColumns, (state, action) => {
        state.columns = action.payload;
      })
      .addCase(exportClusterMilkReport, (state, action) => {
        state.exportFile = action.payload;
      })
      .addCase(exportedClusterMilkReportAction, (state) => {
        state.exportFile = null;
      });
  },
);

export default clusterMilkReportReducer;
