import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIErrorResProps,
  APIResponse,
  IExportFile,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IGetClusterMilkReport,
  IClusterMilkReportColumns,
} from "@crema/types/models/dairy/Report/ClusterMilkReport";
import {
  GET_CLUSTER_MILK_REPORTS,
  INIT_CLUSTER_MILK_REPORTS_FILTER,
  SET_CLUSTER_MILK_REPORTS_FILTER,
  EXPORT_CLUSTER_MILK_REPORT,
  GET_CLUSTER_MILK_REPORT_COLUMNS,
  EXPORTED_CLUSTER_MILK_REPORT,
} from "@crema/types/actions/dairy/Report/ClusterMilkReport";

export const getClusterMilkReports = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cluster-milk-report", { params: filter })
      .then((response: APIResponse<IGetClusterMilkReport>) => {
        handleApiResponse(dispatch, response, GET_CLUSTER_MILK_REPORTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const setClusterMilkReportsFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CLUSTER_MILK_REPORTS_FILTER, payload: formData });
  };

export const initClusterMilkReportsFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_CLUSTER_MILK_REPORTS_FILTER });
  };

export const exportClusterMilkReport = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cluster-milk-report/export", { params: filter })
      .then((response: APIResponse<IExportFile>) => {
        handleApiResponse(dispatch, response, EXPORT_CLUSTER_MILK_REPORT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getClusterMilkReportColumns = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cluster-milk-report/columns")
      .then((response: APIResponse<IClusterMilkReportColumns>) => {
        handleApiResponse(dispatch, response, GET_CLUSTER_MILK_REPORT_COLUMNS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const exportedClusterMilkReport = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: EXPORTED_CLUSTER_MILK_REPORT });
  };
};
