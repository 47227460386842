import { useAuthUser } from "@crema/hooks/AuthHooks";

export const getUserFromJwtAuth = (user: any) => {
  if (user) {
    return {
      id: user.id,
      uid: user.id,
      displayName: user.firstName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobile: user.mobile,
      photoURL: user?.avatar,
      defaultLanguage: user?.defaultLanguage,
      planExpiryDate: user?.planExpiryDate,
      lastLoginDate: user?.lastLoginDate,
      roles: user?.roles,
      employee: user?.employee,
      customer: user?.customer,
      defaultRole: user?.defaultRole,
      permissions: user?.permissions,
      module: user.module,
      company: {
        id: user?.company?.id,
        name: user?.company?.name,
        code: user?.company?.code,
      },
      currentRole: {
        id: user?.currentRole?.id,
        name: user?.currentRole?.name,
        permissions: user?.currentRole?.permissions,
      },
    };
  }
  return user;
};

export const hasPermission = (permission) => {
  const { user } = useAuthUser();
  return user?.currentRole.permissions?.includes(permission);
};
