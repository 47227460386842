import React from "react";

const List = React.lazy(() => import("./list"));
const View = React.lazy(() => import("./view"));
const Edit = React.lazy(() => import("./edit"));

export const clusterMilkComponentRouteConfigs = [
  {
    path: "/cluster-milk",
    element: <List />,
  },
  {
    path: "/cluster-milk/view/:id",
    element: <View />,
  },
  {
    path: "/cluster-milk/edit/:id",
    element: <Edit />,
  },
];
