import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IClusterList,
  IClusterDetails,
  IClusterShort,
  IGetCluster,
} from "@crema/types/models/dairy/Manage-Cluster/Cluster";
import {
  ADD_CLUSTER,
  DELETE_CLUSTER,
  EDIT_CLUSTER,
  GET_CLUSTER,
  GET_CLUSTERS,
  GET_CLUSTER_LIST,
  INIT_ADD_CLUSTER,
  INIT_CLUSTERS_FILTER,
  SET_CLUSTERS_FILTER,
} from "@crema/types/actions/dairy/Manage-Cluster/Cluster.actions";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getClusters = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cluster", { params: filter })
      .then((response: APIResponse<IGetCluster>) => {
        handleApiResponse(dispatch, response, GET_CLUSTERS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateCluster = (
  method: "post" | "put",
  data: IClusterDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/cluster${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IClusterDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_CLUSTER : EDIT_CLUSTER,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteCluster = (type: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/cluster/${type}`, data)
      .then((response: APIResponse<IClusterList>) => {
        handleApiResponse(dispatch, response, DELETE_CLUSTER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getClusterById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/cluster/${id}`)
      .then((response: APIResponse<IClusterDetails>) => {
        handleApiResponse(dispatch, response, GET_CLUSTER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getClusterList = (search: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/cluster/list`, { params: { search } })
      .then((response: APIResponse<Array<IClusterShort>>) => {
        handleApiResponse(dispatch, response, GET_CLUSTER_LIST);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddCluster = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_CLUSTER });
  };
};

export const initClustersFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_CLUSTERS_FILTER });
};

export const setClustersFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CLUSTERS_FILTER, payload: formData });
  };
