import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "../../Common";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import { IReceiptPaymentList } from "@crema/types/models/dairy/Receipt-Payment/receiptPayment";
import {
  ADD_RECEIPT_PAYMENT,
  DELETE_RECEIPT_PAYMENT,
  EDIT_RECEIPT_PAYMENT,
  GET_RECEIPT_PAYMENT,
  GET_RECEIPT_PAYMENTS,
  INIT_ADD_RECEIPT_PAYMENT,
  GET_RECEIPT_PAYMENT_LIST,
  SET_RECEIPT_PAYMENT_FILTER,
  INIT_RECEIPT_PAYMENT_FILTER,
} from "@crema/types/actions/dairy/Receipt-Payment/receiptPayment.Action";

export const getReceiptPayment = (formData: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/receipt-payment", { params: formData })
      .then((response: APIResponse<IReceiptPaymentList[]>) => {
        handleApiResponse(dispatch, response, GET_RECEIPT_PAYMENT);
      })
      .catch((error: any) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const getReceiptPayments = (formData: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/receipt-payment", { params: formData })
      .then((response: APIResponse<IReceiptPaymentList[]>) => {
        handleApiResponse(dispatch, response, GET_RECEIPT_PAYMENTS);
      })
      .catch((error: any) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const addOrUpdateReceiptPayment = (
  method: "post" | "put",
  data: any,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/receipt-payment${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IReceiptPaymentList>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_RECEIPT_PAYMENT : EDIT_RECEIPT_PAYMENT,
        );
      })
      .catch((error: any) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteReceiptPayment = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`/receipt-payment/${id}`, data)
      .then((response: APIResponse<IReceiptPaymentList>) => {
        handleApiResponse(dispatch, response, DELETE_RECEIPT_PAYMENT);
      })
      .catch((error: any) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getReceiptPaymentById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/receipt-payment/${id}`)
      .then((response: APIResponse<IReceiptPaymentList>) => {
        handleApiResponse(dispatch, response, GET_RECEIPT_PAYMENT);
      })
      .catch((error: any) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getReceiptPaymentList = (
  branchId: string | number,
  search: string,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/receipt-payment/list`, { params: { branchId, search } })
      .then((response: APIResponse<Array<IReceiptPaymentList>>) => {
        handleApiResponse(dispatch, response, GET_RECEIPT_PAYMENT_LIST);
      })
      .catch((error: any) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddReceiptPayment = () => (dispatch) => {
  dispatch({ type: INIT_ADD_RECEIPT_PAYMENT });
};

export const setReceiptPaymentFilter = (formData) => (dispatch) => {
  dispatch({ type: SET_RECEIPT_PAYMENT_FILTER, payload: formData });
};

export const InitReceiptPaymentFilter = () => (dispatch) => {
  dispatch({ type: INIT_RECEIPT_PAYMENT_FILTER });
};
