import {
  GET_CLUSTER_MILKS,
  INIT_ADD_CLUSTER_MILK,
  ADD_CLUSTER_MILK,
  EDIT_CLUSTER_MILK,
  DELETE_CLUSTER_MILK,
  GET_CLUSTER_MILK,
  INIT_CLUSTER_MILKS_FILTER,
  SET_CLUSTER_MILKS_FILTER,
  GET_CLUSTERS_FOR_CLUSTER_MILK,
  GET_CHARTS_FOR_CLUSTER_MILK,
  SET_CLUSTER_MILK_RATE,
  EXPORT_CLUSTER_MILKS,
  COLUMNS_CLUSTER_MILKS,
  EXPORTED_CLUSTER_MILKS,
} from "@crema/types/actions/dairy/Manage-Cluster/ClusterMilk.actions";
import {
  IColumns,
  IExportFile,
  ValidationError,
} from "@crema/types/models/APIDataProps";
import { IChartList } from "@crema/types/models/dairy/Manage-Chart/Chart";
import {
  IClusterList,
  IGetCluster,
} from "@crema/types/models/dairy/Manage-Cluster/Cluster";
import {
  IClusterMilkDetails,
  IClusterMilkList,
  IGetClusterMilk,
  clusterMilksFilter,
  rate,
  IClusterMilkFooter,
  IClusterMilkFilter,
} from "@crema/types/models/dairy/Manage-Cluster/ClusterMilk";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialClusterMilkState = {
  clusterMilks: [] as IClusterMilkList[],
  clusterMilkFooter: {} as IClusterMilkFooter,
  clusters: [] as IClusterList[],
  charts: [] as IChartList[],
  pagination: null as PaginationType | null,
  clusterMilk: null as IClusterMilkDetails | null,
  details: null as IClusterMilkDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  clusterMilksFilter: {
    pageNumber: 1,
    pageSize: 10,
  } as IClusterMilkFilter,
  rate: null as rate,
  toggleToReload: 0 as number,
  exportFile: null as IExportFile,
  columns: null as IColumns,
};

export const getClusterMilksAction =
  createAction<IGetClusterMilk>(GET_CLUSTER_MILKS);
export const getClustersForClusterMilksAction = createAction<IGetCluster>(
  GET_CLUSTERS_FOR_CLUSTER_MILK,
);
export const getChartForClusterMilksAction = createAction<IChartList[]>(
  GET_CHARTS_FOR_CLUSTER_MILK,
);
export const initAddClusterMilkAction = createAction(INIT_ADD_CLUSTER_MILK);
export const addClusterMilkAction =
  createAction<IClusterMilkDetails>(ADD_CLUSTER_MILK);
export const editClusterMilkAction = createAction(EDIT_CLUSTER_MILK);
export const deleteClusterMilkAction =
  createAction<IClusterMilkList>(DELETE_CLUSTER_MILK);
export const getClusterMilkAction =
  createAction<IClusterMilkDetails>(GET_CLUSTER_MILK);
export const initClusterMilksFilter = createAction<clusterMilksFilter>(
  INIT_CLUSTER_MILKS_FILTER,
);
export const setClusterMilksFilter = createAction<clusterMilksFilter>(
  SET_CLUSTER_MILKS_FILTER,
);
export const setClusterMilkRate = createAction<rate>(SET_CLUSTER_MILK_RATE);

export const exportClusterMilks =
  createAction<IExportFile>(EXPORT_CLUSTER_MILKS);
export const columnsClusterMilks = createAction<IColumns>(
  COLUMNS_CLUSTER_MILKS,
);
export const exportedClusterMilksAction = createAction(EXPORTED_CLUSTER_MILKS);
const clusterMilkReducer = createReducer(initialClusterMilkState, (builder) => {
  builder
    .addCase(getClusterMilksAction, (state, action) => {
      state.clusterMilks = action.payload.clusterMilk;
      state.clusterMilkFooter = action.payload.pagination;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
      };
    })
    .addCase(getClustersForClusterMilksAction, (state, action) => {
      state.clusters = action.payload.cluster;
    })
    .addCase(getChartForClusterMilksAction, (state, action) => {
      state.charts = action.payload;
    })
    .addCase(initAddClusterMilkAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addClusterMilkAction, (state, action) => {
      state.success = true;
      state.toggleToReload = ++state.toggleToReload;
      state.details = action.payload;
    })
    .addCase(editClusterMilkAction, (state, action) => {
      state.clusterMilk = action.payload as IClusterMilkDetails;
      state.clusterMilks = state.clusterMilks.map((milk) => {
        if (milk.id === (action.payload as IClusterMilkDetails).id) {
          return action.payload;
        }
        return milk;
      });
      state.success = true;
      state.toggleToReload = ++state.toggleToReload;
    })
    .addCase(deleteClusterMilkAction, (state, action) => {
      state.clusterMilks = state.clusterMilks.filter(
        (milk) => milk.id !== action.payload.id,
      );
    })
    .addCase(getClusterMilkAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(initClusterMilksFilter, (state) => {
      state.clusterMilksFilter = {
        pageNumber: 1,
        pageSize: 10,
      };
    })
    .addCase(setClusterMilkRate, (state, action) => {
      state.rate = action.payload;
    })
    .addCase(setClusterMilksFilter, (state, action) => {
      state.clusterMilksFilter = action.payload;
    })
    .addCase(exportClusterMilks, (state, action) => {
      state.exportFile = action.payload;
    })
    .addCase(columnsClusterMilks, (state, action) => {
      state.columns = action.payload;
    })
    .addCase(exportedClusterMilksAction, (state) => {
      state.exportFile = null;
    });
});

export default clusterMilkReducer;
