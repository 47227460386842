import {
  GET_TAGS,
  INIT_ADD_TAG,
  ADD_TAG,
  EDIT_TAG,
  DELETE_TAG,
  GET_TAG,
  GET_TAG_LIST,
  INIT_TAGS_FILTER,
  SET_TAGS_FILTER,
  SET_EDIT_TAG_ID,
} from "@crema/types/actions/superAdmin/Tag.action";

import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  ITagDetails,
  ITagList,
  ITagShort,
  IGetTag,
} from "@crema/types/models/superAdmin/tag";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialTagState = {
  tags: [] as ITagList[],
  pagination: null as PaginationType | null,
  tag: null as ITagDetails | null,
  details: null as ITagDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  tagList: [] as ITagShort[],
  tagsFilter: {} as object,
  toggleReload: false as boolean,
  tagId: 0 as number,
};

export const getTagsAction = createAction<IGetTag>(GET_TAGS);
export const initAddTagAction = createAction(INIT_ADD_TAG);
export const addTagAction = createAction<ITagDetails>(ADD_TAG);
export const editTagAction = createAction(EDIT_TAG);
export const deleteTagAction = createAction<ITagList>(DELETE_TAG);
export const getTagAction = createAction<ITagDetails>(GET_TAG);
export const getTagListByBranchIdAction =
  createAction<ITagShort[]>(GET_TAG_LIST);
export const initTagsFilter = createAction(INIT_TAGS_FILTER);
export const setTagsFilter = createAction<object>(SET_TAGS_FILTER);
export const setEditTagId = createAction<number>(SET_EDIT_TAG_ID);

const tagReducer = createReducer(initialTagState, (builder) => {
  builder
    .addCase(getTagsAction, (state, action) => {
      state.tags = action.payload.tag;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
      };
    })
    .addCase(initAddTagAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addTagAction, (state, action) => {
      state.toggleReload = !state.toggleReload;
    })
    .addCase(editTagAction, (state, action) => {
      state.toggleReload = !state.toggleReload;
      state.tagId = 0;
    })
    .addCase(deleteTagAction, (state, action) => {
      state.tags = state.tags.filter((tag) => tag.id !== action.payload.id);
    })
    .addCase(getTagAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getTagListByBranchIdAction, (state, action) => {
      state.tagList = action.payload;
    })
    .addCase(initTagsFilter, (state) => {
      state.tagsFilter = {};
    })
    .addCase(setTagsFilter, (state, action) => {
      state.tagsFilter = action.payload;
    })
    .addCase(setEditTagId, (state, action) => {
      state.tagId = action.payload;
    });
});

export default tagReducer;
