import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IUserList,
  IUserDetails,
  IUserShort,
  IGetUser,
} from "@crema/types/models/superAdmin/user/user";
import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  GET_USER,
  GET_USERS,
  INIT_ADD_USER,
  INIT_USERS_FILTER,
  SET_USERS_FILTER,
} from "@crema/types/actions/superAdmin/User/User.action";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getUsers = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/admin-api/user", { params: filter })
      .then((response: APIResponse<IGetUser>) => {
        handleApiResponse(dispatch, response, GET_USERS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateUser = (
  method: "post" | "put",
  data: IUserDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/admin-api/user${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IUserDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_USER : EDIT_USER,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteUser = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`/admin-api/user/${id}`, data)
      .then((response: APIResponse<IUserList>) => {
        handleApiResponse(dispatch, response, DELETE_USER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getUserById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/user/${id}`)
      .then((response: APIResponse<IUserDetails>) => {
        handleApiResponse(dispatch, response, GET_USER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const getProxyById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/proxyUser/${id}`)
      .then((response: APIResponse<IUserDetails>) => {
        handleApiResponse(dispatch, response, GET_USER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const initAddUser = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_USER });
  };
};

export const initUsersFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_USERS_FILTER });
};

export const setUsersFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_USERS_FILTER, payload: formData });
  };
