import React from "react";
import { RoutePermission } from "@crema/constants/AppConst";
import { BiAlignLeft } from "react-icons/bi";
import IntlMessages from "@crema/helpers/IntlMessages";
import { FaTags, FaUserFriends } from "react-icons/fa";

const superAdminRoutesConfig = [
  {
    id: "super-dashboard",
    title: <IntlMessages id="sidebar.app.dashboard" />,
    messageId: "sidebar.app.dashboard",
    type: "item",
    icon: <BiAlignLeft />,
    path: "/super-admin/dashboard",
    permission: RoutePermission.admin.user.list,
  },
  {
    id: "user",
    title: <IntlMessages id="common.users" />,
    messageId: "common.users",
    type: "items",
    icon: <FaUserFriends />,
    path: "/super-admin/users",
    permission: RoutePermission.admin.user.list,
  },
  {
    id: "dairyCustomer",
    title: <IntlMessages id="common.dairyCustomer" />,
    messageId: "common.dairyCustomer",
    type: "items",
    icon: <FaUserFriends />,
    path: "/super-admin/dairy-customer",
    permission: RoutePermission.admin.dairy.list,
  },
  {
    id: "tag",
    title: <IntlMessages id="tags" />,
    messageId: "tags",
    type: "items",
    icon: <FaTags />,
    path: "/tags",
    permission: RoutePermission.admin.tag.list,
  },
];
export default superAdminRoutesConfig;
