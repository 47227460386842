import {
  GET_DAILY_MILK_COLLECTION_GRAPH_VIEW,
  GET_MONTHLY_MILK_COLLECTION_GRAPH_VIEW,
  GET_MONTHLY_TOP_TEN_CUSTOMER_GRAPH_VIEW,
  GET_TOTAL_CUSTOMER_GRAPH_VIEW,
  GET_YEARLY_TOP_TEN_CUSTOMER_GRAPH_VIEW,
} from "@crema/types/actions/dairy/Dashboard";
import {
  IDailyMilkCollectionGraphView,
  IMonthlyMilkCollectionGraphView,
  IMonthlyTopTenCustomerGraphView,
  ITotalCustomerGraphView,
  IYearlyTopTenCustomerGraphView,
} from "@crema/types/models/dairy/Dashboard";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialDashboardState = {
  dailyMilkCollectionGraphView: [] as IDailyMilkCollectionGraphView[],
  monthlyMilkCollectionGraphView: [] as IMonthlyMilkCollectionGraphView[],
  yearlyTopTenCustomerGraphView: [] as IYearlyTopTenCustomerGraphView[],
  monthlyTopTenCustomerGraphView: [] as IMonthlyTopTenCustomerGraphView[],
  totalCustomerGraphView: [] as ITotalCustomerGraphView[],
};

export const getDailyMilkCollectionGraphViewAction = createAction<
  IDailyMilkCollectionGraphView[]
>(GET_DAILY_MILK_COLLECTION_GRAPH_VIEW);

export const getMonthlyMilkCollectionGraphViewAction = createAction<
  IMonthlyMilkCollectionGraphView[]
>(GET_MONTHLY_MILK_COLLECTION_GRAPH_VIEW);

export const getYearlyTopTenCustomerGraphViewAction = createAction<
  IYearlyTopTenCustomerGraphView[]
>(GET_YEARLY_TOP_TEN_CUSTOMER_GRAPH_VIEW);

export const getMonthlyTopTenCustomerGraphViewAction = createAction<
  IMonthlyTopTenCustomerGraphView[]
>(GET_MONTHLY_TOP_TEN_CUSTOMER_GRAPH_VIEW);

export const getTotalCustomerGraphViewAction = createAction<
  ITotalCustomerGraphView[]
>(GET_TOTAL_CUSTOMER_GRAPH_VIEW);

const dashboardReducer = createReducer(initialDashboardState, (builder) => {
  builder
    .addCase(getDailyMilkCollectionGraphViewAction, (state, action) => {
      state.dailyMilkCollectionGraphView = action.payload;
    })
    .addCase(getMonthlyMilkCollectionGraphViewAction, (state, action) => {
      state.monthlyMilkCollectionGraphView = action.payload;
    })
    .addCase(getYearlyTopTenCustomerGraphViewAction, (state, action) => {
      state.yearlyTopTenCustomerGraphView = action.payload;
    })
    .addCase(getMonthlyTopTenCustomerGraphViewAction, (state, action) => {
      state.monthlyTopTenCustomerGraphView = action.payload;
    })
    .addCase(getTotalCustomerGraphViewAction, (state, action) => {
      state.totalCustomerGraphView = action.payload;
    });
});

export default dashboardReducer;
