import React from "react";

const Add = React.lazy(() => import("./add"));
const List = React.lazy(() => import("./list"));
const Edit = React.lazy(() => import("./edit"));

export const customerProductsComponentRouteConfigs = [
  {
    path: "/manage-customers/products/add",
    element: <Add />,
  },
  {
    path: "/manage-customers/products",
    element: <List />,
  },
  {
    path: "/manage-customers/products/edit/:id",
    element: <Edit />,
  },
];
