import { ApiSuccessResponse } from "@crema/types/models/APIDataProps";
import {
  IChartDetails,
  IChartList,
  IChartShort,
} from "@crema/types/models/dairy/Manage-Chart/Chart";
export const GET_CHARTS = "GET_CHARTS";
export const INIT_ADD_CHART = "INIT_ADD_CHART";
export const ADD_CHART = "ADD_CHART";
export const EDIT_CHART = "EDIT_CHART";
export const DELETE_CHART = "DELETE_CHART";
export const DELETE_CHART_RATE = "DELETE_CHART_RATE";
export const GET_CHART = "GET_CHART";
export const GET_CHART_LIST = "GET_CHART_LIST";
export const INIT_CHARTS_FILTER = "INIT_CHARTS_FILTER";
export const SET_CHARTS_FILTER = "SET_CHARTS_FILTER";
export const GET_CHARTS_FILTER = "GET_CHARTS_FILTER";
export const EDIT_CHART_RATE_DETAILS = "EDIT_CHART_RATE_DETAILS";
export const UPLOAD_CHART_RATE_DETAILS = "UPLOAD_CHART_RATE_DETAILS";

export type GetChartsActions = {
  type: typeof GET_CHARTS;
  payload: IChartList[];
};

export type InitAddChartActions = {
  type: typeof INIT_ADD_CHART;
};

export type AddChartActions = {
  type: typeof ADD_CHART;
  payload: IChartDetails;
};

export type EditChartActions = {
  type: typeof EDIT_CHART;
  payload: IChartDetails;
};

export type EditChartRateDetailsActions = {
  type: typeof EDIT_CHART_RATE_DETAILS;
  payload: ApiSuccessResponse;
};

export type UploadChartRateDetailsActions = {
  type: typeof UPLOAD_CHART_RATE_DETAILS;
  payload: ApiSuccessResponse;
};

export type GetChartActions = {
  type: typeof GET_CHART;
  payload: IChartDetails;
};

export type GetChartListActions = {
  type: typeof GET_CHART_LIST;
  payload: IChartShort[];
};

export type DeleteChartActions = {
  type: typeof DELETE_CHART;
  payload: IChartList;
};
export type DeleteChartRateActions = {
  type: typeof DELETE_CHART_RATE;
  payload: IChartList;
};

export type InitChartsFilter = {
  type: typeof INIT_CHARTS_FILTER;
};

export type SetChartsFilter = {
  type: typeof SET_CHARTS_FILTER;
  payload: object;
};

export type GetChartsFilterActions = {
  type: typeof GET_CHARTS_FILTER;
  payload: IChartList[];
};

export type ChartActions =
  | GetChartsActions
  | InitAddChartActions
  | AddChartActions
  | EditChartActions
  | DeleteChartActions
  | DeleteChartRateActions
  | GetChartActions
  | GetChartListActions
  | InitChartsFilter
  | SetChartsFilter;
