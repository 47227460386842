import React from "react";

const List = React.lazy(() => import("./list"));
const Add = React.lazy(() => import("./add"));
const Edit = React.lazy(() => import("./edit"));
const View = React.lazy(() => import("./view"));

export const chartComponentRouteConfigs = [
  {
    path: "/charts",
    element: <List />,
  },
  {
    path: "/charts/add",
    element: <Add />,
  },
  {
    path: "/charts/edit/:id",
    element: <Edit />,
  },
  {
    path: "/charts/view/:id",
    element: <View />,
  },
];
