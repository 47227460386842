import React from "react";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from "@crema/constants/ActionTypes";

import { useAppDispatch } from "toolkit/hooks";
import JWTAuthAuthProvider from "@crema/services/auth/jwt-auth/JWTAuthProvider";

type AppAuthProviderProps = {
  children: React.ReactNode;
};

const AppAuthProvider = ({ children }: AppAuthProviderProps) => {
  const dispatch = useAppDispatch();

  const fetchStart = () => {
    dispatch({ type: FETCH_START });
  };
  const fetchError = (message: string) => {
    dispatch({ type: FETCH_ERROR, payload: message });
  };
  const fetchSuccess = () => {
    dispatch({ type: FETCH_SUCCESS });
  };

  return <JWTAuthAuthProvider>{children}</JWTAuthAuthProvider>;
};

export default AppAuthProvider;
