import {
  IPaymentsDetails,
  IPaymentsList,
  IPaymentsShort,
} from "@crema/types/models/dairy/Manage-Customer/Payments";

export const GET_PAYMENTS = "GET_PAYMENTS";
export const INIT_ADD_PAYMENT = "INIT_ADD_PAYMENT";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const GET_PAYMENT = "GET_PAYMENT";
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const INIT_PAYMENTS_FILTER = "INIT_PAYMENTS_FILTER";
export const SET_PAYMENTS_FILTER = "SET_PAYMENTS_FILTER";
export const GET_PAYMENTS_FILTER = "GET_PAYMENTS_FILTER";
export const SET_EDIT_PAYMENT_ID = "SET_EDIT_PAYMENT_ID";

export type GetPaymentsActions = {
  type: typeof GET_PAYMENTS;
  payload: IPaymentsList[];
};

export type InitAddPaymentActions = {
  type: typeof INIT_ADD_PAYMENT;
};

export type AddPaymentActions = {
  type: typeof ADD_PAYMENT;
  payload: IPaymentsDetails;
};

export type EditPaymentActions = {
  type: typeof EDIT_PAYMENT;
  payload: IPaymentsDetails;
};

export type GetPaymentActions = {
  type: typeof GET_PAYMENT;
  payload: IPaymentsDetails;
};

export type GetPaymentListActions = {
  type: typeof GET_PAYMENT_LIST;
  payload: IPaymentsShort[];
};

export type DeletePaymentActions = {
  type: typeof DELETE_PAYMENT;
  payload: IPaymentsList;
};

export type InitPaymentsFilter = {
  type: typeof INIT_PAYMENTS_FILTER;
};

export type SetPaymentsFilter = {
  type: typeof SET_PAYMENTS_FILTER;
  payload: object;
};

export type GetPaymentsFilterActions = {
  type: typeof GET_PAYMENTS_FILTER;
  payload: IPaymentsList[];
};
export type SetPaymentIdForEditActions = {
  type: typeof SET_EDIT_PAYMENT_ID;
  payload: number;
};

export type PaymentsActions =
  | GetPaymentsActions
  | InitAddPaymentActions
  | AddPaymentActions
  | EditPaymentActions
  | DeletePaymentActions
  | GetPaymentActions
  | GetPaymentListActions
  | InitPaymentsFilter
  | SetPaymentsFilter
  | SetPaymentIdForEditActions;
