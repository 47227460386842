import React from "react";

const List = React.lazy(() => import("./list"));
const Add = React.lazy(() => import("./add"));
const Edit = React.lazy(() => import("./edit"));

export const productComponentRouteConfigs = [
  {
    path: "/manage-product/product",
    element: <List />,
  },
  {
    path: "/manage-product/product/add",
    element: <Add />,
  },
  {
    path: "/manage-product/product/edit/:id",
    element: <Edit />,
  },
];
