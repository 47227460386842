import {
  GET_COMPANIES,
  INIT_ADD_COMPANY,
  ADD_COMPANY,
  EDIT_COMPANY,
  DELETE_COMPANY,
  GET_COMPANY,
  INIT_COMPANIES_FILTER,
  SET_COMPANIES_FILTER,
} from "@crema/types/actions/superAdmin/Company/Company.action";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  ICompanyDetails,
  ICompanyList,
  IGetCompany,
  ICompaniesFilter,
} from "@crema/types/models/superAdmin/company/company";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialCompanyState = {
  companies: [] as ICompanyList[],
  pagination: null as PaginationType | null,
  company: null as ICompanyDetails | null,
  details: null as ICompanyDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  companiesFilter: {
    pageNumber: 1,
    pageSize: 10,
  } as ICompaniesFilter,
  toggleToReload: false as boolean,
};

export const getCompaniesAction = createAction<IGetCompany>(GET_COMPANIES);
export const initAddCompanyAction = createAction(INIT_ADD_COMPANY);
export const addCompanyAction = createAction<ICompanyDetails>(ADD_COMPANY);
export const editCompanyAction = createAction(EDIT_COMPANY);
export const deleteCompanyAction = createAction<ICompanyList>(DELETE_COMPANY);
export const getCompanyAction = createAction<ICompanyDetails>(GET_COMPANY);
export const initCompaniesFilter = createAction(INIT_COMPANIES_FILTER);
export const setCompaniesFilter = createAction<object>(SET_COMPANIES_FILTER);

const companyReducer = createReducer(initialCompanyState, (builder) => {
  builder
    .addCase(getCompaniesAction, (state, action) => {
      state.companies = action.payload.companies;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
      };
    })
    .addCase(initAddCompanyAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addCompanyAction, (state, action) => {
      state.company = action.payload;
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(editCompanyAction, (state, action) => {
      state.company = action.payload;
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(deleteCompanyAction, (state, action) => {
      state.companies = state.companies.filter(
        (company) => company.companyId !== action.payload.companyId,
      );
    })
    .addCase(getCompanyAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(initCompaniesFilter, (state) => {
      state.companiesFilter = {
        pageNumber: 1,
        pageSize: 10,
      };
    })
    .addCase(setCompaniesFilter, (state, action) => {
      state.companiesFilter = action.payload;
    });
});

export default companyReducer;
