import React from "react";

const List = React.lazy(() => import("./list"));

export const CustomerMilkComponentRouteConfigs = [
  {
    path: "/manage-report/customerMilk",
    element: <List />,
  },
];
