import {
  IBill,
  IBillDetails,
  IBillPagination,
} from "@crema/types/models/dairy/Manage-Customer/Bill";

export const GET_BILLS = "GET_BILLS";
export const INIT_ADD_BILL = "INIT_ADD_BILL";
export const ADD_BILL = "ADD_BILL";
export const EDIT_BILL = "EDIT_BILL";
export const DELETE_BILL = "DELETE_BILL";
export const GET_BILL = "GET_BILL";
export const GET_BILL_LIST = "GET_BILL_LIST";
export const INIT_BILLS_FILTER = "INIT_BILLS_FILTER";
export const SET_BILLS_FILTER = "SET_BILLS_FILTER";
export const GET_BILLS_FILTER = "GET_BILLS_FILTER";
export const SET_EDIT_BILL_ID = "SET_EDIT_BILL_ID";

export type GetBillsActions = {
  type: typeof GET_BILLS;
  payload: { bill: IBill[]; pagination: IBillPagination };
};

export type InitAddBillActions = {
  type: typeof INIT_ADD_BILL;
};

export type AddBillActions = {
  type: typeof ADD_BILL;
  payload: IBillDetails;
};

export type EditBillActions = {
  type: typeof EDIT_BILL;
  payload: IBillDetails;
};

export type DeleteBillActions = {
  type: typeof DELETE_BILL;
  payload: IBill;
};

export type GetBillActions = {
  type: typeof GET_BILL;
  payload: IBillDetails;
};

export type GetBillListActions = {
  type: typeof GET_BILL_LIST;
  payload: IBill[];
};

export type InitBillsFilter = {
  type: typeof INIT_BILLS_FILTER;
};

export type SetBillsFilter = {
  type: typeof SET_BILLS_FILTER;
  payload: object;
};

export type GetBillsFilterActions = {
  type: typeof GET_BILLS_FILTER;
  payload: IBill[];
};

export type SetBillIdForEditActions = {
  type: typeof SET_EDIT_BILL_ID;
  payload: number;
};

export type BillsActions =
  | GetBillsActions
  | InitAddBillActions
  | AddBillActions
  | EditBillActions
  | DeleteBillActions
  | GetBillActions
  | GetBillListActions
  | InitBillsFilter
  | SetBillsFilter
  | GetBillsFilterActions
  | SetBillIdForEditActions;
