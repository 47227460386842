import {
  IFDCalculator,
  IEMICalculator,
  IPaymentFrequency,
  IRDCalculator,
} from "../models/Utility";

export const GET_RD_CALCULATION = "GET_RD_CALCULATION";
export const INIT_RD_CALCULATION = "INIT_RD_CALCULATION";
export const GET_FD_CALCULATION = "GET_FD_CALCULATION";
export const INIT_FD_CALCULATION = "INIT_FD_CALCULATION";
export const GET_CUSTOMERS_LIST = "GET_CUSTOMERS_LIST";
export const SET_CUSTOMERS_FILTER = "SET_CUSTOMERS_FILTER";

export type getRdCalculation = {
  type: typeof GET_RD_CALCULATION;
  payload: IRDCalculator;
};

export type initRdCalculation = {
  type: typeof INIT_RD_CALCULATION;
};

export type getFdCalculation = {
  type: typeof GET_FD_CALCULATION;
  payload: IFDCalculator;
};

export type initFdCalculation = {
  type: typeof INIT_FD_CALCULATION;
};
export type SetCustomersFilter = {
  type: typeof SET_CUSTOMERS_FILTER;
  payload: object;
};

export type UtilityActions =
  | getRdCalculation
  | getFdCalculation
  | initRdCalculation
  | initFdCalculation
  | SetCustomersFilter;
