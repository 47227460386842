import React, { useEffect } from "react";
import { Col, Card, Form, Button } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getProfile1, switchProfile } from "toolkit/actions";
import { setAuthToken, setUserProfile } from "@crema/services/auth/jwt-auth";
import { useAppDispatch, useAppSelector } from "toolkit/hooks";
import { useJWTAuthActions } from "@crema/services/auth/jwt-auth/JWTAuthProvider";

interface ChooseDashboardCardProps {
  type: string;
  role?: { id: string; name: string };
  company?: { id: number; name: string };
  isDefault?: boolean;
}

export const ChooseDashboardCard: React.FC<ChooseDashboardCardProps> = ({
  type,
  role = null,
  company = null,
  isDefault = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { newToken, profile } = useAppSelector(({ userList }) => userList);
  const { setJWTAuthData } = useJWTAuthActions();

  const handleChooseRole = async () => {
    const { companyId, roleId } = form.getFieldsValue();
    dispatch(switchProfile(companyId, roleId));
  };
  useEffect(() => {
    if (newToken) {
      setAuthToken(newToken);
      dispatch(getProfile1());
    }
  }, [newToken]);

  useEffect(() => {
    if (profile) {
      setUserProfile(JSON.stringify(profile));
      setJWTAuthData({
        user: profile,
        isLoading: false,
        isAuthenticated: true,
      });
    }
  }, [profile]);

  return (
    <Col span={8}>
      <Form form={form}>
        <Form.Item
          name="companyId"
          initialValue={company.id}
          style={{ display: "none" }}
        >
          <input type="hidden" />
        </Form.Item>
        <Form.Item
          name="roleId"
          initialValue={role.id}
          style={{ display: "none" }}
        >
          <input type="hidden" />
        </Form.Item>
        <Card
          title={role.name}
          actions={[
            <Button key="loginButton" type="primary" onClick={handleChooseRole}>
              <LoginOutlined />
            </Button>,
          ]}
        >
          {company?.name}
        </Card>
      </Form>
    </Col>
  );
};
