// API Calls
import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IBill,
  IBillDetails,
  IGetBills,
} from "@crema/types/models/dairy/Manage-Customer/Bill";
import {
  ADD_BILL,
  DELETE_BILL,
  EDIT_BILL,
  GET_BILL,
  GET_BILLS,
  GET_BILL_LIST,
  INIT_ADD_BILL,
  INIT_BILLS_FILTER,
  SET_BILLS_FILTER,
} from "@crema/types/actions/dairy/Manage-Customer/Bill";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getBills = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/manage-customer/bill", { params: filter })
      .then((response: APIResponse<IGetBills>) => {
        handleApiResponse(dispatch, response, GET_BILLS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateBill = (
  method: "post" | "put",
  data: IBillDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/manage-customer/bill${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IBillDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_BILL : EDIT_BILL,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteBill = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/manage-customer/bill/${id}`, data)
      .then((response: APIResponse<IBill>) => {
        handleApiResponse(dispatch, response, DELETE_BILL);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getBillById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-customer/bill/${id}`)
      .then((response: APIResponse<IBillDetails>) => {
        handleApiResponse(dispatch, response, GET_BILL);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getBillList = (branchId: string | number, search: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-customer/bill`, { params: { branchId, search } })
      .then((response: APIResponse<Array<IBill>>) => {
        handleApiResponse(dispatch, response, GET_BILL_LIST);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddBill = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_BILL });
  };
};

export const initBillsFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_BILLS_FILTER });
};

export const setBillsFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_BILLS_FILTER, payload: formData });
  };
