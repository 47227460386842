import { StaffObjType } from "@crema/types/models/apps/Todo";

export const staffList: StaffObjType[] = [
  {
    id: 501,
    name: "Johnson",
    image: "/assets/images/avatar/A11.jpg",
  },
  {
    id: 502,
    name: "Joe Root",
    image: "/assets/images/avatar/A15.jpg",
  },
  {
    id: 503,
    name: "Monty Panesar",
    image: "/assets/images/avatar/A18.jpg",
  },
  {
    id: 504,
    name: "Darren Gough",
    image: "/assets/images/avatar/A21.jpg",
  },
  {
    id: 505,
    name: "Andy Caddick",
    image: "/assets/images/avatar/A24.jpg",
  },
  {
    id: 506,
    name: "Marcus Vaughan",
    image: "/assets/images/avatar/A28.jpg",
  },
];
