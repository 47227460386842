import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIErrorResProps,
  APIResponse,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  GET_DAILY_MILK_COLLECTION_GRAPH_VIEW,
  GET_MONTHLY_MILK_COLLECTION_GRAPH_VIEW,
  GET_MONTHLY_TOP_TEN_CUSTOMER_GRAPH_VIEW,
  GET_TOTAL_CUSTOMER_GRAPH_VIEW,
  GET_YEARLY_TOP_TEN_CUSTOMER_GRAPH_VIEW,
} from "@crema/types/actions/dairy/Dashboard";
import {
  IDailyMilkCollectionGraphView,
  IMonthlyMilkCollectionGraphView,
  IMonthlyTopTenCustomerGraphView,
  ITotalCustomerGraphView,
  IYearlyTopTenCustomerGraphView,
} from "@crema/types/models/dairy/Dashboard";

export const getDailyMilkCollectionGraphView = (filter?: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/dashboard/daily-milk-collection-graph-view", { params: filter })
      .then((response: APIResponse<IDailyMilkCollectionGraphView[]>) => {
        handleApiResponse(
          dispatch,
          response,
          GET_DAILY_MILK_COLLECTION_GRAPH_VIEW,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getMonthlyMilkCollectionGraphView = (filter?: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/dashboard/monthly-milk-collection-graph-view", { params: filter })
      .then((response: APIResponse<IMonthlyMilkCollectionGraphView[]>) => {
        handleApiResponse(
          dispatch,
          response,
          GET_MONTHLY_MILK_COLLECTION_GRAPH_VIEW,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getYearlyTopTenCustomerGraphView = (filter?: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/dashboard/yearly-top-ten-customer-graph-view", { params: filter })
      .then((response: APIResponse<IYearlyTopTenCustomerGraphView[]>) => {
        handleApiResponse(
          dispatch,
          response,
          GET_YEARLY_TOP_TEN_CUSTOMER_GRAPH_VIEW,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getMonthlyTopTenCustomerGraphView = (filter?: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/dashboard/monthly-top-ten-customer-graph-view", { params: filter })
      .then((response: APIResponse<IMonthlyTopTenCustomerGraphView[]>) => {
        handleApiResponse(
          dispatch,
          response,
          GET_MONTHLY_TOP_TEN_CUSTOMER_GRAPH_VIEW,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getTotalCustomerGraphView = (filter?: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/dashboard/total-customer-graph-view", { params: filter })
      .then((response: APIResponse<ITotalCustomerGraphView[]>) => {
        handleApiResponse(dispatch, response, GET_TOTAL_CUSTOMER_GRAPH_VIEW);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
