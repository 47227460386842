import {
  GET_PRODUCTS,
  INIT_ADD_PRODUCT,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT,
  INIT_PRODUCTS_FILTER,
  SET_PRODUCTS_FILTER,
  ATTACH_LEDGER,
  ADD_STOCK,
} from "@crema/types/actions/dairy/Manage-Product/Product";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IProductDetails,
  IProductList,
  IGetProduct,
} from "@crema/types/models/dairy/Manage-Product/Product";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialProductState = {
  products: [] as IProductList[],
  pagination: null as PaginationType | null,
  product: null as IProductDetails | null,
  details: null as IProductDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  productsFilter: {} as object | IProductList,
  toggleToReload: false as boolean,
};

export const getProductsAction = createAction<IGetProduct>(GET_PRODUCTS);
export const initAddProductAction = createAction(INIT_ADD_PRODUCT);
export const addProductAction = createAction<IProductDetails>(ADD_PRODUCT);
export const editProductAction = createAction(EDIT_PRODUCT);
export const deleteProductAction = createAction<IProductList>(DELETE_PRODUCT);
export const getProductAction = createAction<IProductDetails>(GET_PRODUCT);
export const initProductsFilter = createAction(INIT_PRODUCTS_FILTER);
export const setProductsFilter = createAction<object>(SET_PRODUCTS_FILTER);
export const attachLedger = createAction<IProductList>(ATTACH_LEDGER);
export const addStock = createAction<IProductList>(ADD_STOCK);

const productReducer = createReducer(initialProductState, (builder) => {
  builder
    .addCase(getProductsAction, (state, action) => {
      state.products = action.payload.product;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
      };
    })
    .addCase(initAddProductAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addProductAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(editProductAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(deleteProductAction, (state, action) => {
      state.products = state.products.filter(
        (product) => product.id !== action.payload.id,
      );
    })
    .addCase(getProductAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(initProductsFilter, (state) => {
      state.productsFilter = {};
    })
    .addCase(setProductsFilter, (state, action) => {
      state.productsFilter = action.payload;
    })
    .addCase(attachLedger, (state, action) => {
      state.products.forEach((product, index) => {
        if (product.id == action.payload.id) {
          state.products[index].ledgerCode = action.payload.ledgerCode;
          state.products[index].ledgerId = action.payload.ledgerId;
          state.products[index].ledgerName = action.payload.ledgerName;
        }
      });
    })
    .addCase(addStock, (state, action) => {
      state.products.forEach((product, index) => {
        if (product.id == action.payload.id) {
          state.products[index].stock = action.payload.stock;
        }
      });
    });
});

export default productReducer;
