import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IPaymentsList,
  IPaymentsDetails,
  IPaymentsShort,
  IGetPayments,
} from "@crema/types/models/dairy/Manage-Customer/Payments";
import {
  ADD_PAYMENT,
  DELETE_PAYMENT,
  EDIT_PAYMENT,
  GET_PAYMENT,
  GET_PAYMENTS,
  GET_PAYMENT_LIST,
  INIT_ADD_PAYMENT,
  INIT_PAYMENTS_FILTER,
  SET_PAYMENTS_FILTER,
} from "@crema/types/actions/dairy/Manage-Customer/Payments";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getPayments = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/manage-customer/payment", { params: filter })
      .then((response: APIResponse<IGetPayments>) => {
        handleApiResponse(dispatch, response, GET_PAYMENTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdatePayment = (
  method: "post" | "put",
  data: IPaymentsDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/manage-customer/payment${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IPaymentsDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_PAYMENT : EDIT_PAYMENT,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deletePayment = (customerCode: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/manage-customer/payment/${customerCode}`, data)
      .then((response: APIResponse<IPaymentsList>) => {
        handleApiResponse(dispatch, response, DELETE_PAYMENT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getPaymentById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-customer/payment/${id}`)
      .then((response: APIResponse<IPaymentsDetails>) => {
        handleApiResponse(dispatch, response, GET_PAYMENT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getPaymentList = (branchId: string | number, search: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-customer/payment`, { params: { branchId, search } })
      .then((response: APIResponse<Array<IPaymentsShort>>) => {
        handleApiResponse(dispatch, response, GET_PAYMENT_LIST);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddPayment = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_PAYMENT });
  };
};

export const initPaymentsFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_PAYMENTS_FILTER });
};

export const setPaymentsFilter =
  (formData: any) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_PAYMENTS_FILTER, payload: formData });
  };
