import React from "react";

const Signin = React.lazy(() => import("../../../modules/auth/Signin"));
const Signup = React.lazy(() => import("../../../modules/auth/Signup"));
const ForgotPassword = React.lazy(
  () => import("../../../modules/auth/ForgetPassword"),
);
const ResetPasswordAwsCognito = React.lazy(
  () => import("../../../modules/auth/ForgetPassword/ResetPasswordAwsCognito"),
);
export const authRouteConfig = [
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordAwsCognito />,
  },
];
