import {
  GET_PRODUCT_BRANDS,
  INIT_ADD_PRODUCT_BRAND,
  ADD_PRODUCT_BRAND,
  EDIT_PRODUCT_BRAND,
  DELETE_PRODUCT_BRAND,
  GET_PRODUCT_BRAND,
  GET_PRODUCT_BRAND_LIST,
  INIT_PRODUCT_BRANDS_FILTER,
  SET_PRODUCT_BRANDS_FILTER,
  SET_EDIT_PRODUCT_BRAND_ID,
} from "@crema/types/actions/dairy/Manage-Product/Product-Brand/Product-Brand";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IProductBrandDetails,
  IProductBrandList,
  IProductBrandShort,
  IGetProductBrand,
} from "@crema/types/models/dairy/Manage-Product/Product-Brand/Product-Brand";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialProductBrandState = {
  productBrands: [] as IProductBrandList[],
  pagination: null as PaginationType | null,
  productBrand: null as IProductBrandDetails | null,
  details: null as IProductBrandDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  productBrandList: [] as IProductBrandShort[],
  productBrandsFilter: {} as object,
  toggleReload: false as boolean,
  productBrandId: 0 as number,
};

export const getProductBrandsAction =
  createAction<IGetProductBrand>(GET_PRODUCT_BRANDS);
export const initAddProductBrandAction = createAction(INIT_ADD_PRODUCT_BRAND);
export const addProductBrandAction =
  createAction<IProductBrandDetails>(ADD_PRODUCT_BRAND);
export const editProductBrandAction = createAction(EDIT_PRODUCT_BRAND);
export const deleteProductBrandAction =
  createAction<IProductBrandList>(DELETE_PRODUCT_BRAND);
export const getProductBrandAction =
  createAction<IProductBrandDetails>(GET_PRODUCT_BRAND);
export const getProductBrandListByBranchIdAction = createAction<
  IProductBrandShort[]
>(GET_PRODUCT_BRAND_LIST);
export const initProductBrandsFilter = createAction(INIT_PRODUCT_BRANDS_FILTER);
export const setProductBrandsFilter = createAction<object>(
  SET_PRODUCT_BRANDS_FILTER,
);
export const setEditProductBranchId = createAction<number>(
  SET_EDIT_PRODUCT_BRAND_ID,
);

const productBrandReducer = createReducer(
  initialProductBrandState,
  (builder) => {
    builder
      .addCase(getProductBrandsAction, (state, action) => {
        state.productBrands = action.payload.productBrand;
        state.pagination = {
          total: action.payload.pagination.totalCount,
          from: action.payload.pagination.pageNumber,
          perPage: Number(action.payload.pagination.pageSize),
        };
      })
      .addCase(initAddProductBrandAction, (state) => {
        state.details = null;
        state.validationError = null;
        state.success = false;
      })
      .addCase(addProductBrandAction, (state, action) => {
        state.toggleReload = !state.toggleReload;
      })
      .addCase(editProductBrandAction, (state, action) => {
        state.toggleReload = !state.toggleReload;
        // state.productBrandId = 0;
      })
      .addCase(deleteProductBrandAction, (state, action) => {
        state.productBrands = state.productBrands.filter(
          (productBrand) => productBrand.id !== action.payload.id,
        );
      })
      .addCase(getProductBrandAction, (state, action) => {
        state.details = action.payload;
      })
      .addCase(getProductBrandListByBranchIdAction, (state, action) => {
        state.productBrandList = action.payload;
      })

      .addCase(initProductBrandsFilter, (state) => {
        state.productBrandsFilter = {};
      })
      .addCase(setProductBrandsFilter, (state, action) => {
        state.productBrandsFilter = action.payload;
      })
      .addCase(setEditProductBranchId, (state, action) => {
        state.productBrandId = action.payload;
      });
  },
);

export default productBrandReducer;
