import {
  ICustomerProductDetails,
  ICustomerProductList,
  ICustomerProductShort,
} from "@crema/types/models/dairy/Manage-Customer/CustomerProducts/CustomerProducts";

export const GET_CUSTOMER_PRODUCTS = "GET_CUSTOMER_PRODUCTS";
export const INIT_ADD_CUSTOMER_PRODUCT = "INIT_ADD_CUSTOMER_PRODUCT";
export const ADD_CUSTOMER_PRODUCT = "ADD_CUSTOMER_PRODUCT";
export const EDIT_CUSTOMER_PRODUCT = "EDIT_CUSTOMER_PRODUCT";
export const DELETE_CUSTOMER_PRODUCT = "DELETE_CUSTOMER_PRODUCT";
export const GET_CUSTOMER_PRODUCT = "GET_CUSTOMER_PRODUCT";
export const GET_CUSTOMER_PRODUCT_LIST = "GET_CUSTOMER_PRODUCT_LIST";
export const INIT_CUSTOMER_PRODUCTS_FILTER = "INIT_CUSTOMER_PRODUCTS_FILTER";
export const SET_CUSTOMER_PRODUCTS_FILTER = "SET_CUSTOMER_PRODUCTS_FILTER";
export const GET_CUSTOMER_PRODUCTS_FILTER = "GET_CUSTOMER_PRODUCTS_FILTER";
export const SET_EDIT_CUSTOMER_PRODUCT_ID = "SET_EDIT_CUSTOMER_PRODUCT_ID";

export type GetCustomerProductsActions = {
  type: typeof GET_CUSTOMER_PRODUCTS;
  payload: ICustomerProductList[];
};

export type InitAddCustomerProductActions = {
  type: typeof INIT_ADD_CUSTOMER_PRODUCT;
};

export type AddCustomerProductActions = {
  type: typeof ADD_CUSTOMER_PRODUCT;
  payload: ICustomerProductDetails;
};

export type EditCustomerProductActions = {
  type: typeof EDIT_CUSTOMER_PRODUCT;
  payload: ICustomerProductDetails;
};

export type GetCustomerProductActions = {
  type: typeof GET_CUSTOMER_PRODUCT;
  payload: ICustomerProductDetails;
};

export type GetCustomerProductListActions = {
  type: typeof GET_CUSTOMER_PRODUCT_LIST;
  payload: ICustomerProductShort[];
};

export type DeleteCustomerProductActions = {
  type: typeof DELETE_CUSTOMER_PRODUCT;
  payload: ICustomerProductList;
};

export type InitCustomerProductsFilter = {
  type: typeof INIT_CUSTOMER_PRODUCTS_FILTER;
};

export type SetCustomerProductsFilter = {
  type: typeof SET_CUSTOMER_PRODUCTS_FILTER;
  payload: object;
};

export type GetCustomerProductsFilterActions = {
  type: typeof GET_CUSTOMER_PRODUCTS_FILTER;
  payload: ICustomerProductList[];
};
export type SetProductGroupIdForEditActions = {
  type: typeof SET_EDIT_CUSTOMER_PRODUCT_ID;
  payload: number;
};

export type CustomerProductActions =
  | GetCustomerProductsActions
  | InitAddCustomerProductActions
  | AddCustomerProductActions
  | EditCustomerProductActions
  | DeleteCustomerProductActions
  | GetCustomerProductActions
  | GetCustomerProductListActions
  | InitCustomerProductsFilter
  | SetCustomerProductsFilter
  | SetProductGroupIdForEditActions;
