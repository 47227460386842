// ForJWT Auth
import { getUserFromJwtAuth } from "@crema/helpers/AuthHelper";
import {
  useJWTAuth,
  useJWTAuthActions,
} from "@crema/services/auth/jwt-auth/JWTAuthProvider";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
  };
};

export const useAuthMethod = () => {
  const { signInUser, logout, signUpUser, proxy, proxyBackToAdmin } =
    useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
    proxy,
    proxyBackToAdmin,
  };
};

export const hasPermission = (permission) => {
  const { user } = useAuthUser();
  return user?.currentRole?.permissions?.includes(permission);
};
