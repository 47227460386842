import enMessages from "../locales/hi_US.json";
import hiIn from "antd/lib/locale/hi_IN";
import enValidationMessages from "../locales/hi_validation.json";

const HiLang = {
  messages: {
    ...enMessages,
    ...enValidationMessages,
  },
  antLocale: hiIn,
  locale: "hi-IN",
};
export default HiLang;
