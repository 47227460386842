import {
  CLEAR_VALIDATION_ERROR,
  FETCH_ERROR,
  SHOW_MESSAGE,
  VALIDATION_ERROR,
  VALIDATION_ERROR_DISPLAYED,
} from "@crema/types/actions/Common.action";
import { ValidationError } from "@crema/types/models/APIDataProps";
import { createAction } from "@reduxjs/toolkit";

export const ShowMsgAction = createAction<string>(SHOW_MESSAGE);
export const FetchErrorAction = createAction<string>(FETCH_ERROR);
export const ValidationErrorAction =
  createAction<ValidationError>(VALIDATION_ERROR);
export const ClearValidationErrorAction = createAction(CLEAR_VALIDATION_ERROR);
export const ValidationErrorDisplayedAction = createAction<boolean>(
  VALIDATION_ERROR_DISPLAYED,
);
