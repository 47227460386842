import {
  GET_PAYMENTS,
  INIT_ADD_PAYMENT,
  ADD_PAYMENT,
  EDIT_PAYMENT,
  DELETE_PAYMENT,
  GET_PAYMENT,
  GET_PAYMENT_LIST,
  INIT_PAYMENTS_FILTER,
  SET_PAYMENTS_FILTER,
  SET_EDIT_PAYMENT_ID,
} from "@crema/types/actions/dairy/Manage-Customer/Payments";
import {
  PaginationType,
  ValidationError,
} from "@crema/types/models/APIDataProps";
import {
  IPaymentsDetails,
  IPaymentsList,
  IPaymentsShort,
  IGetPayments,
  IPaymentFooter,
} from "@crema/types/models/dairy/Manage-Customer/Payments";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialPaymentState = {
  payments: [] as IPaymentsList[],
  pagination: null as PaginationType | null,
  paymentFooter: null as IPaymentFooter | null,
  payment: null as IPaymentsDetails | null,
  details: null as IPaymentsDetails,
  validationError: null as ValidationError | null,
  success: false as boolean,
  paymentList: [] as IPaymentsShort[],
  paymentsFilter: {
    pageNumber: 0,
    pageSize: 10,
  } as any,
  toggleToReload: false as boolean,
  paymentId: 0 as number,
};

export const getPaymentsAction = createAction<IGetPayments>(GET_PAYMENTS);
export const initAddPaymentAction = createAction(INIT_ADD_PAYMENT);
export const addPaymentAction = createAction<IPaymentsDetails>(ADD_PAYMENT);
export const editPaymentAction = createAction(EDIT_PAYMENT);
export const deletePaymentAction = createAction<IPaymentsList>(DELETE_PAYMENT);
export const getPaymentAction = createAction<IPaymentsDetails>(GET_PAYMENT);
export const getPaymentListAction =
  createAction<IPaymentsShort[]>(GET_PAYMENT_LIST);
export const initPaymentsFilter = createAction(INIT_PAYMENTS_FILTER);
export const setPaymentsFilter = createAction<any>(SET_PAYMENTS_FILTER);
export const setEditPaymentId = createAction<number>(SET_EDIT_PAYMENT_ID);

const paymentReducer = createReducer(initialPaymentState, (builder) => {
  builder
    .addCase(getPaymentsAction, (state, action) => {
      state.payments = action.payload.payment;
      state.pagination = {
        ...action.payload.pagination,
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: action.payload.pagination.pageSize,
      };
      state.paymentFooter = action.payload.pagination;
    })
    .addCase(initAddPaymentAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addPaymentAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(editPaymentAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(deletePaymentAction, (state, action) => {
      state.payments = state.payments.filter(
        (payment) => payment.customerCode !== action.payload.customerCode,
      );
    })
    .addCase(getPaymentAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getPaymentListAction, (state, action) => {
      state.paymentList = action.payload;
    })
    .addCase(initPaymentsFilter, (state) => {
      state.paymentsFilter = {
        pageNumber: 0,
        pageSize: 10,
      };
    })
    .addCase(setPaymentsFilter, (state, action) => {
      state.paymentsFilter = action.payload;
    })
    .addCase(setEditPaymentId, (state, action) => {
      state.paymentId = action.payload;
    });
});

export default paymentReducer;
