import { ContactObjType } from "@crema/types/models/apps/Contact";

const contactList: ContactObjType[] = [
  {
    id: 2001,
    name: "Jubin Chawla",
    email: "jubin.chawla@xyz.com",
    contact: "+1-344432739",
    company: "First Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 312,
    image: "/assets/images/avatar/A1.jpg",
  },
  {
    id: 2002,
    name: "Mark Johnson",
    email: "mark.johnson@abc.com",
    contact: "+1-34354234",
    company: "Second Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 311,
    image: "/assets/images/avatar/A2.jpg",
  },
  {
    id: 2003,
    name: "Shane Lee",
    email: "shane.lee@mnz.com",
    contact: "+1-324563332",
    company: "Third Exclusive Limited",
    isStarred: true,
    isFrequent: Math.random() > 0.5,
    label: 313,
    image: "/assets/images/avatar/A3.jpg",
  },
  {
    id: 2004,
    name: "Chris Crains",
    email: "chris.crains@cde.com",
    contact: "+1-233454323",
    company: "Fourth Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 311,
    image: "/assets/images/avatar/A4.jpg",
  },
  {
    id: 2005,
    name: "Jonathan Trott",
    email: "jonathan.trott@ewr.com",
    contact: "+1-324565345",
    company: "Fifth Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 312,
    image: "/assets/images/avatar/A5.jpg",
  },
  {
    id: 2006,
    name: "Rohit Sharma",
    email: "rohit.sharma@xcr.com",
    contact: "+1-435424345",
    company: "Sixth Exclusive Limited",
    isStarred: true,
    isFrequent: Math.random() > 0.5,
    label: 313,
    image: "/assets/images/avatar/A6.jpg",
  },
  {
    id: 2007,
    name: "Mark Lee",
    email: "mark.lee@cdf.com",
    contact: "+1-989323434",
    company: "Seventh Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 311,
    image: "/assets/images/avatar/A7.jpg",
  },
  {
    id: 2008,
    name: "Sain Williams",
    email: "sain.williams@gww.com",
    contact: "+1-349848303",
    company: "Eighth Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 312,
    image: "/assets/images/avatar/A8.jpg",
  },
  {
    id: 2009,
    name: "Parth Aulins",
    email: "parth.aulins@ddd.com",
    contact: "+1-4874387443",
    company: "Ninth Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 313,
    image: "/assets/images/avatar/A9.jpg",
  },
  {
    id: 2010,
    name: "Rahul Bose",
    email: "rahul.bose@xyz.com",
    contact: "+1-43235454",
    company: "Tenth Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 311,
    image: "/assets/images/avatar/A10.jpg",
  },
  {
    id: 2011,
    name: "Rohan Bopanna",
    email: "rohan.bopanna4@red.com",
    contact: "+1-432123345",
    company: "Eleventh Exclusive Limited",
    isStarred: true,
    isFrequent: Math.random() > 0.5,
    label: 312,
    image: "/assets/images/avatar/A11.jpg",
  },
  {
    id: 2012,
    name: "Nikit Panwar",
    email: "nikit.panwar@xyz.com",
    contact: "+1-542324563",
    company: "International Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 313,
    image: "/assets/images/avatar/A12.jpg",
  },
  {
    id: 2013,
    name: "Vijay Murli",
    email: "vijay.murli@xyz.com",
    contact: "+1-435432434",
    company: "First Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 311,
    image: "/assets/images/avatar/A13.jpg",
  },
  {
    id: 2014,
    name: "David Boon",
    email: "david.boon@xyz.com",
    contact: "+1-343322323",
    company: "Second Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 312,
    image: "/assets/images/avatar/A14.jpg",
  },
  {
    id: 2015,
    name: "Saurabh Shukla",
    email: "saurabh.shukla@sdd.com",
    contact: "+1-334354333",
    company: "Seventh Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 313,
    image: "/assets/images/avatar/A15.jpg",
  },
  {
    id: 2016,
    name: "Nick Taylor",
    email: "nick.taylor@usa.com",
    contact: "+1-432543345",
    company: "Eighth Exclusive Limited",
    isStarred: true,
    isFrequent: Math.random() > 0.5,
    label: 312,
    image: "/assets/images/avatar/A15.jpg",
  },
  {
    id: 2017,
    name: "Nikita Suman",
    email: "nikita.suman@nicks.com",
    contact: "+91-3283828373",
    company: "AXY Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 311,
    image: "/assets/images/avatar/A18.jpg",
  },
  {
    id: 2018,
    name: "Laura Lohan",
    email: "laura.lohan@laura.com",
    contact: "+1-3232323",
    company: "US Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 313,
    image: "/assets/images/avatar/A19.jpg",
  },
  {
    id: 2019,
    name: "Jennifer John",
    email: "jennifer.john@abc.com",
    contact: "+1-334354333",
    company: "Seventh Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 313,
    image: "/assets/images/avatar/A15.jpg",
  },
  {
    id: 2020,
    name: "Robert Key",
    email: "robert.key@key.com",
    contact: "+1-3289893323",
    company: "UK Exclusive Limited",
    isStarred: false,
    isFrequent: Math.random() > 0.5,
    label: 313,
    image: "/assets/images/avatar/A20.jpg",
  },
];
export default contactList;
