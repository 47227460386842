import {
  IClusterDetails,
  IClusterList,
  IClusterShort,
} from "@crema/types/models/dairy/Manage-Cluster/Cluster";

export const GET_CLUSTERS = "GET_CLUSTERS";
export const INIT_ADD_CLUSTER = "INIT_ADD_CLUSTER";
export const ADD_CLUSTER = "ADD_CLUSTER";
export const EDIT_CLUSTER = "EDIT_CLUSTER";
export const DELETE_CLUSTER = "DELETE_CLUSTER";
export const GET_CLUSTER = "GET_CLUSTER";
export const GET_CLUSTER_LIST = "GET_CLUSTER_LIST";
export const INIT_CLUSTERS_FILTER = "INIT_CLUSTERS_FILTER";
export const SET_CLUSTERS_FILTER = "SET_CLUSTERS_FILTER";
export const GET_CLUSTERS_FILTER = "GET_CLUSTERS_FILTER";

export type GetClustersActions = {
  type: typeof GET_CLUSTERS;
  payload: IClusterList[];
};

export type InitAddClusterActions = {
  type: typeof INIT_ADD_CLUSTER;
};

export type AddClusterActions = {
  type: typeof ADD_CLUSTER;
  payload: IClusterDetails;
};

export type EditClusterActions = {
  type: typeof EDIT_CLUSTER;
  payload: IClusterDetails;
};

export type GetClusterActions = {
  type: typeof GET_CLUSTER;
  payload: IClusterDetails;
};

export type GetClusterListActions = {
  type: typeof GET_CLUSTER_LIST;
  payload: IClusterShort[];
};

export type DeleteClusterActions = {
  type: typeof DELETE_CLUSTER;
  payload: IClusterList;
};

export type InitClustersFilter = {
  type: typeof INIT_CLUSTERS_FILTER;
};

export type SetClustersFilter = {
  type: typeof SET_CLUSTERS_FILTER;
  payload: object;
};

export type GetClustersFilterActions = {
  type: typeof GET_CLUSTERS_FILTER;
  payload: IClusterList[];
};

export type ClusterActions =
  | GetClustersActions
  | InitAddClusterActions
  | AddClusterActions
  | EditClusterActions
  | DeleteClusterActions
  | GetClusterActions
  | GetClusterListActions
  | InitClustersFilter
  | SetClustersFilter;
