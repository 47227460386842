import {
  GET_PROFILE,
  GET_USER_LIST,
  SWITCH_PROFILE,
} from "@crema/constants/ActionTypes";
import { IProfile, UserList } from "@crema/types/models/Apps";
import { AccessTokenResponse } from "@crema/types/models/AuthUser";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState: {
  usersList: UserList[];
  swirchProfileSuccess: boolean;
  newToken: string;
  profile: IProfile;
} = {
  usersList: [],
  swirchProfileSuccess: true,
  newToken: null,
  profile: null as IProfile,
};

export const GetUserListAction = createAction<UserList[]>(GET_USER_LIST);
export const switchProfile = createAction<AccessTokenResponse>(SWITCH_PROFILE);
export const getProfile = createAction<IProfile>(GET_PROFILE);

const userListReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GetUserListAction, (state, action) => {
      state.usersList = action.payload;
    })
    .addCase(switchProfile, (state, action) => {
      state.swirchProfileSuccess = true;
      state.newToken = action.payload.accessToken;
    })
    .addCase(getProfile, (state, action) => {
      state.profile = action.payload;
    });
});

export default userListReducer;
