import React from "react";
import { RoutePermission } from "@crema/constants/AppConst";

const List = React.lazy(() => import("./List"));
const Add = React.lazy(() => import("./Add"));
const Edit = React.lazy(() => import("./Edit"));
const View = React.lazy(() => import("./View"));

export const ledgerComponentConfigs = [
  {
    path: "/manage-ledger/ledgers",
    element: <List />,
    permission: RoutePermission.dairy.ledger.list,
  },
  {
    path: "/manage-ledger/ledgers/add",
    element: <Add />,
    permission: RoutePermission.dairy.ledger.create,
  },
  {
    path: "/manage-ledger/ledgers/edit/:id",
    element: <Edit />,
    permission: RoutePermission.dairy.ledger.update,
  },
  {
    path: "/manage-ledger/ledgers/view/:id",
    element: <View />,
    permission: RoutePermission.dairy.ledger.single,
  },
];
