import React from "react";

const Dashboard = React.lazy(() => import("./index"));

export const dashboardComponentRouteConfigs = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
];
