import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  ICompanyList,
  ICompanyDetails,
  ICompanyShort,
  IGetCompany,
} from "@crema/types/models/superAdmin/company/company";
import {
  ADD_COMPANY,
  DELETE_COMPANY,
  EDIT_COMPANY,
  GET_COMPANY,
  GET_COMPANIES,
  INIT_ADD_COMPANY,
  INIT_COMPANIES_FILTER,
  SET_COMPANIES_FILTER,
} from "@crema/types/actions/superAdmin/Company/Company.action";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getCompanies = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/admin-api/company", { params: filter })
      .then((response: APIResponse<IGetCompany>) => {
        handleApiResponse(dispatch, response, GET_COMPANIES);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateCompany = (
  method: "post" | "put",
  data: ICompanyDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/admin-api/company${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<ICompanyDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_COMPANY : EDIT_COMPANY,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteCompany = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`/admin-api/company/${id}`, data)
      .then((response: APIResponse<ICompanyList>) => {
        handleApiResponse(dispatch, response, DELETE_COMPANY);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getCompanyById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/company/${id}`)
      .then((response: APIResponse<ICompanyDetails>) => {
        handleApiResponse(dispatch, response, GET_COMPANY);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddCompany = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_COMPANY });
  };
};

export const initCompaniesFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_COMPANIES_FILTER });
};

export const setCompaniesFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_COMPANIES_FILTER, payload: formData });
  };
