import React from "react";
import {
  StyledAuth,
  StyledAuthCard,
  StyledAuthCardHeader,
  StyledAuthMainContent,
  StyledAuthWelContent,
  StyledAuthWellAction,
  StyledAuthWrap,
  StyledMainAuthScrollbar,
} from "./AuthWrapper.styled";
import AppLogo from "@crema/components/AppLayout/components/AppLogo";
import AppAnimateGroup from "@crema/components/AppAnimateGroup";
import AppInfoView from "@crema/components/AppInfoView";

type Props = {
  children: React.ReactNode;
};
const AuthWrapper: React.FC<Props> = ({ children }) => {
  return (
    <StyledAuth>
      <StyledMainAuthScrollbar>
        <AppAnimateGroup
          type="scale"
          animateStyle={{ flex: 1 }}
          delay={0}
          style={{ height: "100%" }}
          interval={10}
          duration={200}
        >
          <StyledAuthWrap key={"wrap"}>
            <StyledAuthCard>
              <StyledAuthMainContent>
                <StyledAuthCardHeader>
                  <AppLogo />
                </StyledAuthCardHeader>
                {children}
              </StyledAuthMainContent>
              <StyledAuthWellAction>
                <StyledAuthWelContent>
                  <h2>Welcome to Hamari Dairy!</h2>
                  <p>
                    Our platform offers a seamless dairy management experience
                    across mobile, web, and desktop. Easily manage milk
                    procurement, testing, and sales on our intuitive mobile app.
                    The web interface provides features like milk rate
                    management and inventory tracking, accessible from any
                    device. For advanced functionality, our desktop app enables
                    bulk milk collection and detailed reporting. Hamari Dairy
                    simplifies dairy operations for farmers, suppliers, and
                    sellers. Sign in now and discover the convenience of
                    managing your dairy business anytime, anywhere!
                  </p>
                </StyledAuthWelContent>
              </StyledAuthWellAction>
            </StyledAuthCard>
          </StyledAuthWrap>
          <AppInfoView />
        </AppAnimateGroup>
      </StyledMainAuthScrollbar>
    </StyledAuth>
  );
};

export default AuthWrapper;
