import {
  ILedgers,
  ILedgerDetails,
  ILedgerShort,
  ILedgerFooter,
  ILedgerTransactions,
} from "@crema/types/models/dairy/Manage-Ledger/Ledger";
import {
  GET_LEDGER,
  GET_LEDGERS,
  ADD_LEDGER,
  EDIT_LEDGER,
  GET_LEDGER_LIST,
  INIT_ADD_LEDGER,
  INIT_LEDGER_FILTER,
  SET_LEDGER_FILTER,
  GET_LEDGER_TRANSACTIONS,
  INIT_LEDGER_TRANSACTION_FILTER,
  SET_LEDGER_TRANSACTION_FILTER,
} from "@crema/types/actions/dairy/Manage-Ledger/Ledger.action";
import {
  PaginationType,
  ValidationError,
} from "@crema/types/models/APIDataProps";
import { createAction, createReducer } from "@reduxjs/toolkit";

export const getLedgersAction = createAction<any>(GET_LEDGERS);
export const getLedgerAction = createAction<ILedgerDetails>(GET_LEDGER);
export const initAddLedgerAction = createAction(INIT_ADD_LEDGER);
export const addLedgerAction = createAction<ILedgerDetails>(ADD_LEDGER);
export const editLedgerAction = createAction<ILedgerDetails>(EDIT_LEDGER);
export const initLedgerFilterAction = createAction(INIT_LEDGER_FILTER);
export const setLedgerFilterAction = createAction<object>(SET_LEDGER_FILTER);
export const getLedgerListAction =
  createAction<ILedgerShort[]>(GET_LEDGER_LIST);
export const getLedgerTransactionsAction = createAction<any>(
  GET_LEDGER_TRANSACTIONS,
);
export const initLedgerTransactionFilterAction = createAction(
  INIT_LEDGER_TRANSACTION_FILTER,
);
export const setLedgerTransactionFilterAction = createAction<object>(
  SET_LEDGER_TRANSACTION_FILTER,
);

const initialState = {
  ledgers: [] as ILedgers[],
  ledgerList: [] as ILedgerShort[],
  ledgerFooter: null as ILedgerFooter,
  pagination: null as PaginationType | null,
  ledger: null as ILedgerDetails,
  details: null as ILedgerDetails,
  validationError: null as ValidationError,
  success: false as boolean,
  ledgerFilter: {} as object,
  ledgerTransactions: [] as ILedgerTransactions[],
  ledgerTransactionPagination: null as PaginationType | null,
  ledgerTransactionFooter: null as ILedgerFooter,
  ledgerTransactionFilter: {} as object,
};

const ledgerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getLedgersAction, (state, action) => {
      state.ledgers = action.payload.data;
      state.ledgerFooter = action.payload.count;
    })
    .addCase(getLedgerAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(initAddLedgerAction, (state, action) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addLedgerAction, (state, action) => {
      state.success = true;
      state.ledger = action.payload;
    })
    .addCase(editLedgerAction, (state, action) => {
      state.ledger = action.payload;
      state.ledgers = state.ledgers.map((ledger) => {
        if (ledger.id === action.payload.id) {
          return {
            ...ledger,
            ...action.payload,
          };
        }
        return ledger;
      });
      state.success = true;
    })
    .addCase(getLedgerListAction, (state, action) => {
      state.ledgerList = action.payload;
    })
    .addCase(initLedgerFilterAction, (state, action) => {
      state.ledgerFilter = {};
    })
    .addCase(setLedgerFilterAction, (state, action) => {
      state.ledgerFilter = action.payload;
    })
    .addCase(initLedgerTransactionFilterAction, (state, action) => {
      state.ledgerTransactionFilter = {};
    })
    .addCase(setLedgerTransactionFilterAction, (state, action) => {
      state.ledgerTransactionFilter = action.payload;
    });
});

export default ledgerReducer;
