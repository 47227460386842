import {
  IDailyMilkCollectionGraphView,
  IMonthlyMilkCollectionGraphView,
  IMonthlyTopTenCustomerGraphView,
  ITotalCustomerGraphView,
  IYearlyTopTenCustomerGraphView,
} from "@crema/types/models/dairy/Dashboard";

export const GET_DAILY_MILK_COLLECTION_GRAPH_VIEW =
  "GET_DAILY_MILK_COLLECTION_GRAPH_VIEW";
export const GET_MONTHLY_MILK_COLLECTION_GRAPH_VIEW =
  "GET_MONTHLY_MILK_COLLECTION_GRAPH_VIEW";
export const GET_YEARLY_TOP_TEN_CUSTOMER_GRAPH_VIEW =
  "GET_YEARLY_TOP_TEN_CUSTOMER_GRAPH_VIEW";
export const GET_MONTHLY_TOP_TEN_CUSTOMER_GRAPH_VIEW =
  "GET_MONTHLY_TOP_TEN_CUSTOMER_GRAPH_VIEW";
export const GET_TOTAL_CUSTOMER_GRAPH_VIEW = "GET_TOTAL_CUSTOMER_GRAPH_VIEW";

export type GetDailyMilkCollectionGraphView = {
  type: typeof GET_DAILY_MILK_COLLECTION_GRAPH_VIEW;
  payload: IDailyMilkCollectionGraphView[];
};

export type GetMonthlyMilkCollectionGraphView = {
  type: typeof GET_MONTHLY_MILK_COLLECTION_GRAPH_VIEW;
  payload: IMonthlyMilkCollectionGraphView[];
};

export type GetYearlyTopTenCustomerGraphView = {
  type: typeof GET_YEARLY_TOP_TEN_CUSTOMER_GRAPH_VIEW;
  payload: IYearlyTopTenCustomerGraphView[];
};

export type GetMonthlyTopTenCustomerGraphView = {
  type: typeof GET_MONTHLY_TOP_TEN_CUSTOMER_GRAPH_VIEW;
  payload: IMonthlyTopTenCustomerGraphView[];
};

export type GetTotalCustomerGraphView = {
  type: typeof GET_TOTAL_CUSTOMER_GRAPH_VIEW;
  payload: ITotalCustomerGraphView[];
};

export type DashboardActions =
  | GetDailyMilkCollectionGraphView
  | GetMonthlyMilkCollectionGraphView
  | GetYearlyTopTenCustomerGraphView
  | GetMonthlyTopTenCustomerGraphView
  | GetTotalCustomerGraphView;
