/* eslint-disable prettier/prettier */
import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIResponse,
  APIErrorResProps,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  ILedgerGroups,
  ILedgerGroupDetails,
  ILedgerGroupShort,
  IGetLedgerGroups,
} from "@crema/types/models/dairy/Manage-Ledger/LedgerGroup";
import {
  GET_LEDGER_GROUPS,
  ADD_LEDGER_GROUP,
  INIT_ADD_LEDGER_GROUP,
  EDIT_LEDGER_GROUP,
  GET_LEDGER_GROUP,
  GET_LEDGER_GROUP_LIST,
  INIT_LEDGER_GROUP_FILTER,
  SET_LEDGER_GROUP_FILTER,
  SET_EDIT_LEDGER_GROUP_ID,
} from "@crema/types/actions/dairy/Manage-Ledger/LedgerGroup.action";

export const getLedgerGroups =
  (filter: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/ledger-group", { params: filter })
      .then((response: APIResponse<IGetLedgerGroups>) => {
        handleApiResponse(dispatch, response, GET_LEDGER_GROUPS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const createOrUpdateLedgerGroup =
  (method: "post" | "put", data: ILedgerGroupDetails, id?: string | number) =>
    (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      jwtAxios[method](`/ledger-group${id ? `/${id}` : ""}`, data)
        .then((response: APIResponse<ILedgerGroupDetails>) => {
          const actionType =
            method === "post" ? ADD_LEDGER_GROUP : EDIT_LEDGER_GROUP;
          handleApiResponse(dispatch, response, actionType);
          if (method === "post" && response.status === 201) {
            dispatch({ type: INIT_ADD_LEDGER_GROUP });
          }
        })
        .catch((error: APIErrorResProps) => {
          handleErrorResponse(dispatch, error);
        });
    };

export const deleteLedgerGroup =
  (id: string | number, formData) => (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/ledger-group/${id}`, { data: formData })
      .then((response: APIResponse<ILedgerGroupDetails>) => {
        handleApiResponse(dispatch, response, EDIT_LEDGER_GROUP);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const getLedgerGroupById =
  (id: string | number) => (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/ledger-group/${id}`)
      .then((response: APIResponse<ILedgerGroupShort[]>) => {
        handleApiResponse(dispatch, response, GET_LEDGER_GROUP);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const getLedgerGroupList =
  (data: object) =>
    (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      jwtAxios
        .get(`/dropdown-ledger-group`, { params: data })
        .then((response: APIResponse<ILedgerGroupShort[]>) => {
          handleApiResponse(
            dispatch,
            response,
            GET_LEDGER_GROUP_LIST,
          );
        })
        .catch((error: APIErrorResProps) => {
          handleErrorResponse(dispatch, error);
        });
    };

export const initAddLedgerGroup = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_ADD_LEDGER_GROUP });
};

export const initLedgerGroupFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_LEDGER_GROUP_FILTER });
};

export const setLedgerGroupFilter =
  (filter: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_LEDGER_GROUP_FILTER, payload: filter });
  };


export const editLedgerGroup =
  (id: number) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_EDIT_LEDGER_GROUP_ID, payload: id });
  };
