import { IGetCustomerPaymentReport } from "@crema/types/models/dairy/Report/CustomerPayment";

export const GET_CUSTOMER_PAYMENT_REPORT = "GET_CUSTOMER_PAYMENT_REPORT";
export const EXPORT_CUSTOMER_PAYMENT_REPORT = "EXPORT_CUSTOMER_PAYMENT_REPORT";
export const COLUMNS_CUSTOMER_PAYMENT_REPORT =
  "COLUMNS_CUSTOMER_PAYMENT_REPORT";
export const INIT_CUSTOMER_PAYMENT_REPORT_FILTER =
  "INIT_CUSTOMER_PAYMENT_REPORT_FILTER";
export const SET_CUSTOMER_PAYMENT_REPORT_FILTER =
  "SET_CUSTOMER_PAYMENT_REPORT_FILTER";
export const EXPORTED_CUSTOMER_PAYMENT_REPORT =
  "EXPORTED_CUSTOMER_PAYMENT_REPORT";

export type GetCustomerPaymentReportActions = {
  type: typeof GET_CUSTOMER_PAYMENT_REPORT;
  payload: IGetCustomerPaymentReport;
};

export type ColumnsCustomerPaymentReportActions = {
  type: typeof COLUMNS_CUSTOMER_PAYMENT_REPORT;
  payload: string[];
};

export type InitCustomerPaymentReportFilter = {
  type: typeof INIT_CUSTOMER_PAYMENT_REPORT_FILTER;
};

export type SetCustomerPaymentReportFilter = {
  type: typeof SET_CUSTOMER_PAYMENT_REPORT_FILTER;
  payload: object;
};
export type ExportedCustomerPaymentReportAction = {
  type: typeof EXPORTED_CUSTOMER_PAYMENT_REPORT;
};

export type CustomerPaymentReportActions =
  | GetCustomerPaymentReportActions
  | ColumnsCustomerPaymentReportActions
  | InitCustomerPaymentReportFilter
  | SetCustomerPaymentReportFilter
  | ExportedCustomerPaymentReportAction;
