import { IChartList } from "@crema/types/models/dairy/Manage-Chart/Chart";
import { IClusterList } from "@crema/types/models/dairy/Manage-Cluster/Cluster";
import {
  IClusterMilkDetails,
  IClusterMilkList,
  rate,
} from "@crema/types/models/dairy/Manage-Cluster/ClusterMilk";

export const GET_CLUSTER_MILKS = "GET_CLUSTER_MILKS";
export const INIT_ADD_CLUSTER_MILK = "INIT_ADD_CLUSTER_MILK";
export const ADD_CLUSTER_MILK = "ADD_CLUSTER_MILK";
export const EDIT_CLUSTER_MILK = "EDIT_CLUSTER_MILK";
export const DELETE_CLUSTER_MILK = "DELETE_CLUSTER_MILK";
export const GET_CLUSTER_MILK = "GET_CLUSTER_MILK";
export const GET_CLUSTER_MILK_LIST = "GET_CLUSTER_MILK_LIST";
export const INIT_CLUSTER_MILKS_FILTER = "INIT_CLUSTER_MILKS_FILTER";
export const SET_CLUSTER_MILKS_FILTER = "SET_CLUSTER_MILKS_FILTER";
export const GET_CLUSTERS_FOR_CLUSTER_MILK = "GET_CLUSTERS_FOR_CLUSTER_MILK";
export const GET_CHARTS_FOR_CLUSTER_MILK = "GET_CHARTS_FOR_CLUSTER_MILK";
export const SET_CLUSTER_MILK_RATE = "SET_CLUSTER_MILK_RATE";
export const SET_EDIT_CLUSTER_MILK_ID = "SET_EDIT_CLUSTER_MILK_ID";
export const EXPORT_CLUSTER_MILKS = "EXPORT_CLUSTER_MILKS";
export const COLUMNS_CLUSTER_MILKS = "COLUMNS_CLUSTER_MILKS";
export const EXPORTED_CLUSTER_MILKS = "EXPORTED_CLUSTER_MILKS";

export type GetClusterMilksActions = {
  type: typeof GET_CLUSTER_MILKS;
  payload: IClusterMilkList[];
};

export type GetClusterForClusterMilksActions = {
  type: typeof GET_CLUSTERS_FOR_CLUSTER_MILK;
  payload: IClusterList[];
};

export type GetChartForClusterMilksActions = {
  type: typeof GET_CHARTS_FOR_CLUSTER_MILK;
  payload: IChartList[];
};

export type InitAddClusterMilkActions = {
  type: typeof INIT_ADD_CLUSTER_MILK;
};

export type AddClusterMilkActions = {
  type: typeof ADD_CLUSTER_MILK;
  payload: IClusterMilkDetails;
};

export type EditClusterMilkActions = {
  type: typeof EDIT_CLUSTER_MILK;
  payload: IClusterMilkDetails;
};

export type GetClusterMilkActions = {
  type: typeof GET_CLUSTER_MILK;
  payload: IClusterMilkDetails;
};

export type DeleteClusterMilkActions = {
  type: typeof DELETE_CLUSTER_MILK;
  payload: IClusterMilkList;
};

export type InitClusterMilksFilter = {
  type: typeof INIT_CLUSTER_MILKS_FILTER;
};

export type SetClusterMilksFilter = {
  type: typeof SET_CLUSTER_MILKS_FILTER;
  payload: object;
};
export type SetClusterMilkRate = {
  type: typeof SET_CLUSTER_MILK_RATE;
  payload: rate;
};
export type SetClusterMilkIdForEditActions = {
  type: typeof SET_EDIT_CLUSTER_MILK_ID;
  payload: number;
};
export type ExportedClusterMilksAction = {
  type: typeof EXPORTED_CLUSTER_MILKS;
};

export type ClusterMilkActions =
  | GetClusterMilksActions
  | InitAddClusterMilkActions
  | AddClusterMilkActions
  | EditClusterMilkActions
  | DeleteClusterMilkActions
  | GetClusterMilkActions
  | InitClusterMilksFilter
  | SetClusterMilksFilter
  | GetClusterForClusterMilksActions
  | GetChartForClusterMilksActions
  | SetClusterMilkRate
  | SetClusterMilkIdForEditActions
  | ExportedClusterMilksAction;
