import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IProductBrandList,
  IProductBrandDetails,
  IGetProductBrand,
} from "@crema/types/models/dairy/Manage-Product/Product-Brand/Product-Brand";
import {
  ADD_PRODUCT_BRAND,
  DELETE_PRODUCT_BRAND,
  EDIT_PRODUCT_BRAND,
  GET_PRODUCT_BRAND,
  GET_PRODUCT_BRANDS,
  INIT_ADD_PRODUCT_BRAND,
  SET_EDIT_PRODUCT_BRAND_ID,
} from "@crema/types/actions/dairy/Manage-Product/Product-Brand/Product-Brand";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getProductBrands = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("manage-product/product-brand", { params: filter })
      .then((response: APIResponse<IGetProductBrand>) => {
        handleApiResponse(dispatch, response, GET_PRODUCT_BRANDS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateProductBrand = (
  method: "post" | "put",
  data: IProductBrandDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/manage-product/product-brand${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IProductBrandDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_PRODUCT_BRAND : EDIT_PRODUCT_BRAND,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteProductBrand = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/manage-product/product-brand/${id}`, data)
      .then((response: APIResponse<IProductBrandList>) => {
        handleApiResponse(dispatch, response, DELETE_PRODUCT_BRAND);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getProductBrandById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-product/product-brand/${id}`)
      .then((response: APIResponse<IProductBrandDetails>) => {
        handleApiResponse(dispatch, response, GET_PRODUCT_BRAND);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const initAddProductBrand = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_PRODUCT_BRAND });
  };
};

export const editProductBrand =
  (id: number) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_EDIT_PRODUCT_BRAND_ID, payload: id });
  };
