import { IChartList } from "@crema/types/models/dairy/Manage-Chart/Chart";
import { ICustomerList } from "@crema/types/models/dairy/Customer";
import { IMilkDetails, IMilkList, rate } from "@crema/types/models/dairy/Milk";

export const GET_MILKS = "GET_MILKS";
export const INIT_ADD_MILK = "INIT_ADD_MILK";
export const ADD_MILK = "ADD_MILK";
export const EDIT_MILK = "EDIT_MILK";
export const DELETE_MILK = "DELETE_MILK";
export const GET_MILK = "GET_MILK";
export const GET_MILK_LIST = "GET_MILK_LIST";
export const INIT_MILKS_FILTER = "INIT_MILKS_FILTER";
export const SET_MILKS_FILTER = "SET_MILKS_FILTER";
export const GET_CUSTOMERS_FOR_MILK = "GET_CUSTOMERS_FOR_MILK";
export const GET_CHARTS_FOR_MILK = "GET_CHARTS_FOR_MILK";
export const SET_MILK_RATE = "SET_MILK_RATE";
export const SET_EDIT_MILK_ID = "SET_EDIT_MILK_ID";

export type GetMilksActions = {
  type: typeof GET_MILKS;
  payload: IMilkList[];
};

export type GetCustomerForMilksActions = {
  type: typeof GET_MILKS;
  payload: ICustomerList[];
};

export type GetChartForMilksActions = {
  type: typeof GET_CHARTS_FOR_MILK;
  payload: IChartList[];
};

export type InitAddMilkActions = {
  type: typeof INIT_ADD_MILK;
};

export type AddMilkActions = {
  type: typeof ADD_MILK;
  payload: IMilkDetails;
};

export type EditMilkActions = {
  type: typeof EDIT_MILK;
  payload: IMilkDetails;
};

export type GetMilkActions = {
  type: typeof GET_MILK;
  payload: IMilkDetails;
};

export type DeleteMilkActions = {
  type: typeof DELETE_MILK;
  payload: IMilkList;
};

export type InitMilksFilter = {
  type: typeof INIT_MILKS_FILTER;
};

export type SetMilksFilter = {
  type: typeof SET_MILKS_FILTER;
  payload: object;
};
export type SetMilkRate = {
  type: typeof SET_MILK_RATE;
  payload: rate;
};
export type SetMilkIdForEditActions = {
  type: typeof SET_EDIT_MILK_ID;
  payload: number;
};
export type MilkActions =
  | GetMilksActions
  | InitAddMilkActions
  | AddMilkActions
  | EditMilkActions
  | DeleteMilkActions
  | GetMilkActions
  | InitMilksFilter
  | SetMilksFilter
  | GetCustomerForMilksActions
  | GetChartForMilksActions
  | SetMilkRate
  | SetMilkIdForEditActions;
