import {
  GET_CUSTOMER_PRODUCTS,
  INIT_ADD_CUSTOMER_PRODUCT,
  ADD_CUSTOMER_PRODUCT,
  EDIT_CUSTOMER_PRODUCT,
  DELETE_CUSTOMER_PRODUCT,
  GET_CUSTOMER_PRODUCT,
  GET_CUSTOMER_PRODUCT_LIST,
  INIT_CUSTOMER_PRODUCTS_FILTER,
  SET_CUSTOMER_PRODUCTS_FILTER,
  SET_EDIT_CUSTOMER_PRODUCT_ID,
} from "@crema/types/actions/dairy/Manage-Customer/CustomerProducts/CustomerProducts";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  ICustomerProductDetails,
  ICustomerProductList,
  ICustomerProductListPaginationType,
  ICustomerProductShort,
  IGetCustomerProduct,
} from "@crema/types/models/dairy/Manage-Customer/CustomerProducts/CustomerProducts";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialCustomerProductState = {
  customerProducts: [] as ICustomerProductList[],
  pagination: null as ICustomerProductListPaginationType | null,
  customerProduct: null as ICustomerProductDetails | null,
  details: null as ICustomerProductDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  customerProductList: [] as ICustomerProductShort[],
  customerProductsFilter: {} as object | ICustomerProductList,
  toggleToReload: false as boolean,
  customerProductId: 0 as number,
};

export const getCustomerProductsAction = createAction<IGetCustomerProduct>(
  GET_CUSTOMER_PRODUCTS,
);
export const initAddCustomerProductAction = createAction(
  INIT_ADD_CUSTOMER_PRODUCT,
);
export const addCustomerProductAction =
  createAction<ICustomerProductDetails>(ADD_CUSTOMER_PRODUCT);
export const editCustomerProductAction = createAction(EDIT_CUSTOMER_PRODUCT);
export const deleteCustomerProductAction = createAction<ICustomerProductList>(
  DELETE_CUSTOMER_PRODUCT,
);
export const getCustomerProductAction =
  createAction<ICustomerProductDetails>(GET_CUSTOMER_PRODUCT);
export const getCustomerProductListByBranchIdAction = createAction<
  ICustomerProductShort[]
>(GET_CUSTOMER_PRODUCT_LIST);
export const initCustomerProductsFilter = createAction(
  INIT_CUSTOMER_PRODUCTS_FILTER,
);
export const setCustomerProductsFilter = createAction<object>(
  SET_CUSTOMER_PRODUCTS_FILTER,
);
export const setEditCustomerProductId = createAction<number>(
  SET_EDIT_CUSTOMER_PRODUCT_ID,
);

const customerProductReducer = createReducer(
  initialCustomerProductState,
  (builder) => {
    builder
      .addCase(getCustomerProductsAction, (state, action) => {
        state.customerProducts = action.payload.product;
        state.pagination = {
          total: action.payload.pagination.totalCount,
          from: action.payload.pagination.pageNumber,
          perPage: Number(action.payload.pagination.pageSize),
          totalRecords: Number(action.payload.pagination.totalRecords),
          totalWeight: Number(action.payload.pagination.totalWeight),
          totalAmount: Number(action.payload.pagination.totalAmount),
        };
      })
      .addCase(initAddCustomerProductAction, (state) => {
        state.details = null;
        state.validationError = null;
        state.success = false;
      })
      .addCase(addCustomerProductAction, (state, action) => {
        state.success = true;
        state.toggleToReload = !state.toggleToReload;
      })
      .addCase(editCustomerProductAction, (state, action) => {
        state.success = true;
        state.toggleToReload = !state.toggleToReload;
      })
      .addCase(deleteCustomerProductAction, (state, action) => {
        state.customerProducts = state.customerProducts.filter(
          (customerProduct) => customerProduct.id !== action.payload.id,
        );
      })
      .addCase(getCustomerProductAction, (state, action) => {
        state.details = action.payload;
      })
      .addCase(getCustomerProductListByBranchIdAction, (state, action) => {
        state.customerProductList = action.payload;
      })
      .addCase(initCustomerProductsFilter, (state) => {
        state.customerProductsFilter = {};
      })
      .addCase(setCustomerProductsFilter, (state, action) => {
        state.customerProductsFilter = action.payload;
      })
      .addCase(setEditCustomerProductId, (state, action) => {
        state.customerProductId = action.payload;
      });
  },
);

export default customerProductReducer;
