import { LanguageProps } from "@crema/types/models/Apps";

const languageData: LanguageProps[] = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
  },
  {
    languageId: "hindi",
    locale: "hi",
    name: "हिंदी",
  },
];
export default languageData;
