import { ApiSuccessResponse } from "@crema/types/models/APIDataProps";
import { IFixedChartList } from "@crema/types/models/dairy/Manage-Chart/FixedChart";

export const GET_FIXED_CHARTS = "GET_FIXED_CHARTS";
export const GET_FIXED_CHART = "GET_FIXED_CHART";
export const EDIT_FIXED_CHART_DETAILS = "EDIT_FIXED_CHART_DETAILS";
export const INIT_ADD_FIXED_CHART = "INIT_ADD_FIXED_CHART";

export type GetFixedChartsActions = {
  type: typeof GET_FIXED_CHARTS;
  payload: IFixedChartList[];
};
export type GetProductActions = {
  type: typeof GET_FIXED_CHART;
  payload: IFixedChartList;
};
export type InitAddFixedChartActions = {
  type: typeof INIT_ADD_FIXED_CHART;
};
export type EditFixedChartDetailsActions = {
  type: typeof EDIT_FIXED_CHART_DETAILS;
  payload: ApiSuccessResponse;
};

export type FixedChartActions =
  | GetFixedChartsActions
  | InitAddFixedChartActions;
