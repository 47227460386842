import {
  ILedgerGroups,
  ILedgerGroupDetails,
  ILedgerGroupShort,
  IGetLedgerGroups,
} from "@crema/types/models/dairy/Manage-Ledger/LedgerGroup";
import {
  GET_LEDGER_GROUPS,
  GET_LEDGER_GROUP,
  ADD_LEDGER_GROUP,
  INIT_ADD_LEDGER_GROUP,
  EDIT_LEDGER_GROUP,
  GET_LEDGER_GROUP_LIST,
  INIT_LEDGER_GROUP_FILTER,
  SET_LEDGER_GROUP_FILTER,
  SET_EDIT_LEDGER_GROUP_ID,
} from "@crema/types/actions/dairy/Manage-Ledger/LedgerGroup.action";
import {
  PaginationType,
  ValidationError,
} from "@crema/types/models/APIDataProps";
import { createAction, createReducer } from "@reduxjs/toolkit";

export const initLedgerGroupFilter = createAction(INIT_LEDGER_GROUP_FILTER);
export const setLedgerGroupFilter = createAction<object>(
  SET_LEDGER_GROUP_FILTER,
);
export const getLedgerGroupsAction =
  createAction<IGetLedgerGroups>(GET_LEDGER_GROUPS);
export const getLedgerGroupAction =
  createAction<ILedgerGroupDetails>(GET_LEDGER_GROUP);
export const initAddLedgerGroupAction = createAction(INIT_ADD_LEDGER_GROUP);
export const addLedgerGroupAction =
  createAction<ILedgerGroupDetails>(ADD_LEDGER_GROUP);
export const editLedgerGroupAction =
  createAction<ILedgerGroupDetails>(EDIT_LEDGER_GROUP);
export const getLedgerGroupList = createAction<ILedgerGroupShort[]>(
  GET_LEDGER_GROUP_LIST,
);
export const setEditLedgerGroupId = createAction<number>(
  SET_EDIT_LEDGER_GROUP_ID,
);

const initialState = {
  ledgerGroups: [] as ILedgerGroups[],
  ledgerGroupList: [] as ILedgerGroupShort[],
  ledgerGroup: null as ILedgerGroupDetails,
  pagination: null as PaginationType | null,
  details: null as ILedgerGroupDetails,
  validationError: null as ValidationError,
  success: false as boolean,
  ledgerGroupFilter: {} as object,
  toggleReload: false as boolean,
  ledgerGroupId: 0 as number,
};

const ledgerGroupReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getLedgerGroupsAction, (state, action) => {
      state.ledgerGroups = action.payload.ledgerGroup;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: action.payload.pagination.pageSize,
      };
    })
    .addCase(initAddLedgerGroupAction, (state, action) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addLedgerGroupAction, (state, action) => {
      state.toggleReload = !state.toggleReload;
    })
    .addCase(editLedgerGroupAction, (state, action) => {
      state.toggleReload = !state.toggleReload;
      state.ledgerGroupId = 0;
    })
    .addCase(getLedgerGroupAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getLedgerGroupList, (state, action) => {
      state.ledgerGroupList = action.payload;
    })
    .addCase(initLedgerGroupFilter, (state, action) => {
      state.ledgerGroupFilter = {};
    })
    .addCase(setLedgerGroupFilter, (state, action) => {
      state.ledgerGroupFilter = action.payload;
    })
    .addCase(setEditLedgerGroupId, (state, action) => {
      state.ledgerGroupId = action.payload;
    });
});

export default ledgerGroupReducer;
