import {
  GET_RECEIPT_PAYMENTS,
  INIT_ADD_RECEIPT_PAYMENT,
  ADD_RECEIPT_PAYMENT,
  EDIT_RECEIPT_PAYMENT,
  DELETE_RECEIPT_PAYMENT,
  GET_RECEIPT_PAYMENT,
  GET_RECEIPT_PAYMENT_LIST,
  INIT_RECEIPT_PAYMENT_FILTER,
  SET_RECEIPT_PAYMENT_FILTER,
} from "@crema/types/actions/dairy/Receipt-Payment/receiptPayment.Action";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IReceiptPaymentFooter,
  IReceiptPaymentList,
} from "@crema/types/models/dairy/Receipt-Payment/receiptPayment";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialReportState = {
  receiptPayment: [] as IReceiptPaymentList[],
  details: null as IReceiptPaymentList | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  customerList: [] as IReceiptPaymentList[],
  receiptPaymentList: [] as IReceiptPaymentList[],
  receiptPaymentFilter: {
    pageNumber: 0,
    pageSize: 10,
  } as object,
  pagination: [] as any,
  receiptPaymentFooter: null as IReceiptPaymentFooter,
};

export const getReceiptPaymentAction = createAction<any>(GET_RECEIPT_PAYMENT);
export const getReceiptPaymentsAction = createAction<any>(GET_RECEIPT_PAYMENTS);
export const initAddReceiptPaymentAction = createAction<any>(
  INIT_ADD_RECEIPT_PAYMENT,
);
export const addReceiptPaymentAction = createAction<any>(ADD_RECEIPT_PAYMENT);
export const editReceiptPaymentAction = createAction<any>(EDIT_RECEIPT_PAYMENT);
export const InitReceiptPaymentFilterAction = createAction(
  INIT_RECEIPT_PAYMENT_FILTER,
);
export const SetReceiptPaymentFilterAction = createAction(
  SET_RECEIPT_PAYMENT_FILTER,
);
export const deleteReceiptPaymentAction = createAction<any>(
  DELETE_RECEIPT_PAYMENT,
);
export const getReceiptPaymentListByBranchIdAction = createAction<any>(
  GET_RECEIPT_PAYMENT_LIST,
);

const receiptPaymentReducer = createReducer(initialReportState, (builder) => {
  builder
    .addCase(getReceiptPaymentsAction, (state, action) => {
      state.receiptPayment = action.payload.receiptPayment;
      state.pagination = {
        ...action.payload.pagination,
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: action.payload.pagination.pageSize,
      };
      state.receiptPaymentFooter = action.payload.pagination;
    })
    .addCase(initAddReceiptPaymentAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addReceiptPaymentAction, (state, action) => {
      state.details = action.payload;
      state.success = true;
    })
    .addCase(editReceiptPaymentAction, (state, action) => {
      state.details = action.payload;
      state.receiptPayment = state.receiptPayment.map((receiptPayment) => {
        if (receiptPayment.id === action.payload.id) {
          return action.payload;
        }
        return receiptPayment;
      });
      state.success = true;
    })
    .addCase(getReceiptPaymentAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(InitReceiptPaymentFilterAction, (state) => {
      state.receiptPaymentFilter = {
        pageNumber: 0,
        pageSize: 10,
      };
    })
    .addCase(SetReceiptPaymentFilterAction, (state, action) => {
      state.receiptPaymentFilter = action.payload;
    })
    .addCase(deleteReceiptPaymentAction, (state, action) => {
      state.receiptPayment = state.receiptPayment.filter(
        (receiptPayment) => receiptPayment.id !== action.payload.id,
      );
    })
    .addCase(getReceiptPaymentListByBranchIdAction, (state, action) => {
      state.receiptPaymentList = action.payload;
    });
});

export default receiptPaymentReducer;
