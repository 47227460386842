import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IProductList,
  IProductDetails,
  IProductShort,
  IGetProduct,
} from "@crema/types/models/dairy/Manage-Product/Product";
import {
  ADD_PRODUCT,
  ADD_STOCK,
  ATTACH_LEDGER,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  INIT_ADD_PRODUCT,
  INIT_PRODUCTS_FILTER,
  SET_PRODUCTS_FILTER,
} from "@crema/types/actions/dairy/Manage-Product/Product";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";

export const getProducts = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("manage-product/product", { params: filter })
      .then((response: APIResponse<IGetProduct>) => {
        handleApiResponse(dispatch, response, GET_PRODUCTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateProduct = (
  method: "post" | "put",
  data: IProductDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/manage-product/product${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IProductDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_PRODUCT : EDIT_PRODUCT,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteProduct = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/manage-product/product/${id}`, data)
      .then((response: APIResponse<IProductList>) => {
        handleApiResponse(dispatch, response, DELETE_PRODUCT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getProductById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/manage-product/product/${id}`)
      .then((response: APIResponse<IProductDetails>) => {
        handleApiResponse(dispatch, response, GET_PRODUCT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const initAddProduct = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_PRODUCT });
  };
};

export const initProductsFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_PRODUCTS_FILTER });
};

export const setProductsFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_PRODUCTS_FILTER, payload: formData });
  };

export const attachLedger = (
  productId: string | number,
  ledgerId: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/manage-product/ledger-product/attach`, { ledgerId, productId })
      .then((response: APIResponse<IProductDetails>) => {
        handleApiResponse(dispatch, response, ATTACH_LEDGER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const addStock = (productId: string | number, stock: number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`manage-product/product/${productId}/add-stock`, { stock })
      .then((response: APIResponse<IProductDetails>) => {
        handleApiResponse(dispatch, response, ADD_STOCK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
