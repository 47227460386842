import React from "react";

const Add = React.lazy(() => import("./add"));
const List = React.lazy(() => import("./list"));

export const billComponentRouteConfigs = [
  {
    path: "/bills/add",
    element: <Add />,
  },
  {
    path: "/bills",
    element: <List />,
  },
];
