import React from "react";

const List = React.lazy(() => import("./list"));

export const productBrandComponentRouteConfigs = [
  {
    path: "/manage-product/product-brand",
    element: <List />,
  },
];
