import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIErrorResProps,
  APIResponse,
  IExportFile,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  ICustomerMilkList,
  ICustomerMilkDetails,
  IGetCustomerMilk,
} from "@crema/types/models/dairy/Report/CustomerMilk";
import {
  ADD_CUSTOMER_MILK,
  COLUMNS_CUSTOMER_MILKS,
  DELETE_CUSTOMER_MILK,
  EDIT_CUSTOMER_MILK,
  EXPORTED_CUSTOMER_MILKS,
  EXPORT_CUSTOMER_MILKS,
  GET_CUSTOMER_MILK,
  GET_CUSTOMER_MILKS,
  INIT_ADD_CUSTOMER_MILK,
  INIT_CUSTOMER_MILKS_FILTER,
  SET_CUSTOMER_MILKS_FILTER,
  SET_EDIT_CUSTOMER_MILK_ID,
} from "@crema/types/actions/dairy/Report/CustomerMilk";

export const getCustomerMilks = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer-milk-report", { params: filter })
      .then((response: APIResponse<IGetCustomerMilk>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER_MILKS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const exportCustomerMilk = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer-milk-report/export", { params: filter })
      .then((response: APIResponse<IExportFile>) => {
        handleApiResponse(dispatch, response, EXPORT_CUSTOMER_MILKS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const columnsCustomerMilk = (filter?: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer-milk-report/columns", { params: filter })
      .then((response: APIResponse<IGetCustomerMilk>) => {
        handleApiResponse(dispatch, response, COLUMNS_CUSTOMER_MILKS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateCustomerMilk = (
  method: "post" | "put",
  data: ICustomerMilkDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/customer-milk-report${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<ICustomerMilkDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_CUSTOMER_MILK : EDIT_CUSTOMER_MILK,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteCustomerMilk = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`/customer-milk-report${id}`, data)
      .then((response: APIResponse<ICustomerMilkList>) => {
        handleApiResponse(dispatch, response, DELETE_CUSTOMER_MILK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getCustomerMilkById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/customer-milk-report${id}`)
      .then((response: APIResponse<ICustomerMilkDetails>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER_MILK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddCustomerMilk = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_CUSTOMER_MILK });
  };
};

export const initCustomerMilksFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_CUSTOMER_MILKS_FILTER });
  };

export const editCustomerMilk =
  (id: number) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_EDIT_CUSTOMER_MILK_ID, payload: id });
  };

export const setCustomerMilksFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CUSTOMER_MILKS_FILTER, payload: formData });
  };

export const exportedCustomerMilks = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: EXPORTED_CUSTOMER_MILKS });
  };
};
