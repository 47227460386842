import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthUserType } from "@crema/types/models/AuthUser";
import jwtAxios, { setAuthToken, setUserProfile } from "./index";
import {
  clearLocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from "@crema/utility/LocalStorage";
import { useAppDispatch } from "toolkit/hooks";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  validationError,
} from "toolkit/actions";
import { appIntl } from "@crema/helpers/Common";

interface JWTAuthContextProps {
  user: AuthUserType | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignInProps {
  mobile: string;
  password: string;
}

interface ProxyProps {
  userId: number;
}

interface SignUpProps {
  firstName: string;
  lastName: string;
  mobile: string;
  password: string;
  password_confirmation: string;
}

interface JWTAuthActionsProps {
  signInUser: (data: SignInProps) => void;
  signUpUser: (data: SignUpProps) => void;
  proxy: (data: ProxyProps) => void;
  proxyBackToAdmin: () => void;
  logout: () => void;
  setJWTAuthData?: Dispatch<SetStateAction<Partial<JWTAuthContextProps>>>;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signInUser: () => {},
  logout: () => {},
  signUpUser: () => {},
  proxy: () => {},
  proxyBackToAdmin: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [jwtAuthData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getAuthUser = () => {
      const token = getLocalStorageItem("token");
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      const userProfile = getLocalStorageItem("userProfile");
      if (!userProfile) {
        jwtAxios
          .get("profile")
          .then(function (res) {
            setUserProfile(res.data.data);
            setJWTAuthData({
              user: res.data.data,
              isLoading: false,
              isAuthenticated: true,
            });
          })
          .catch(function () {
            setJWTAuthData({
              user: undefined,
              isLoading: false,
              isAuthenticated: false,
            });
          });
        return;
      }
      setJWTAuthData({
        user: JSON.parse(userProfile),
        isLoading: false,
        isAuthenticated: true,
      });
    };
    getAuthUser();
  }, []);

  const signInUser = async ({
    mobile,
    password,
  }: {
    mobile: string;
    password: string;
  }) => {
    dispatch(fetchStart());
    // infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post("getToken", { mobile, password });
      setAuthToken(data.data.accessToken);
      const profile = await jwtAxios.get("profile");
      setUserProfile(profile.data.data);

      if (profile.data.data?.currentRole.id == 1) {
        setLocalStorageItem("adminToken", data.data.accessToken);
        setLocalStorageItem("adminProfile", profile.data.data);
      }
      setJWTAuthData({
        user: profile.data.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const proxy = async ({ userId }: ProxyProps) => {
    dispatch(fetchStart());
    // infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.get(`admin-api/proxyUser/${userId}`);
      setAuthToken(data.data.accessToken);
      const profile = await jwtAxios.get("profile");
      setUserProfile(profile.data.data);
      setJWTAuthData({
        user: profile.data.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const proxyBackToAdmin = async () => {
    dispatch(fetchStart());
    try {
      const adminToken = getLocalStorageItem("adminToken");
      const adminProfile = getLocalStorageItem("adminProfile");
      setAuthToken(adminToken);
      setUserProfile(adminProfile);
      setJWTAuthData({
        user: adminProfile,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const signUpUser = async ({
    firstName,
    lastName,
    mobile,
    password,
    password_confirmation,
  }: {
    firstName: string;
    lastName: string;
    mobile: string;
    password: string;
    password_confirmation: string;
  }) => {
    dispatch(fetchStart());
    try {
      const { data } = await jwtAxios.post("register", {
        firstName,
        lastName,
        mobile,
        password,
        password_confirmation,
      });
      localStorage.setItem("token", data.token);
      setAuthToken(data.data.accessToken);
      const profile = await jwtAxios.get("profile");
      setUserProfile(profile.data.data);
      setJWTAuthData({
        user: profile.data.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      const { messages } = appIntl();
      if (error?.response && error?.response?.data.status === 400) {
        dispatch(validationError(error.response.data.error));
      } else {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      }
    }
  };

  const logout = async () => {
    clearLocalStorage();
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
          signUpUser,
          setJWTAuthData,
          proxy,
          proxyBackToAdmin,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
