import {
  backgroundDark,
  backgroundLight,
  textDark,
  textLight,
} from "./defaultConfig";

export type ThemeColorType = {
  mode: string;
  secondary: { main: string };
  background: { default: string; paper: string };
  text: { secondary: string; hint?: string; disabled: string; primary: string };
  title: string;
  primary: { main: string };
};

const themeColorSets: ThemeColorType[] = [
  {
    mode: "light",
    primary: {
      main: "#0A8FDC",
    },
    secondary: {
      main: "#F04F47",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light",
  },
  {
    mode: "light",
    primary: {
      main: "#6E3A4B",
    },
    secondary: {
      main: "#D38C48",
    },
    background: {
      paper: "#FFFFFF",
      default: "#FCF8F5",
    },
    text: textLight,
    title: "Light-2",
  },
  {
    mode: "light",
    primary: {
      main: "#079CE9",
    },
    secondary: {
      main: "#232EC0",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-3",
  },
  {
    mode: "light",
    primary: {
      main: "#1B9E85",
    },
    secondary: {
      main: "#FF916E",
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF",
    },
    text: textLight,
    title: "Light-4",
  },
  {
    mode: "light",
    primary: {
      main: "#905EAE",
    },
    secondary: {
      main: "#2D755A",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-5",
  },
  {
    mode: "light",
    primary: {
      main: "#266BB0",
    },
    secondary: {
      main: "#587527",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-6",
  },
  {
    mode: "light",
    primary: {
      main: "#F0464D",
    },
    secondary: {
      main: "#00B6EF",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-7",
  },
  {
    mode: "light",
    primary: {
      main: "#639F52",
    },
    secondary: {
      main: "#7C4D30",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-8",
  },
  {
    mode: "light",
    primary: {
      main: "#FFD955",
    },
    secondary: {
      main: "#C4476D",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-9",
  },
  {
    mode: "light",
    primary: {
      main: "#2196F2",
    },
    secondary: {
      main: "#FF4981",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-1",
  },

  {
    mode: "light",
    primary: {
      main: "#FD933A",
    },
    secondary: {
      main: "#5A63C8",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-10",
  },
  {
    mode: "light",
    primary: {
      main: "#03A9F4",
    },
    secondary: {
      main: "#FFC107",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-11",
  },
  {
    mode: "light",
    primary: {
      main: "#03A9F4",
    },
    secondary: {
      main: "#FF80AB",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-12",
  },

  {
    mode: "light",
    primary: {
      main: "#3F51B5",
    },
    secondary: {
      main: "#2196F3",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-13",
  },

  {
    mode: "light",
    primary: {
      main: "#9C27B0",
    },
    secondary: {
      main: "#FFCA28",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-14",
  },

  {
    mode: "light",
    primary: {
      main: "#673AB7",
    },
    secondary: {
      main: "#2196F3",
    },
    background: backgroundLight,
    text: textLight,
    title: "Light-15",
  },
  {
    mode: "dark",
    primary: {
      main: "#0A8FDC",
    },
    secondary: {
      main: "#F04F47",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark",
  },
  {
    mode: "dark",
    primary: {
      main: "#0A8FDC",
    },
    secondary: {
      main: "#F04F47",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-1",
  },
  {
    mode: "dark",
    primary: {
      main: "#6E3A4B",
    },
    secondary: {
      main: "#D38C48",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-2",
  },
  {
    mode: "dark",
    primary: {
      main: "#079CE9",
    },
    secondary: {
      main: "#232EC0",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-3",
  },
  {
    mode: "dark",
    primary: {
      main: "#1B9E85",
    },
    secondary: {
      main: "#FF916E",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-4",
  },
  {
    mode: "dark",
    primary: {
      main: "#905EAE",
    },
    secondary: {
      main: "#2D755A",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-5",
  },
  {
    mode: "dark",
    primary: {
      main: "#266BB0",
    },
    secondary: {
      main: "#587527",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-6",
  },
  {
    mode: "dark",
    primary: {
      main: "#F0464D",
    },
    secondary: {
      main: "#00B6EF",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-7",
  },
  {
    mode: "dark",
    primary: {
      main: "#639F52",
    },
    secondary: {
      main: "#7C4D30",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-8",
  },
  {
    mode: "dark",
    primary: {
      main: "#FFD955",
    },
    secondary: {
      main: "#C4476D",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-9",
  },

  {
    mode: "dark",
    primary: {
      main: "#FD933A",
    },
    secondary: {
      main: "#5A63C8",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-10",
  },
  {
    mode: "dark",
    primary: {
      main: "#03A9F4",
    },
    secondary: {
      main: "#FFC107",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-11",
  },
  {
    mode: "dark",
    primary: {
      main: "#03A9F4",
    },
    secondary: {
      main: "#FF80AB",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-12",
  },

  {
    mode: "dark",
    primary: {
      main: "#3F51B5",
    },
    secondary: {
      main: "#2196F3",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-13",
  },

  {
    mode: "dark",
    primary: {
      main: "#9C27B0",
    },
    secondary: {
      main: "#FFCA28",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-14",
  },

  {
    mode: "dark",
    primary: {
      main: "#673AB7",
    },
    secondary: {
      main: "#2196F3",
    },
    background: backgroundDark,
    text: textDark,
    title: "Dark-15",
  },
];
export default themeColorSets;

// const colorSet = [
//   {
//     PrimaryColor: '#0A8FDC',
//     SecondaryColor: '#F04F47',
//     SidebarColor: '#313541',
//   },
//   {
//     PrimaryColor: '#905EAE',
//     SecondaryColor: '#2D755A',
//     SidebarColor: '#313541',
//   },
//   {
//     PrimaryColor: '#266BB0',
//     SecondaryColor: '#587527',
//     SidebarColor: '#313541',
//   },
//   {
//     PrimaryColor: '#F0464D',
//     SecondaryColor: '#00B6EF',
//     SidebarColor: '#313541',
//   },
//   {
//     PrimaryColor: '#639F52',
//     SecondaryColor: '#7C4D30',
//     SidebarColor: '#313541',
//   },
//   {
//     PrimaryColor: '#FFD955',
//     SecondaryColor: '#C4476D',
//     SidebarColor: '#313541',
//   },

//   {
//     PrimaryColor: '#FD933A',
//     SecondaryColor: '#5A63C8',
//     SidebarColor: '#313541',
//   },
//   {
//     PrimaryColor: '#03A9F4',
//     SecondaryColor: '#FFC107',
//     SidebarColor: '#313541',
//   },
//   {
//     PrimaryColor: '#03A9F4',
//     SecondaryColor: '#FF80AB',
//     SidebarColor: '#313541',
//   },

//   {
//     PrimaryColor: '#3F51B5',
//     SecondaryColor: '#2196F3',
//     SidebarColor: '#FFC107',
//   },

//   {
//     PrimaryColor: '#9C27B0',
//     SecondaryColor: '#FFCA28',
//     SidebarColor: '#313541',
//   },

//   {
//     PrimaryColor: '#673AB7',
//     SecondaryColor: '#2196F3',
//     SidebarColor: '#313541',
//   },
// ];
// export default colorSet;
