import { IExportFile } from "@crema/types/models/APIDataProps";
import {
  IClusterMilkReportList,
  IClusterMilkReportColumns,
} from "@crema/types/models/dairy/Report/ClusterMilkReport";

export const GET_CLUSTER_MILK_REPORTS = "GET_CLUSTER_MILK_REPORTS";
export const INIT_CLUSTER_MILK_REPORTS_FILTER =
  "INIT_CLUSTER_MILK_REPORTS_FILTER";
export const SET_CLUSTER_MILK_REPORTS_FILTER =
  "SET_CLUSTER_MILK_REPORTS_FILTER";
export const EXPORT_CLUSTER_MILK_REPORT = "EXPORT_CLUSTER_MILK_REPORT";
export const GET_CLUSTER_MILK_REPORT_COLUMNS =
  "GET_CLUSTER_MILK_REPORT_COLUMNS";
export const EXPORTED_CLUSTER_MILK_REPORT = "EXPORTED_CLUSTER_MILK_REPORT";

export type GetClusterMilkReportsActions = {
  type: typeof GET_CLUSTER_MILK_REPORTS;
  payload: IClusterMilkReportList[];
};

export type InitClusterMilkReportsFilterActions = {
  type: typeof INIT_CLUSTER_MILK_REPORTS_FILTER;
};

export type SetClusterMilkReportsFilterActions = {
  type: typeof SET_CLUSTER_MILK_REPORTS_FILTER;
  payload: object;
};

export type ExportClusterMilkReportAction = {
  type: typeof EXPORT_CLUSTER_MILK_REPORT;
  payload: IExportFile;
};

export type GetClusterMilkReportColumnsAction = {
  type: typeof GET_CLUSTER_MILK_REPORT_COLUMNS;
  payload: IClusterMilkReportColumns;
};

export type ExportedClusterMilkReportAction = {
  type: typeof EXPORTED_CLUSTER_MILK_REPORT;
};

export type ClusterMilkReportActions =
  | GetClusterMilkReportsActions
  | InitClusterMilkReportsFilterActions
  | SetClusterMilkReportsFilterActions
  | ExportClusterMilkReportAction
  | GetClusterMilkReportColumnsAction
  | ExportedClusterMilkReportAction;
