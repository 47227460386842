import { RoutePermission } from "@crema/constants/AppConst";
import React from "react";

const List = React.lazy(() => import("./list"));
const Add = React.lazy(() => import("./add"));
const Edit = React.lazy(() => import("./edit"));
const View = React.lazy(() => import("./view"));

export const clusterComponentRouteConfigs = [
  {
    path: "/clusters",
    element: <List />,
    permission: RoutePermission.dairy.cluster.list,
  },
  {
    path: "/clusters/add",
    element: <Add />,
    permission: RoutePermission.dairy.cluster.create,
  },
  {
    path: "/clusters/edit/:id",
    element: <Edit />,
    permission: RoutePermission.dairy.cluster.update,
  },
  {
    path: "/clusters/view/:id",
    element: <View />,
    permission: RoutePermission.dairy.cluster.single,
  },
];
