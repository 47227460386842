import {
  IProductUnitDetails,
  IProductUnitList,
  IProductUnitShort,
} from "@crema/types/models/dairy/Manage-Product/Product-Units/Product-Units";

export const GET_PRODUCT_UNITS = "GET_PRODUCT_UNITS";
export const GET_PRODUCT_UNIT = "GET_PRODUCT_UNIT";

export type GetProductUnitsActions = {
  type: typeof GET_PRODUCT_UNITS;
  payload: IProductUnitList[];
};

export type GetProductUnitActions = {
  type: typeof GET_PRODUCT_UNIT;
  payload: IProductUnitDetails;
};

export type ProductUnitActions = GetProductUnitsActions | GetProductUnitActions;
