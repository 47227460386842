import React from "react";

const List = React.lazy(() => import("./list"));
const View = React.lazy(() => import("./view"));

export const companyComponentRouteConfigs = [
  {
    path: "/customer/company",
    element: <List />,
  },
  {
    path: "/customer/company/view/:id",
    element: <View />,
  },
];
