import {
  GET_PRODUCT_GROUPS,
  INIT_ADD_PRODUCT_GROUP,
  ADD_PRODUCT_GROUP,
  EDIT_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  GET_PRODUCT_GROUP,
  GET_PRODUCT_GROUP_LIST,
  INIT_PRODUCT_GROUPS_FILTER,
  SET_PRODUCT_GROUPS_FILTER,
  SET_EDIT_PRODUCT_GROUP_ID,
} from "@crema/types/actions/dairy/Manage-Product/Product-Group/Product-Group";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IProductGroupDetails,
  IProductGroupList,
  IProductGroupShort,
  IGetProductGroup,
} from "@crema/types/models/dairy/Manage-Product/Product-Group/Product-Group";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialProductGroupState = {
  productGroups: [] as IProductGroupList[],
  pagination: null as PaginationType | null,
  productGroup: null as IProductGroupDetails | null,
  details: null as IProductGroupDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  productGroupList: [] as IProductGroupShort[],
  productGroupsFilter: {} as object | IProductGroupList,
  toggleReload: false as boolean,
  productGroupId: 0 as number,
};

export const getProductGroupsAction =
  createAction<IGetProductGroup>(GET_PRODUCT_GROUPS);
export const initAddProductGroupAction = createAction(INIT_ADD_PRODUCT_GROUP);
export const addProductGroupAction =
  createAction<IProductGroupDetails>(ADD_PRODUCT_GROUP);
export const editProductGroupAction = createAction(EDIT_PRODUCT_GROUP);
export const deleteProductGroupAction =
  createAction<IProductGroupList>(DELETE_PRODUCT_GROUP);
export const getProductGroupAction =
  createAction<IProductGroupDetails>(GET_PRODUCT_GROUP);
export const getProductGroupListByBranchIdAction = createAction<
  IProductGroupShort[]
>(GET_PRODUCT_GROUP_LIST);
export const initProductGroupsFilter = createAction(INIT_PRODUCT_GROUPS_FILTER);
export const setProductGroupsFilter = createAction<object>(
  SET_PRODUCT_GROUPS_FILTER,
);
export const setEditProductGroupId = createAction<number>(
  SET_EDIT_PRODUCT_GROUP_ID,
);

const productGroupReducer = createReducer(
  initialProductGroupState,
  (builder) => {
    builder
      .addCase(getProductGroupsAction, (state, action) => {
        state.productGroups = action.payload.productGroup;
        state.pagination = {
          total: action.payload.pagination.totalCount,
          from: action.payload.pagination.pageNumber,
          perPage: Number(action.payload.pagination.pageSize),
        };
      })
      .addCase(initAddProductGroupAction, (state) => {
        state.details = null;
        state.validationError = null;
        state.success = false;
      })
      .addCase(addProductGroupAction, (state, action) => {
        state.toggleReload = !state.toggleReload;
      })
      .addCase(editProductGroupAction, (state, action) => {
        state.toggleReload = !state.toggleReload;
      })
      .addCase(deleteProductGroupAction, (state, action) => {
        state.productGroups = state.productGroups.filter(
          (productBrand) => productBrand.id !== action.payload.id,
        );
      })
      .addCase(getProductGroupAction, (state, action) => {
        state.details = action.payload;
      })
      .addCase(getProductGroupListByBranchIdAction, (state, action) => {
        state.productGroupList = action.payload;
      })

      .addCase(initProductGroupsFilter, (state) => {
        state.productGroupsFilter = {};
      })
      .addCase(setProductGroupsFilter, (state, action) => {
        state.productGroupsFilter = action.payload;
      })
      .addCase(setEditProductGroupId, (state, action) => {
        state.productGroupId = action.payload;
      });
  },
);

export default productGroupReducer;
