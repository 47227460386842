import {
  GET_CUSTOMERS_LIST,
  GET_FD_CALCULATION,
  GET_RD_CALCULATION,
  INIT_FD_CALCULATION,
  INIT_RD_CALCULATION,
} from "@crema/types/actions/Utility.action";
import {
  APIResponse,
  APIErrorResProps,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { IFDCalculator, IRDCalculator } from "@crema/types/models/Utility";
import { IGetCustomer } from "@crema/types/models/dairy/Customer";

interface GetDepositProps {
  doc: string;
  deposit?: number;
  tenure?: number;
  rate?: string | number;
  type?: number;
  repeat?: string | number;
  interestCompounding?: string | number;
}

export const getRdCalculation =
  ({ doc, deposit, tenure, rate, interestCompounding }: GetDepositProps) =>
  (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/common/rd-calculator", {
        params: { doc, deposit, tenure, rate, interestCompounding },
      })
      .then((response: APIResponse<Array<IRDCalculator>>) => {
        handleApiResponse(dispatch, response, GET_RD_CALCULATION);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const getFdCalculation =
  ({ doc, deposit, tenure, rate, type, repeat }: GetDepositProps) =>
  (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/common/fd-calculator", {
        params: { doc, deposit, tenure, rate, type, repeat },
      })
      .then((response: APIResponse<Array<IFDCalculator>>) => {
        handleApiResponse(dispatch, response, GET_FD_CALCULATION);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };

export const initFdCalculation = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_FD_CALCULATION });
};
export const initRdCalculation = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_RD_CALCULATION });
};

export const getCustomersList = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer", { params: filter })
      .then((response: APIResponse<IGetCustomer>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMERS_LIST);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
